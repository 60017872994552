import React, { PureComponent } from "react";
import "./styles.scss";
import Page from "../../Components/HOC/Page";
import { AppConstants } from "../../Shared/Constants";
import Moment from "moment";
import ProductCard from "../../Components/Cell/ProductCard";
import { connect } from "react-redux";
import { Spin, message } from "antd";
import { showAuthModal } from "../../Redux/Actions/AuthActions";
import DataFirebase from "../../Firebase/DataFirebase";
import { RouteConfig } from "../../Config/RouteConfig";
import RouterService from "../../Services/RouterService";
import CustomButton from "../../Components/Atom/CustomButton";
import { Row } from "react-bootstrap";
import Utility from "../../Services/Utility";
import TagManager from "react-gtm-module";

class Like extends PureComponent {
  onProductDetails(productKey) {
    RouterService.pushRoute(`${RouteConfig.PRODUCT}/${productKey}`);
  }

  render() {
    let tagManagerArgs = {
      dataLayer: {
        page: "Landing",
      },
      dataLayerName: "PageDataLayer",
    };

    TagManager.dataLayer(tagManagerArgs);

    let favorites = this.props.favorites;
    return (
      <div className={"Like"}>
        <Page noSection noFooter hidePost={false} className={"Like-pg"}>
          <div className={"new-section"}>
            <div className={"Head-section"}>
              <h1>Favorite</h1>
              <p>
                {favorites.length} items
                <span className={"Head-span"}>Add all item to cart</span>
              </p>
            </div>
          </div>
          <div className={"mid-section"}>
            <Spin spinning={this.props.loadingProducts}>
              <Row className={"product-grid"}>
                {favorites.map((product, index) => {
                  let { id } = product;
                  return (
                    // <div className="AdjustingHeight">
                      <ProductCard
                        key={index}
                        onClick={this.onProductDetails.bind(this, id)}
                        product={product}
                      />
                    // </div>
                  );
                })}
              </Row>
            </Spin>
          </div>
          <div className={"footer-bottom"}>
            <p>
              &copy; {Moment().year()} {AppConstants.APP_NAME} a Tanbel Inc.
              Product. Tanbel Inc. All rights reserved.
            </p>
          </div>
        </Page>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  loadingProducts: state.ProductReducer.loadingProducts,
  favorites: state.ProductReducer.favorites,
});
export default connect(mapStateToProps)(Like);
