import React from 'react';
import './styles.scss';

import CustomButton from '../../Atom/CustomButton';
import { FaArrowLeft } from 'react-icons/fa';

const PreviewActionBar = ({
    onPostClick,
    onBackEdit
}) => {

    return (
        <div className={'preview-action-bar'}>
         <div className={'action-buttons'}>
                    <button
                        className={'back-button'}
                        onClick={onBackEdit}
                    >

                    <FaArrowLeft className={'back-arrow'} />
                        Return to Editing
                    </button>
                   
                    <CustomButton
                        className={'action-button'}
                        onClick={onPostClick}
                    >
                        Post
                    </CustomButton>
                </div>
        </div>
    );
}

export default PreviewActionBar;
