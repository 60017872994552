import React, { PureComponent } from 'react';
import './styles.scss';

import SectionWrapper from '../../SectionWrapper';
import ContactForm from '../../ContactForm';
import { sections } from '../../../../Config/Placeholder';
import { Images, Icons } from '../../../../Shared/Assets';
import { SocialLinks } from '../../../../Config/FooterConfig';
import { iOSLink } from '../../../../Config';

export default class Contact extends PureComponent {
    render() {
        return (
            <SectionWrapper
                id={sections.contact.id}
                isHide={this.props.isHide}
            >
                <div className={'contact-container'}>
                    <div className={'form-wrapper'}>
                        <ContactForm />
                        <div className={'social-link-wrapper'}>
                            <ul>
                                {SocialLinks.map((LinkData, index) => {
                                    return (
                                        <li key={index}>
                                            <a href={LinkData.link} target={'blank'}>
                                                <LinkData.Icon className={'icon'} />
                                            </a>
                                        </li>
                                    );
                                })}
                            </ul>
                            <a href={iOSLink} target={'blank'}>
                                <img className={'ios-link'} alt={'ios-link'} src={Icons.iosLink} />
                            </a>
                        </div>
                        <p className={'copy-right'}>
                            {new Date().getFullYear()} &copy; WeCycle. a Tanbel Inc. Project
                        </p>
                    </div>
                    <img className={'contact-image'} alt={'contact'} src={Images.contact} />
                </div>
            </SectionWrapper>
        );
    }
}
