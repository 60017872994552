import FirebaseApp from "./config";
import { GeoFire } from "geofire";
import "firebase/database";
import firebase from "firebase/app";
import { message } from "antd";
import { dispatchAction } from "../Redux/Store";
import { addCart, clearCart, showLoader } from "../Redux/Actions/CartActions";
import {
  loadingProducts,
  productEntered,
  loadMore,
  setConditions,
  favoriteEntered,
  favoriteRemove,
  addUserProduct,
  clearUserProduct,
  setCategory,
  clearProductList,
  updateShippingProducts,
} from "../Redux/Actions/ProductActions";
import { updateUserData, showAuthModal } from "../Redux/Actions/AuthActions";
import {
  updateChatListData,
  updateChatMessageData,
  updateNotificationData,
  removeChatListData,
  removeNotificationData,
} from "../Redux/Actions/ChatAction";
import { resizeFile } from "../utils/imageResizer";

class DataFirebase {
  constructor() {
    this.database = FirebaseApp.database();
    this.geoFire = new GeoFire(this.database.ref("products"));
    this.productGeoQuery = null;
  }

  resetQuery() {
    if (this.productGeoQuery) {
      this.productGeoQuery.cancel();
      this.productGeoQuery = null;
      dispatchAction(clearProductList());
    }
  }

  setProductQuery(lat, long, radius = 40) {
    if (this.productGeoQuery) return;
    this.productGeoQuery = this.geoFire.query({
      center: [lat, long],
      radius,
    });

    this.getAllShippingProducts();
    dispatchAction(loadingProducts(true));
    this.productGeoQuery.on("ready", () => {
      dispatchAction(loadingProducts(false));
    });

    this.productGeoQuery.on("key_entered", (key) => {
      this.getProduct(key).then((product) => {
        if (product.isApproved === 1 && product.isArchived === false) {
          dispatchAction(productEntered(product));
        }
      });
    });
  }

  getUserProducts(id) {
    return new Promise((resolve, reject) => {
      dispatchAction(clearUserProduct());
      this.database
        .ref(`users/${id}/lendingId`)
        .once("value")
        .then((snapshot) => {
          snapshot.forEach((child) => {
            this.getProduct(child.key)
              .then((product) => {
                dispatchAction(addUserProduct(product));
                resolve();
              })
              .catch(reject);
          });
        })
        .catch(reject);
    });
  }

  getUserFavoriteProducts(uid) {
    this.database.ref(`users/${uid}/favoritesId`).on("value", (snapshot) => {
      dispatchAction(favoriteRemove());
      snapshot.forEach((child) => {
        this.getProduct(child.key).then((favorites) => {
          dispatchAction(favoriteEntered(favorites));
        });
      });
    });
  }

  getUserLendingProducts(uid) {
    this.database.ref(`users/${uid}/lendingId`).on("value", (snapshot) => {
      // dispatchAction(favoriteRemove())
      snapshot.forEach((child) => {
        this.getProduct(child.key).then((lending) => {
          // dispatchAction(favoriteEntered(favorites))
        });
      });
    });
  }

  getAllShippingProducts = () => {
    this.database
      .ref(`products`)
      .once("value")
      .then(async (snapshot) => {
        if (snapshot.exists()) {
          let allProducts = Object.values(snapshot.val());
          let allKeys = Object.keys(snapshot.val());
          await Promise.all(
            allProducts.map(async (item, index) => {
              let items = item;
              items.id = allKeys[index];
              let imageIds = items.images || [];
              if (imageIds.length) {
                if (items.coverImage) {
                  let index = imageIds.indexOf(items.coverImage);
                  var element = imageIds[index];
                  imageIds.splice(index, 1);
                  imageIds.splice(0, 0, element);
                }
                let imageUrls = await this.getImages(imageIds);
                items.images = imageUrls;
              }

              return items;
            })
          );
          allProducts = allProducts.filter(
            (item) =>
              item.isDeliveryAvailable &&
              item.isDeliveryAvailable === true &&
              item.isArchived === false &&
              item.isApproved === 1
          );
          dispatchAction(updateShippingProducts(allProducts));
        }
      })
      .catch((error) => {});
  };

  getCurrentRadius() {
    return this.productGeoQuery.radius();
  }

  updateQueryRadius(increment = 20) {
    if (this.productGeoQuery) {
      let currentRadius = this.productGeoQuery.radius();
      if (currentRadius > 2000) {
        dispatchAction(loadMore(false));
      } else {
        dispatchAction(loadingProducts(true));
        this.productGeoQuery.updateCriteria({
          radius: currentRadius + increment,
        });
      }
    }
  }

  updateProductQuery({ lat, lang, radius }) {
    if (this.productGeoQuery) {
      let [currentLat, currentLang] = this.productGeoQuery.center();
      let currentRadius = this.productGeoQuery.radius();
      if (
        lat !== currentLat ||
        currentLang !== lang ||
        currentRadius !== radius
      ) {
        let criteria = {};
        if (lat && lang) criteria["center"] = [lat, lang];
        if (radius) criteria["radius"] = radius;
        dispatchAction(loadingProducts(true));
        this.productGeoQuery.updateCriteria(criteria);
      }
    }
  }

  setOnline(uid) {
    this.database.ref(`users/${uid}/online`).set(true);
    this.database.ref(`users/${uid}/online`).onDisconnect().remove();
  }

  listenUserData(uid) {
    this.getUserFavoriteProducts(uid);
    this.getCartProducts(uid);
    this.database.ref(`users/${uid}`).on("value", (snapshot) => {
      dispatchAction(updateUserData(snapshot.val()));
    });
  }

  getUserData(uid) {
    return new Promise((resolve, reject) => {
      this.database
        .ref(`users/${uid}`)
        .once("value")
        .then(async (snapshot) => {
          if (snapshot.exists()) {
            resolve(snapshot.val());
          }
        })
        .catch(reject);
    });
  }

  createUserInfo(uid, userData) {
    return this.database.ref(`users/${uid}`).update(userData);
  }

  updateUserProfile(uid, userData) {
    return this.database.ref(`users/${uid}`).update(userData);
  }

  updateGovtDocuments(uid, userData) {
    return this.database.ref(`users/${uid}/govtProof`).update(userData);
  }

  removeGovtDocuments(uid, path) {
    return this.database.ref(`users/${uid}/govtProof`).remove();
  }

  favoriteProduct(isLoggedIn, uid, productId, isFavorite, isLoaded) {
    if (!isLoggedIn) {
      return dispatchAction(showAuthModal(true));
    }
    if (isFavorite) {
      this.database.ref(`users/${uid}/favoritesId/${productId}`).remove(() => {
        message.info("Product removed from favorites!");
      });
    } else {
      this.database.ref(`users/${uid}/favoritesId`).update(
        {
          [productId]: productId,
        },
        () => {
          message.success("Product added to favorites!");
        }
      );
    }
  }

  getShippingAddress(uid) {
    return new Promise((resolve, reject) => {
      this.database
        .ref(`deliveryAddress/${uid}`)
        .once("value")
        .then(async (snapshot) => {
          if (snapshot.exists()) {
            let address = Object.values(snapshot.val());
            resolve(address);
          }
        })
        .catch(reject);
    });
  }

  addedToCart(product, uid, isBooknow) {
    return new Promise((resolve, reject) => {
      let detailProduct = { ...product };
      detailProduct.time = firebase.database.ServerValue.TIMESTAMP;
      let ref = this.database.ref(`cart/${uid}`).push();
      detailProduct.id = ref.key;
      ref
        .set(detailProduct)
        .then(() => {
          if (!isBooknow) {
            message.success("Product added to the cart");
          }
          resolve();
        })
        .catch((error) => {
          reject();
        });
    });
  }

  cartRemoveProduct = (uid, id) => {
    return new Promise((resolve, reject) => {
      this.database
        .ref(`cart/${uid}/${id}`)
        .remove()
        .then(() => {
          message.success("Product was removed from the cart");
          dispatchAction(clearCart());
          resolve();
        })
        .catch((error) => {
          reject();
        });
    });
  };

  cartClearAllProduct = (uid) => {
    return new Promise((resolve, reject) => {
      this.database
        .ref(`cart/${uid}`)
        .remove()
        .then(() => {
          dispatchAction(clearCart());
          resolve();
        })
        .catch((error) => {
          reject();
        });
    });
  };

  getCartProducts = (uid) => {
    this.database.ref(`cart/${uid}`).on("value", (snapshot) => {
      dispatchAction(clearCart());
      dispatchAction(showLoader());
      snapshot.forEach((child) => {
        let cartProduct = child.val();
        this.getProduct(cartProduct.productId).then((product) => {
          cartProduct.product = product;
          dispatchAction(addCart(cartProduct));
        });
      });
    });
  };

  addShippingAddress(uid, data, isDefaultAddress = false) {
    return new Promise((resolve, reject) => {
      let deliveryAddress = { ...data };
      let ref = this.database.ref(`deliveryAddress/${uid}`).push();
      deliveryAddress.id = ref.key;
      ref.set(deliveryAddress);
      if (isDefaultAddress) {
        this.database.ref(`users/${uid}/default_address`).set(ref.key);
      }
      resolve();
    });
  }

  updateDefaultAddress = (uid, key) => {
    this.database.ref(`users/${uid}/default_address`).set(key);
  };

  deleteShippingAddress(uid, key) {
    return new Promise((resolve, reject) => {
      this.database
        .ref(`deliveryAddress/${uid}/${key}`)
        .remove()
        .then((success) => {
          resolve();
        });
    });
  }

  getProduct(productKey) {
    return new Promise((resolve, reject) => {
      this.database
        .ref(`products/${productKey}`)
        .once("value")
        .then(async (snapshot) => {
          if (snapshot.exists()) {
            let product = snapshot.val();
            product.id = productKey; //snapshot.key;

            let conditionSnapshot = await this.database
              .ref(`/product_conditions/${product.conditionId}`)
              .once("value");
            if (conditionSnapshot.val()) {
              let { name: condition } = conditionSnapshot.val();
              let ownerSnapshot = await this.database
                .ref(`/users/${product.userId}`)
                .once("value");
              let address = await this.getProductAddress(product.locationId);

              let imageIds = product.images || [];

              if (imageIds.length) {
                if (product.coverImage) {
                  let index = imageIds.indexOf(product.coverImage);
                  var element = imageIds[index];
                  imageIds.splice(index, 1);
                  imageIds.splice(0, 0, element);
                }
                let imageUrls = await this.getImages(imageIds);
                product.images = imageUrls;
              }
              resolve({
                ...product,
                condition,
                ownerData: ownerSnapshot.val(),
                address: address,
              });
            } else {
              reject();
            }
          }
        })
        .catch(reject);
    });
  }

  getConditions() {
    this.database
      .ref("product_conditions")
      .once("value")
      .then((condition) => {
        condition.forEach((child) => {
          let { name } = child.val();
          let key = child.key;
          let detail = { name: name, id: key };
          dispatchAction(setConditions(detail));
        });
      });
  }

  getProductCategory() {
    this.database
      .ref("product_categories")
      .once("value")
      .then((category) => {
        category.forEach((child) => {
          let { name } = child.val();
          let key = child.key;
          let detail = { name: name, id: key };
          dispatchAction(setCategory(detail));
        });
      });
  }

  sendRequestToSender(notification) {
    return new Promise((resolve, reject) => {
      let ref = this.database
        .ref(`notifications/${notification.receiverID}`)
        .push();
      notification.id = ref.key;
      ref
        .set(notification)
        .then((success) => {
          this.firebaseUpdateBadgeRequestNotification(
            true,
            notification.receiverID,
            notification.id
          );
          resolve();
        })
        .catch((error) => {
          reject();
        });
    });
  }

  getProductAddress(id) {
    return new Promise((resolve, reject) => {
      this.database
        .ref(`locations/${id}`)
        .once("value")
        .then((address) => {
          resolve(address.val());
        })
        .catch(reject);
    });
  }

  getImages(imageIds) {
    return new Promise((resolve, reject) => {
      let promises = [];
      for (let imageId of imageIds) {
        promises.push(
          new Promise((resolve, reject) => {
            this.database
              .ref(`images/${imageId}`)
              .once("value")
              .then((image) => {
                resolve(image.val());
              })
              .catch(reject);
          })
        );
      }

      Promise.all(promises).then(resolve).catch(reject);
    });
  }

  saveContactMessage({ name, email }) {
    return new Promise((resolve, reject) => {
      this.database
        .ref("ContactMessages")
        .push({
          name,
          email,
        })
        .then(resolve)
        .catch(reject);
    });
  }
  // <=====================  CHAT  ===========================>

  firebaseCreateChannel(isLoggedIn, id, channelDetails) {
    if (!isLoggedIn) {
      return dispatchAction(showAuthModal(true));
    }
    return new Promise((resolve, reject) => {
      this.database
        .ref(`Chats/${id}`)
        .once("value")
        .then((snapshot) => {
          if (snapshot.exists()) {
            resolve();
          } else {
            this.database
              .ref(`Chats/${id}`)
              .update(channelDetails)
              .then(() => {
                resolve();
              })
              .catch(() => reject());
          }
        })
        .catch(() => reject());
    });
  }

  firebaseObserveChatList(uid) {
    this.database.ref("Chats").on("value", (snapshot) => {
      snapshot.forEach((child) => {
        if (child.key.includes(uid)) {
          let detail = child.val();
          detail.id = child.key;
          detail.unreadCount = 0;
          if (detail.notification && uid in detail.notification) {
            detail.unreadCount = detail.notification[uid];
          }
          if (detail.productID) {
            this.getProduct(detail.productID).then((product) => {
              detail.product = product;
              let currentUser =
                detail.UserToChat === uid
                  ? detail.currentUser
                  : detail.UserToChat;
              this.getUserData(currentUser).then((user) => {
                detail.user = user;
                dispatchAction(updateChatListData(detail));
              });
            });
          } else {
            let currentUser =
              detail.UserToChat === uid
                ? detail.currentUser
                : detail.UserToChat;
            this.getUserData(currentUser).then((user) => {
              detail.user = user;
              dispatchAction(updateChatListData(detail));
            });
          }
        }
      });
    });
  }

  removeFirebaseChatListObserver() {
    this.database.ref(`Chats`).off("value");
    dispatchAction(removeChatListData());
  }

  firebaseObserveChatMessage(id, uid) {
    this.database.ref(`Chats/${id}/messages`).on("child_added", (snapshot) => {
      let detail = snapshot.val();
      if (!detail.messageType) {
        detail.messageType = 0;
      }
      this.database.ref(`Chats/${id}/notification/${uid}`).remove();
      dispatchAction(updateChatMessageData(detail));
    });
  }

  firebaseRemoveObserveChat(id) {
    this.database.ref(`Chats/${id}/messages`).off("child_added");
  }

  firebaseAddProduct(detail) {
    return new Promise((resolve, reject) => {
      let productRef = this.database.ref(`products`).push();
      let categoryRef = this.database.ref(
        `product_categories/${detail.categoryId}/products/${productRef.key}`
      );
      let locationRef = this.database.ref(`locations`).push();
      let userRef = this.database.ref(
        `users/${detail.userId}/lendingId/${productRef.key}`
      );
      detail.locationId = locationRef.key;
      detail.id = productRef.key;
      let productDetail = { ...detail };
      productDetail.images = null;
      productDetail.address = null;

      //upload Images
      let images = [];
      detail.images.forEach(async (image, index) => {
        let cover = null;
        let imageRef = this.database.ref(`images`).push();

        let imageStorageRef = firebase
          .storage()
          .ref(`product_images/${imageRef.key}.jpg`);
        const blob = await this.getFileBlob(image);
        const uploadTastk = imageStorageRef.put(blob);
        uploadTastk.then(
          (response) => {
            imageStorageRef.getDownloadURL().then((url) => {
              imageRef.child(`downloadURL`).set(url);

              images.push(imageRef.key);
              if (index === 0) {
                cover = url;
              }
              if (index + 1 === detail.images.length) {
                // productRef.child(`images`).set(images)
                productDetail.images = images;
                if (cover) {
                  productRef.child(`coverImage`).set(imageRef.key);
                }
                var geoFireProduct = new GeoFire(this.database.ref(`products`));
                var geoFire = new GeoFire(
                  this.database.ref(`loc/${detail.categoryId}`)
                );
                //Add Geo product location
                geoFireProduct
                  .set(productRef.key, [
                    detail.address.latitude,
                    detail.address.longitude,
                  ])
                  .then(
                    function () {
                      productRef.update(productDetail).then(() => {
                        locationRef.set(detail.address);

                        //Add Geo loc location
                        geoFire
                          .set(productRef.key, [
                            detail.address.latitude,
                            detail.address.longitude,
                          ])
                          .then(
                            () => {
                              userRef.set(true);
                              resolve();
                            },
                            function (error) {
                              console.log("Error: " + error);
                            }
                          );
                        categoryRef.set(true);
                      });
                    },
                    function (error) {
                      console.log("Error: " + error);
                    }
                  );
              }
            });
          },
          (failedReason) => {
            console.log("image upload failed");
            reject();
          }
        );
      });
    });
  }

  async generateImageUrl(file, ref) {
    let imageStorageRef = firebase.storage().ref(`${ref}/${new Date()}.jpg`);
    // const blob = await this.getFileBlob(file);
    await imageStorageRef.put(file);
    const url = await imageStorageRef.getDownloadURL();
    return url;
  }

  firebaseArchiveProduct(id, status) {
    let productRef = this.database.ref(`products/${id}`);
    return productRef.update({
      isArchived: status,
    });
  }

  firebaseUpdateProduct(detail) {
    return new Promise((resolve, reject) => {
      try {
        let productRef = this.database.ref(`products/${detail.id}`);
        let categoryRef = this.database.ref(
          `product_categories/${detail.categoryId}/products/${detail.id}`
        );
        let locationRef = this.database.ref(`locations`).push();
        let userRef = this.database.ref(
          `users/${detail.userId}/lendingId/${detail.id}`
        );
        detail.locationId = locationRef.key;
        let productDetail = { ...detail };
        productDetail.images = null;
        productDetail.address = null;
        productDetail.condition = null;
        let images = [];
        detail.images.forEach(async (image, index) => {
          let imageRef = this.database.ref(`images`).push();
          let cover = null;
          let url = image;
          if (image.includes("blob:http")) {
            let imageStorageRef = firebase
              .storage()
              .ref(`product_images/${imageRef.key}.jpg`);
            const blob = await this.getFileBlob(image);
            await imageStorageRef.put(blob);
            url = await imageStorageRef.getDownloadURL();
          }
          imageRef.child(`downloadURL`).set(url);
          images.push(imageRef.key);
          if (index === 0) {
            cover = url;
          }
          if (index + 1 === detail.images.length) {
            productDetail.images = images;
            if (cover) {
              productRef.child(`coverImage`).set(imageRef.key);
            }
            var geoFireProduct = new GeoFire(this.database.ref(`products`));
            var geoFire = new GeoFire(
              this.database.ref(`loc/${detail.categoryId}`)
            );
            //Add Geo product location
            geoFireProduct
              .set(detail.id, [
                detail.address.latitude,
                detail.address.longitude,
              ])
              .then(
                function () {
                  productRef.set(productDetail).then(() => {
                    locationRef.set(detail.address);
                    //Add Geo loc location
                    geoFire
                      .set(detail.id, [
                        detail.address.latitude,
                        detail.address.longitude,
                      ])
                      .then(
                        () => {
                          userRef.set(true);
                          resolve();
                        },
                        function (error) {
                          console.log("Error: " + error);
                        }
                      );
                    // categoryRefOld.set(false);
                    categoryRef.set(true);
                  });
                },
                function (error) {
                  console.log("Error: " + error);
                }
              );
          }
        });
      } catch (error) {
        console.log(error);
        reject();
      }
    });
  }

  getFileBlob = function (url) {
    return new Promise((resolve, reject) => {
      var xhr = new XMLHttpRequest();
      xhr.open("GET", url);
      xhr.responseType = "blob";
      xhr.addEventListener("load", async () => {
        const resizedBlob = await resizeFile(xhr.response);
        resolve(resizedBlob);
      });
      xhr.send();
    });
  };

  addUserReview(detail, success, failed) {
    const reviewRef = this.database.ref(`UserReviews/${detail.user}`).push();
    return this.database
      .ref(`UserReviews/${detail.user}/${reviewRef.key}`)
      .set(detail)
      .then(() => {
        success();
      })
      .catch((e) => {
        console.log(e);
        failed();
      });
  }

  getUserReview(uid) {
    return new Promise((resolve, reject) => {
      this.database
        .ref(`UserReviews/${uid}`)
        .limitToLast(5)
        .once("value")
        .then(async (snapshot) => {
          console.log(snapshot);
          if (snapshot.exists()) {
            let reviews = Object.values(snapshot.val());
            resolve(reviews);
          }
        })
        .catch(reject);
    });
  }

  addProductReview(detail, success, failed) {
    const reviewRef = this.database
      .ref(`ProductReviews/${detail.product}`)
      .push();
    return this.database
      .ref(`ProductReviews/${detail.product}/${reviewRef.key}`)
      .set(detail)
      .then(() => {
        success();
      })
      .catch((e) => {
        console.log(e);
        failed();
      });
  }

  getProductReview(pid) {
    return new Promise((resolve, reject) => {
      this.database
        .ref(`ProductReviews/${pid}`)
        .limitToLast(5)
        .once("value")
        .then(async (snapshot) => {
          console.log(snapshot);
          if (snapshot.exists()) {
            let reviews = Object.values(snapshot.val());
            resolve(reviews);
          }
        })
        .catch(reject);
    });
  }

  getMyReviews(uid) {
    return new Promise((resolve, reject) => {
      const reviews = {
        profile: [],
        product: [],
      };
      this.database
        .ref(`UserReviews`)
        .once("value")
        .then(async (snapshot) => {
          if (snapshot.exists()) {
            let profileReviews = Object.values(snapshot.val());
            reviews.profile = profileReviews;
          }
        })
        .catch(reject);
      this.database
        .ref(`ProductReviews`)
        .once("value")
        .then(async (snapshot) => {
          if (snapshot.exists()) {
            let productReviews = Object.values(snapshot.val());
            reviews.product = productReviews;
          }
        })
        .catch(reject);
      resolve(reviews);
    });
  }

  sendTextMessage(user, message, channelID, receiverID) {
    return new Promise((resolve, reject) => {
      let detail = {
        senderId: user.uid,
        senderName: user.first_Name + " " + user.last_Name,
        text: message,
        time: firebase.database.ServerValue.TIMESTAMP,
      };
      this.updateChatMessage(channelID, message, detail)
        .then(() => {
          this.database
            .ref(`Chats/${channelID}/notification/${receiverID}`)
            .once("value")
            .then((snapshot) => {
              if (snapshot.exists()) {
                let count = snapshot.val();
                count = count + 1;
                this.database
                  .ref(`Chats/${channelID}/notification/${receiverID}`)
                  .set(count);
                resolve();
              } else {
                this.database
                  .ref(`Chats/${channelID}/notification/${receiverID}`)
                  .set(1);
                resolve();
              }
            });
        })
        .catch(() => {
          reject();
        });
    });
  }

  sendImageMessage(user, url, channelID, receiverID) {
    let detail = {
      senderId: user.uid,
      senderName: user.first_Name + " " + user.last_Name,
      photoURL: url,
      time: firebase.database.ServerValue.TIMESTAMP,
    };
    this.updateChatMessage(channelID, "send a Image", detail).then(() => {
      this.database
        .ref(`Chats/${channelID}/notification/${receiverID}`)
        .once("value")
        .then((snapshot) => {
          if (snapshot.exists()) {
            let count = snapshot.val();
            count = count + 1;
            this.database
              .ref(`Chats/${channelID}/notification/${receiverID}`)
              .set(count);
          } else {
            this.database
              .ref(`Chats/${channelID}/notification/${receiverID}`)
              .set(1);
          }
        });
    });
  }

  updateChatMessage(channelID, lastMessage, detail) {
    return new Promise((resolve, reject) => {
      this.database
        .ref(`Chats/${channelID}/messages`)
        .push(detail, (error, ref) => {
          if (!error) {
            this.database
              .ref(`Chats/${channelID}/lastMessage`)
              .set(lastMessage);
            this.database
              .ref(`Chats/${channelID}/lastMessageTime`)
              .set(firebase.database.ServerValue.TIMESTAMP);
            resolve();
          } else {
            reject();
          }
        });
    });
  }

  updateNotification(notification) {
    return new Promise((resolve, reject) => {
      let ref = this.database
        .ref(`notifications/${notification.receiverID}`)
        .push();
      notification.id = ref.key;
      ref.set(notification).then(() => {
        resolve();
      });
    });
  }

  readNotification(id, userID) {
    return new Promise((resolve, reject) => {
      this.database
        .ref(`notifications/${userID}/${id}/isRead`)
        .set(true)
        .then(() => {
          resolve();
        });
    });
  }

  // <=====================  Notification  ===========================>

  firebaseObserveNotification(uid) {
    this.database.ref(`notifications/${uid}`).on("value", (snapshot) => {
      let notifications = [];
      snapshot.forEach((childSnapshot) => {
        let detail = childSnapshot.val();
        detail.id = childSnapshot.key;
        this.getProduct(detail.productId).then((product) => {
          detail.product = product;
          this.getUserData(detail.senderID).then((user) => {
            detail.user = user;
            notifications.push(detail);
            dispatchAction(updateNotificationData(notifications));
          });
        });
      });
    });
  }

  firebaseUpdateBadgeRequestNotification(isUpdate, userID, key) {
    if (isUpdate === true) {
      this.database.ref(`Badge/${userID}/RequestBadges/${key}`).set(true);
    } else {
      this.database.ref(`Badge/${userID}/RequestBadges/${key}`).remove();
    }
  }

  removeFirebaseChatNotificationObserver(uid) {
    this.database.ref(`notifications/${uid}`).off("value");
    dispatchAction(removeNotificationData());
  }

  checkProductAvailable(startTimeStamp, endTimeStamp, pProductID) {
    return new Promise((resolve, reject) => {
      this.database.ref(`contracts/${pProductID}`).once("value", (snapshot) => {
        let isProductAvailable = true;
        snapshot.forEach((child) => {
          if (
            !(
              (startTimeStamp < child.startTimeStamp ||
                startTimeStamp > child.endTimeStamp) &&
              (endTimeStamp < child.startTimeStamp ||
                endTimeStamp > child.endTimeStamp)
            )
          ) {
            isProductAvailable = false;
            return true;
          }
        });
        resolve(isProductAvailable);
      });
    });
  }
}

export default new DataFirebase();
