export const sections = {
    home: {
        id: 'home',
        name: 'Home'
    },
    about: {
        id: 'about',
        name: 'About'
    },
    services: {
        id: 'our-services',
        name: 'Our Services'
    },
    features: {
        id: 'features',
        name: 'Features'
    },
    contact: {
        id: 'contact',
        name: 'Contact'
    },
    popular: {
        id: 'popular',
        name: 'Popular'
    },
};