import React from 'react';
import './styles.scss';
import ExifOrientationImg from 'react-exif-orientation-img';
import { Carousel, Icon } from 'antd';


const CustomCarousel = ({
    className = '',
    images = [],
    onClick,
    index = 0,
    isFull = false
  }) => {

return (
    <>
  
    <div className={`custom-carousel ${className}`}>
      {/* <div className="d-flex justify-content-between">
        <Icon type="left-circle" onClick={previous}/>
        <Icon type="right-circle" onClick={next} />
      </div>*/}
      <Carousel className={'carousel-images'} 
        ref={node => {
          if (node) {
            node.goTo(index, false)
          }
        }}
      >

        {images.map((image, index) => {
          return (
            <img
                onClick={() => {
                onClick(image, index)
            }}
              key={index}
              alt={'carousel-item'}
              className={`carousel-image ${isFull ? 'carousel-image-Full' : ''}`}
              src={image}
            />
           
            
          );
        })}
      </Carousel>

               
    </div>

                 
             </> 

  )
}


export default CustomCarousel;


