import React from "react";
import "./styles.scss";

import { Rate } from "antd";

const userInfo = ({ userData, onMessage }) => {
  let { first_Name, last_Name, name, avatar } = userData;
  let debugName = userData.first_Name > 0 ? first_Name + " " + last_Name : name;

  return (
    <div className={"userinfo-conatiner"}>
      <div className={"userinfo-content"}>
        <div className={"avatar"}>
          {userData.avatar ? (
            <img src={avatar} alt="profole-pic" />
          ) : (
            <h1 className={"avt-word"}>{debugName.charAt(0)}</h1>
          )}
        </div>
        <div className={"information"}>
          <div>
            <h5>{debugName}</h5>
            <Rate
              disabled
              allowHalf
              count={5}
              value={4}
              style={{ color: "#6CC788", fontSize: "0px" }}
            />
          </div>
          <div className={"Write-msg"} onClick={onMessage}>
            <p>Write message</p>
          </div>
        </div>
      </div>
    </div>
    // {userDetil.avatar ? <img src={userDetil.avatar}/> : <h1>{(userDetil.first_Name ? userDetil.first_Name : userDetil.name).charAt(0)}</h1>}
  );
};

export default userInfo;
