import React from 'react';
import './styles.scss'
import CustomButton from '../../Atom/CustomButton'

const filterBottonBar = ({
    clearClicked,
    applyClicked
}) => {
    return (
        <div className={'container'}>
            <CustomButton className={'button'} onClick={clearClicked}>Clear</CustomButton>
            <CustomButton className={'button apply'} onClick={applyClicked}>Apply</CustomButton>
        </div>
    )
}

export default filterBottonBar;