import React from "react";
import { Row, Col } from "react-bootstrap";
import "./styles.scss";
import ProductDetails from "./ProductDetails";
import Inbox from "./Inbox";

const OpenMsg = ({ backClicked = () => {}, message = {} }) => {
  let value = "";

  if (message.product) {
    value = (
      <div className="row-eq-height">
        <div className={"msgProductDetails"} md={5}>
          <ProductDetails message={message} />
        </div>
        <div className={"inboxDetails"} md={7}>
          <Inbox message={message} />
        </div>
      </div>
    );
  } else {
    value = (
      <div className={"inboxDetails"}>
        <Inbox message={message} />
      </div>
    );
  }

  return (
    <div className={"open-msg"}>
      <div className="second-nav d-flex">
        <div style={{ margin: "2vh" }}>
          <p className={"backLink"} onClick={backClicked}>
            Back
          </p>
        </div>
        <div className="link-center">
          <p>
            <span className={"backLink"} onClick={backClicked}>
              Chat
            </span>{" "}
            /{" "}
            <span style={{ color: "black" }}>
              {message.user.first_Name} {message.user.last_Name}
            </span>
          </p>
        </div>
      </div>
      {value}
    </div>
  );
};

export default OpenMsg;
