import React from "react";
import "./styles.scss";
import UserInfo from "../../Cell/UserInfo";
import { Row, Col } from "antd";

const UserProfile = ({ userData, onMessage }) => {
  const info = ["Ads", "Reviews", "Verified"];
  return (
    <div className={"other-user-profile"}>
      <UserInfo userData={userData} onMessage={onMessage} />
      <div className={"other-user-profile-basic-Information"}>
        {info.map((element, index) => {
          let value = [];
          if (index === 0) {
            let test = <p key={"-"}>-</p>;
            value.push(test);
          } else if (index === 1) {
            let test = <p key={"-"}>-</p>;
            value.push(test);
          } else if (index === 2) {
            if (userData.accountNumberVerification) {
              let test = <p key={"Bank"}>Bank</p>;
              value.push(test);
            }
            if (userData.phoneVerification) {
              let test = <p key={"Phone"}>Phone</p>;
              value.push(test);
            }
            if (userData.emailVerified) {
              let test = <p key={"Email"}>Email</p>;
              value.push(test);
            }
            if (userData.proofVerifiedStatus === 2) {
              let test = <p key={"Govt"}>Government ID</p>;
              value.push(test);
            }
            if (userData.Facebook_id) {
              let test = <p key={"fb"}>Facebook</p>;
              value.push(test);
            }
          }
          return (
            <Row gutter={10} key={index}>
              <Col span={12}>
                <p>{element}</p>
              </Col>
              <Col span={12}>
                <div className={"other-user-profile-basic-Information-value"}>
                  {value.map((innerElement) => {
                    return innerElement;
                  })}
                </div>
              </Col>
            </Row>
          );
        })}
      </div>
    </div>
  );
};

export default UserProfile;
