import { BlogActionTypes } from '../Actions/BlogActions';

const defaultCartState = {
    blogs : []
}

const BlogReducer = (state = { ...defaultCartState }, action) => {
    switch (action.type) {
       
        case BlogActionTypes.SET_BLOGS:
        return {
            ...state,
            blogs: action.blogs
        };

        default:
            return { ...state };
    }
}

export default BlogReducer;