import React from "react";
import Page from "../../Components/HOC/Page";
import { Row, Col, Button, Modal, Input, Rate, message } from "antd";
import "./styles.scss";
import OtherUser from "../../Components/Molecule/OtherProfile";
import { connect } from "react-redux";
import ProductsHorizontal from "../../Components/Molecule/ProductsHorizontal";
import Database from "../../Firebase/DataFirebase";
import RouterService from "../../Services/RouterService";
import { RouteConfig } from "../../Config/RouteConfig";
import { Spin } from "antd";
import { CATEGORIES } from "../../Config";
import TagManager from "react-gtm-module";
import NoReviews from "../../Assets/Images/undraw_feedback.png";
import BasicModal from "../../Components/Atom/BasicModal";
import CustomButton from "../../Components/Atom/CustomButton";
import TextArea from "antd/lib/input/TextArea";
import ButtonGroup from "antd/lib/button/button-group";
import DataFirebase from "../../Firebase/DataFirebase";

class UserProfile extends React.PureComponent {
  state = {
    userDetail: null,
    isModalOpen: false,
    ratting: {
      rate: 0,
      review: "",
    },
    reviews: [],
  };

  handleCancel = () => {
    this.setState({
      ...this.state,
      isModalOpen: false,
    });
  };

  showModal = () => {
    this.setState({
      ...this.state,
      isModalOpen: true,
    });
  };

  fetchReviews = async (uid) => {
    const allReview = await Database.getUserReview(uid);
    this.setState({
      ...this.state,
      reviews: allReview.reverse(),
    });
  };

  handleOk = () => {
    this.setState({
      ...this.state,
      isModalOpen: false,
    });

    const data = {
      ...this.state.ratting,
      reviewer: {
        uid: this.props.userData.uid,
        name: this.props.userData.name,
        photo: this.props.userData.avatar,
      },
      timestamp: new Date().toISOString(),
      user: this.state.userDetail.uid,
    };
    DataFirebase.addUserReview(
      data,
      () => {
        this.setState({
          ...this.state,
          reviews: [data, ...this.state.reviews],
        });
      },
      () => {
        message.error("Something went wrong");
      }
    );
  };

  componentDidMount() {
    let { id } = this.props.match.params;
    if (id) {
      Database.getUserData(id).then((data) => {
        this.setState({
          ...this.state,
          userDetail: data,
        });
      });
      Database.getUserProducts(id);
      this.fetchReviews(id);
    }
  }

  onProductSearch = () => {
    let { id } = this.props.match.params;
    let param = { product: id };
    RouterService.pushRoute({
      pathname: `${RouteConfig.PRODUCTS}/${CATEGORIES.POPULAR}`,
      search: "?" + new URLSearchParams(param).toString(),
    });
  };

  onMessage = () => {
    let { uid } = this.props.userData || {};
    if (uid !== this.state.userDetail.uid) {
      let userIdArray = [uid, this.state.userDetail.uid];
      userIdArray.sort();
      let userIDs = userIdArray.join("_");
      let channelID = userIDs;
      let details = {
        name: "",
        UserToChat: uid,
        currentUser: this.state.userDetail.uid,
        productID: "",
      };
      Database.firebaseCreateChannel(this.props.isLoggedIn, channelID, details)
        .then(() => {
          RouterService.pushRoute(`${RouteConfig.CHAT}/${channelID}`);
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      console.warn("Cannot message yourself");
    }
  };

  render() {
    let tagManagerArgs = {
      dataLayer: {
        page: "Landing",
      },
      dataLayerName: "PageDataLayer",
    };

    TagManager.dataLayer(tagManagerArgs);

    return (
      <>
        {this.state.isModalOpen && (
          <BasicModal>
            <p className="text-center">Give your review here</p>
            <hr />
            <div className="d-flex justify-content-center mb-3">
              <Rate
                allowHalf
                count={5}
                value={this.state.ratting.rate}
                style={{ transform: "scale(1.5)" }}
                onChange={(v) => {
                  this.setState({
                    ...this.state,
                    ratting: {
                      ...this.state.ratting,
                      rate: v,
                    },
                  });
                }}
                size={"large"}
              />
            </div>
            <TextArea
              style={{ width: "300px" }}
              className="mb-3"
              placeholder="Write your thoughts here..."
              allowClear
              value={this.state.ratting.review}
              onChange={(v) => {
                this.setState({
                  ...this.state,
                  ratting: {
                    ...this.state.ratting,
                    review: v.target.value,
                  },
                });
              }}
            />
            <div className="d-flex">
              <Button type="primary" onClick={this.handleOk} className="mr-2">
                Send
              </Button>
              <Button type="link" onClick={this.handleCancel}>
                Cancel
              </Button>
            </div>
          </BasicModal>
        )}
        <Page noSection={true} noPadding={true} lightView={false}>
          <div className={"user-profile-page-container"}>
            {this.state.userDetail ? (
              <Row gutter={16}>
                <Col lg={7} md={12}>
                  <OtherUser
                    userData={this.state.userDetail}
                    onMessage={this.onMessage}
                  />
                </Col>
                <Col lg={17} md={12}>
                  {this.props.userProducts ? (
                    <ProductsHorizontal
                      products={this.props.userProducts}
                      user={this.state.userDetail}
                      onClick={this.onProductSearch}
                      onMoreClicked={this.onProductSearch}
                    />
                  ) : (
                    <div className={"No-product"}>No Product</div>
                  )}
                </Col>
                {/* <Col sm={24}>
                  <div className={"reviews"}>
                    <img
                      className={"noRev-img"}
                      alt={"no-Rev"}
                      src={NoReviews}
                    />
                    <br />
                    <h1 className={"NoRev"}> No reviews yet</h1>
                    <p className={"noRev-desc"}>
                      Need some little description <br />
                      about it
                    </p>
                    <Button onClick={this.showModal}>Leave a review</Button>
                  </div>
                </Col> */}
                <Col sm={24}>
                  <div className={"reviews-list"}>
                    <div className={"d-flex justify-content-between"}>
                      <h3 className="mr-3">Reviews</h3>
                      <Button onClick={this.showModal}>Leave a review</Button>
                    </div>
                    <hr className="mb-5" />
                    {this.state.reviews.map((review) => (
                      <div className={"review-item mb-5"}>
                        <div className={"d-flex align-items-center"}>
                          <img
                            className={"mr-2"}
                            alt={"reviewer"}
                            width={60}
                            height={60}
                            src={review.reviewer.photo}
                          />
                          <div className={"reviewer-info"}>
                            <h5 className={"reviewer-name"}>
                              {review.reviewer.name}
                            </h5>
                            {review.timestamp && (
                              <h6 className={"reviewer-date"}>
                                {new Date(
                                  review.timestamp
                                ).toLocaleDateString()}
                              </h6>
                            )}
                          </div>
                        </div>
                        <div>
                          <Rate
                            allowHalf
                            count={5}
                            value={review.rate}
                            size={"large"}
                          />
                          <p className={"review-desc"}>{review.review}</p>
                        </div>
                      </div>
                    ))}
                  </div>
                </Col>
              </Row>
            ) : (
              <Spin className={"spin"} />
            )}
          </div>
        </Page>
      </>
    );
  }
}

const mapPropsToState = (state) => {
  return {
    userProducts: state.ProductReducer.userProducts,
    userData: state.AuthReducer.userData,
    isLoggedIn: state.AuthReducer.isLoggedIn,
  };
};

export default connect(mapPropsToState)(UserProfile);
