import React, { PureComponent } from "react";
import "./styles.scss";
import { connect } from "react-redux";
import { Row, Col, Select } from "antd";
import CustomButton from "../../../Components/Atom/CustomButton";
import LocIcon from "../../../Assets/Icons/locIcon.png";
import { Form } from "antd";
import CustomAlert from "../../../Components/Atom/CustomAlert";
import CustomInput from "../../../Components/Atom/CustomInput";
import { showAuthModal } from "../../../Redux/Actions/AuthActions";
import Rules from "../../../Services/Rules";
import Autocomplete from "react-google-autocomplete";
import { spin } from "antd";
const { Option } = Select;

class PostForm extends PureComponent {
  constructor(props) {
    super(props);
    if (this.props.post == null) {
      this.state = {
        errorMessage: "",
        id: null,
        userId: this.props.userData.uid,
        name: null,
        hourlyRate: null,
        weeklyRate: null,
        categoryId: undefined,
        conditionId: undefined,
        desc: " ",
        condition: undefined,
      };
    } else {
      this.state = {
        errorMessage: "",
        id: null,
        userId: this.props.post.uid,
        name: this.props.post.name,
        hourlyRate: this.props.post.hourlyRate,
        weeklyRate: this.props.post.weeklyRate,
        categoryId: this.props.post.categoryId,
        conditionId: this.props.post.conditionId,
        desc: this.props.post.desc,
        condition: this.props.conditions[this.props.post.conditionId - 1].name,
        address: this.props.post.address,
      };
    }
    this.checkSignIn();
  }

  //for hours validation
  handleChange = (e) => {
    const re = /^[0-9\b]+$/;
    if (e.target.value == "" || re.test(e.target.value)) {
      this.setState({
        hourlyRate: e.target.value,
      });
    }
  };
  //for week validation
  handleChange2 = (e) => {
    const re = /^[0-9\b]+$/;
    if (e.target.value == "" || re.test(e.target.value)) {
      this.setState({
        weeklyRate: e.target.value,
      });
    }
  };
  
  componentDidUpdate(prev) {
    this.checkSignIn();
    if (this.props.productDetails !== prev.productDetails) {
      this.setState({
        ...this.state,
        ...this.props.productDetails,
      });
    }
  }

  checkSignIn() {
    if (this.props.isLoggedIn) {
      this.props.showAuthModal(false);
    }
  }

  onPreview(event) {
    event.preventDefault();
    let { validateFields } = this.props.form;
    validateFields((error, values) => {
      if (!error) {
        let postData = {
          id: this.state.id,
          userId: this.state.userId,
          name: this.state.name,
          name_lowercased: this.state.name.toLowerCase(),
          hourlyRate: this.state.hourlyRate,
          weeklyRate: this.state.weeklyRate,
          categoryId: this.state.categoryId,
          conditionId: this.state.conditionId,
          desc: this.state.desc,
          description_lowecased: this.state.desc.toLowerCase(),
          address: this.state.address,
          isArchived: false,
          condition: this.state.condition,
        };
        this.props.onPreview(postData);
      }
    });
  }

  onPost(event) {
    event.preventDefault();
    let { validateFields } = this.props.form;
    validateFields((error, values) => {
      if (!error) {
        let postData = {
          id: this.state.id,
          userId: this.state.userId,
          name: this.state.name,
          name_lowercased: this.state.name.toLowerCase(),
          hourlyRate: this.state.hourlyRate,
          weeklyRate: this.state.weeklyRate,
          categoryId: this.state.categoryId,
          conditionId: this.state.conditionId,
          desc: this.state.desc,
          description_lowecased: this.state.desc.toLowerCase(),
          address: this.state.address,
          isArchived: false,
          condition: this.state.condition,
        };

        this.props.onSelect(postData);
      } else {
        console.log(error, "s");
      }
    });
  }

  render() {
    let { getFieldDecorator } = this.props.form;
    let addressString = "";
    let { address } = this.state.address || {};
    if (address) {
      addressString = `${address.zipCode} ${address.street} ${address.state} ${address.country}`;
    }
    return (
      <div className={"postform"}>
        <Form onSubmit={this.onPost.bind(this)}>
          <div className={"mid-main-wrap"}>
            <CustomAlert message={this.state.errorMessage} />
            <Form.Item>
              <Row className={"mid-main-section"}>
                <Col md={6} lg={5}>
                  <div>
                    <h2>Category</h2>
                  </div>
                </Col>
                <Col md={6} lg={19}>
                  <div className={"select-cat"}>
                    {getFieldDecorator("select-multiple-cat", {
                      initialValue: this.state.categoryId,
                      rules: Rules.getCategory(),
                    })(
                      <Select
                        className={"cat-custom"}
                        placeholder="Please choose category"
                        onChange={(value) => {
                          this.setState({
                            categoryId: value,
                          });
                        }}
                      >
                        {this.props.category.map((category, index) => {
                          return (
                            <Option key={index} value={category.id}>
                              {category.name}
                            </Option>
                          );
                        })}
                      </Select>
                    )}
                  </div>
                </Col>
              </Row>
            </Form.Item>
            <Form.Item>
              <Row className={"mid-main-section"}>
                <Col md={6} lg={5}>
                  <div>
                    <h2>Condition</h2>
                  </div>
                </Col>
                <Col md={6} lg={19}>
                  <div className={"select-con"}>
                    {getFieldDecorator("select-multiple-con", {
                      initialValue: this.state.conditionId,
                      rules: Rules.getCondition(),
                    })(
                      <Select
                        className={"con-custom"}
                        placeholder="Please choose condition"
                        onChange={(event) => {
                          this.setState({
                            conditionId: event,
                            condition: this.props.conditions[event - 1].name,
                          });
                        }}
                      >
                        {this.props.conditions.map((condition, index) => {
                          return (
                            <Option key={index} value={condition.id}>
                              {condition.name}
                            </Option>
                          );
                        })}
                      </Select>
                    )}
                  </div>
                </Col>
              </Row>
            </Form.Item>
          </div>
          <div className={"middle-wrap"}>
            <Form.Item>
              <Row className={"middle-section"}>
                <Col md={6} lg={5} className={"left-middle-section"}>
                  <div>
                    <h2>Name of the product</h2>
                  </div>
                </Col>
                <Col md={6} lg={17} className={"right-middle-section"}>
                  <div>
                    {getFieldDecorator("productName", {
                      initialValue: this.state.name,
                      rules: Rules.getProductName(),
                    })(
                      <CustomInput
                        type="text"
                        className={"productName-feild"}
                        onChange={(event) => {
                          this.setState({
                            name: event.target.value,
                          });
                        }}
                      />
                    )}
                  </div>
                </Col>
              </Row>
            </Form.Item>
            <Row className={"middle-section"}>
              <Col md={6} lg={5} className={"left-middle-section"}>
                <div>
                  <h2>Rate</h2>
                </div>
              </Col>
              <Col md={6} lg={17} className={"right-middle-section"}>
                <Row>
                  <div className={"rate-fields"}>
                    {/* <Col xs={12} md={4} lg={4} xl={4}> */}
                    <Form.Item>
                      {/* { getFieldDecorator('hourPrice', {
                                                initialValue: this.state.hourlyRate,
                                                rules: Rules.getTime()
                                            })( */}
                      <CustomInput
                        type="text"
                        className={"hour-feild"}
                        value={this.state.hourlyRate}
                        suffix={"/day"}
                        onChange={this.handleChange}
                        // onChange={(event) => {
                        //     this.setState({
                        //         hourlyRate: event.target.value
                        //     });
                        // }}
                      />
                      {/* )} */}
                      {/* <CustomInput type='text' suffix={'/day'} value={this.state.values} className={'productName-feild'} onChange={this.handleChange} /> */}
                    </Form.Item>
                    {/* </Col> */}
                    {/* <Col xs={12} md={4} lg={} xl={5}> */}
                    <Form.Item>
                      {/* {getFieldDecorator('weekPrice', {
                                                initialValue: this.state.weeklyRate,
                                                rules: Rules.getTime()
                                            })( */}
                      <CustomInput
                        type="text"
                        className={"week-feild"}
                        value={this.state.weeklyRate}
                        onChange={this.handleChange2}
                        suffix={"/week"}
                        // onChange={(event) => {
                        //     this.setState({
                        //         weeklyRate: event.target.value
                        //     });
                        // }}
                      />
                      {/* )} */}
                    </Form.Item>
                    {/* </Col>            */}
                  </div>
                </Row>
              </Col>
            </Row>
            <Form.Item>
              <Row className={"middle-section"}>
                <Col md={6} lg={5} className={"left-middle-section"}>
                  <div>
                    <h2>Description</h2>
                  </div>
                </Col>
                <Col md={6} lg={17} className={"right-middle-section"}>
                  {getFieldDecorator("description", {
                    initialValue: this.state.desc,
                    rules: Rules.getDescription(),
                  })(
                    <div>
                      <textarea
                        rows="4"
                        cols="50"
                        className={"Desc-feild"}
                        value={this.state.desc}
                        onChange={(event) => {
                          this.setState({
                            desc: event.target.value,
                          });
                        }}
                      />
                    </div>
                  )}
                </Col>
              </Row>
            </Form.Item>
          </div>
          <div className={"bottom-wrap"}>
            <Form.Item>
              <Row className={"bottom-section"}>
                <Col md={6} lg={5}>
                  <h2>Location</h2>
                </Col>
                <Col md={18} lg={17}>
                  {getFieldDecorator("location", {
                    initialValue: this.state.address,
                    rules: Rules.getLocation(),
                  })(
                    <div>
                      <Autocomplete
                        className={"location-feild"}
                        onPlaceSelected={(place) => {
                          let addressString = place.formatted_address;
                          let detail = {
                            address: {
                              country: "",
                              state: "",
                              street: "",
                              zipCode: "",
                            },

                            add: place.formatted_address,
                            latitude: place.geometry.location.lat(),
                            longitude: place.geometry.location.lng(),
                            placeId: place.place_id,
                          };

                          place.address_components.forEach((address) => {
                            if (
                              address.types.includes(
                                "administrative_area_level_2"
                              )
                            ) {
                              detail.address.street = address.long_name;
                            } else if (
                              address.types.includes(
                                "administrative_area_level_1"
                              )
                            ) {
                              detail.address.state = address.long_name;
                            } else if (address.types.includes("country")) {
                              detail.address.country = address.long_name;
                            } else if (address.types.includes("postal_code")) {
                              detail.address.zipCode = address.long_name;
                            }
                          });
                          this.setState({
                            address: detail,
                            addressString: addressString,
                          });
                        }}
                        value={this.state.addressString}
                        onChange={(event) => {
                          this.setState({
                            addressString: event.target.value,
                            address: null,
                          });
                        }}
                        types={["(regions)"]}
                      />
                      <img src={LocIcon} height="15px" alt={"LocIcon"} />
                    </div>
                  )}
                </Col>
              </Row>
            </Form.Item>
          </div>
          <Form.Item>
            <Row className={"footer-section"}>
              <div className={"btn-wrap"}>
                <CustomButton
                  onClick={this.onPreview.bind(this)}
                  className={"Preview-Btn"}
                >
                  Preview
                </CustomButton>
                <CustomButton
                  onClick={() => this.onPost.bind(this)}
                  className={"Post-Btn"}
                >
                  {this.props.productDetails ? "Update" : "Post"}
                </CustomButton>
              </div>
            </Row>
          </Form.Item>
        </Form>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isLoggedIn: state.AuthReducer.isLoggedIn,
    products: state.ProductReducer.products,
    conditions: state.ProductReducer.conditions,
    post: state.ProductReducer.postProduct,
    category: state.ProductReducer.category,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    showAuthModal: (check) => dispatch(showAuthModal(check)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create()(PostForm));
