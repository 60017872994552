import React from "react";
import "./style.scss";
import { connect } from "react-redux";
import DataFirebase from "../../../Firebase/DataFirebase";
import { Card } from "antd";
import ImageIcon from "../../Atom/ImageIcon";
import { Icons } from "../../../Shared/Assets";
import Carousel from "../../Molecule/Carousel";
import RouterService from "../../../Services/RouterService";
import { RouteConfig } from "../../../Config/RouteConfig";

const ProductCard = ({
  isLoggedIn,
  userData,
  onClick,
  product,
  className,
  isMyProduct,
}) => {
  let {
    id,
    images,
    name,
    desc: description,
    isDeliveryAvailable = false,
    isVerified = false,
    isSell = false,
    // conditionId: rating,
    hourlyRate,
    weeklyRate,
  } = product;

  let { uid, favoritesId = {} } = userData || {};

  let isFavorite = Object.keys(favoritesId).includes(id);
  let productImages = [images[0].downloadURL]; //images.map(image => image.downloadURL);

  return (
    <Card
      hoverable
      className={`product-card ${className}`}
      cover={
        <div className={"product-cover"}>
          <Carousel images={productImages} onClick={onClick} />
          {isMyProduct ? (
            <div
              className="edit-icon"
              onClick={() => {
                RouterService.pushRoute(`${RouteConfig.POST}/${id}`);
              }}
            >
              <img src={Icons.EditIcon} alt="edit" />
            </div>
          ) : (
            <ImageIcon
              className={"favorite-button"}
              src={
                isLoggedIn && isFavorite
                  ? Icons.heartOrangeFilled
                  : Icons.heartGreyFilled
              }
              onClick={() => {
                DataFirebase.favoriteProduct(isLoggedIn, uid, id, isFavorite);
              }}
            />
          )}
          {isDeliveryAvailable && (
            <ImageIcon
              className={"favorite-button Ship_butn"}
              src={Icons.ShipingIcon}
            />
          )}
        </div>
      }
      bodyStyle={{ padding: 0 }}
    >
      <div onClick={onClick} className={"ant-card-body card-body"}>
        <div className={"product-info"}>
          <h2 className={"product-title"}>
            {name}
            {isVerified && (
              <ImageIcon className={"Verify_butn"} src={Icons.Verifyicon} />
            )}
          </h2>
          <p className={"product-desc"}>{description}</p>
        </div>
        {isSell ? (
          <span className={"product-price"}>Price ${hourlyRate}</span>
        ) : (
          <span className={"product-price"}>
            Daily ${hourlyRate}/Weekly ${weeklyRate}
          </span>
        )}
      </div>
    </Card>
  );
};
const mapStateToProps = (state) => ({
  isLoggedIn: state.AuthReducer.isLoggedIn,
  userData: state.AuthReducer.userData,
});
export default connect(mapStateToProps)(ProductCard);
