import mixpanel from "mixpanel-browser";

class Utility {
    static isEmail = (email = '') => {
        let regex = /^([A-Za-z0-9_\-.+])+@([A-Za-z0-9_\-.])+\.([A-Za-z]{2,})$/;
        return regex.test(String(email).toLowerCase());
    }

    static isValidName = (name = '') => {
        let regex = /^[a-zA-Z]+/g;
        return regex.test(String(name));
    }

    static getParam = (paramName, url = window.location.href) => {
        return new URL(url).searchParams.get(paramName);
    }

    static isValidPassword = (password = '') => {
        let regex = /(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*]){8,}/g;
        return regex.test(password);
    }

    static analyticsUpdates = (title, data) => {
        return mixpanel.track(title, data);
    } 

}


export default Utility;