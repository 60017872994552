export const AuthActionTypes = {
    SHOW_AUTH_MODAL: 'SHOW_AUTH_MODAL',
    AUTH_CHANGED: 'AUTH_CHANGED',
    UPDATE_USER_DATA: 'UPDATE_USER_DATA',
    SIGNING_IN: 'SIGNING_IN',
    SIGNING_UP: 'SIGNING_UP',
    SIGNIN_FAIL: 'SIGNIN_FAIL',
    SIGNUP_FAIL: 'SIGNUP_FAIL',
    SENDING_FORGOT_PASSWORD_EMAIL: 'SENDING_FORGOT_PASSWORD_EMAIL',
    FORGOT_PASSWORD_EMAIL_FAIL: 'FORGOT_PASSWORD_EMAIL_FAIL',
    SET_LOCATION: 'SET_LOCATION',
    RESET_USER: 'RESET_USER'
}

export const setLocation = (location, latitude, longitude) => {
    return {
        type: AuthActionTypes.SET_LOCATION,
        location,
        latitude,
        longitude
    };
}

export const showAuthModal = (status, activeId) => {
    return {
        type: AuthActionTypes.SHOW_AUTH_MODAL,
        status,
        activeId
    };
}

export const authChanged = (user = null) => {
    return {
        type: AuthActionTypes.AUTH_CHANGED,
        user
    };
}

export const updateUserData = (userData) => {
    return {
        type: AuthActionTypes.UPDATE_USER_DATA,
        userData
    };
}

export const resetUser = () => {
    return {
        type: AuthActionTypes.RESET_USER
    };
}