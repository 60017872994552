
class DateExtention {
  
    static getDate = () => {
        let newDate = new Date()
        return newDate;
    }

    static getTimeStamp = () => {
        let date = this.getDate()
        return date.getTime()
    }
    

}

export default DateExtention;