
export const ChatActionTypes = {
    CHAT_LIST : 'Chat_List',
    CHAT_MESSAGE : 'Chat_Message',
    REMOVE_CHAT_MESSAGE : 'Remove_Chat_Message',
    REMOVE_CHAT_LIST : 'Remove_Chat_List',
    NOTIFICATION_LIST : 'Notification_List',
    REMOVE_NOTIFICATION_LIST : 'Remove_Notification_List'
}

export const updateChatListData = (chatList) => {
    return {
        type: ChatActionTypes.CHAT_LIST,
        chatList
    };
}

export const updateChatMessageData = (chat) => {
    return {
        type: ChatActionTypes.CHAT_MESSAGE,
        chat
    };
}

export const removeChatMessageData = () => {
    return {
        type: ChatActionTypes.REMOVE_CHAT_MESSAGE,
    };
}

export const removeChatListData = () => {
    return {
        type: ChatActionTypes.REMOVE_CHAT_LIST,
    };
}

export const updateNotificationData = (notificationList) => {
    return {
        type: ChatActionTypes.NOTIFICATION_LIST,
        notificationList
    };
}

export const removeNotificationData = () => {
    return {
        type: ChatActionTypes.REMOVE_NOTIFICATION_LIST,
    };
}