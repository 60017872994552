import React from "react";
import "./styles.scss";
import CustomButton from "../CustomButton";

const model = ({
  notification,
  handleCancel = () => {},
  handleOk = () => {},
  crossClickes = () => {},
}) => {
  return (
    <div className={"content"}>
      <div className={"model"}>
        <div className={"model-Content"}>
          {/* <button className={'cross'}>X</button> */}
          <CustomButton className={"cross"} onClick={crossClickes}>
            <i className="fa fa-close"></i>
          </CustomButton>
          <h4>New Request</h4>
          <div className={"avtar"}>
            {notification.user.avatar ? (
              <img src={notification.user.avatar} alt={"avtar"} />
            ) : (
              <h1>
                {(notification.user.first_Name
                  ? notification.user.first_Name
                  : notification.user.name
                ).charAt(0)}
              </h1>
            )}
            <div className={"text"}>
              {notification.user.first_Name
                ? notification.user.first_Name
                : notification.user.name}{" "}
              has sent request to you
            </div>
          </div>
          <div className={"Notification_Detail"}>
            <div className={"date"}>
              <h5>Dates</h5>
              <p>{`${notification.startDate} - ${notification.endDate}`}</p>
            </div>
            <div className={"price"}>
              <h5>Total Price</h5>
              <p>$ {notification.finalPrice}</p>
            </div>
          </div>

          <div className={"bottom_buttons"}>
            <CustomButton className={"button decline"} onClick={handleCancel}>
              Decline
            </CustomButton>
            <CustomButton className={"button"} onClick={handleOk}>
              Accpet
            </CustomButton>
          </div>
        </div>
      </div>
    </div>
  );
};

export default model;
