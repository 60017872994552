import Firebase from 'firebase/app';
import FirebaseApp from './config';
import 'firebase/auth';

import DataFirebase from './DataFirebase';
import mixpanel from 'mixpanel-browser'
import { dispatchAction } from '../Redux/Store';
import {
    authChanged
} from '../Redux/Actions/AuthActions';
import { setLoading } from '../Redux/Actions/CommonActions';
import { resetUser } from '../Redux/Actions/AuthActions';


class AuthFirebase {
    constructor() {
        this.auth = FirebaseApp.auth();
        this.googleProvider = new Firebase.auth.GoogleAuthProvider();
        this.facebookProvider = new Firebase.auth.FacebookAuthProvider();
        this.listenChanges();
        this.getRedirectResult();
    }

    _updateUserInfo = (user, callback) => {
        DataFirebase.setOnline(user.uid);
        DataFirebase.createUserInfo(user.uid, {
            uid: user.uid,
            avatar: user.photoURL,
            email: user.email,
            name: user.displayName,
            token: user.refreshToken,
            providerID: (user.providerData[0] || {}).providerId
        }).then(callback);
    }

    _updateAuthReducer = (user) => {
        let {
            email,
            emailVerified,
            displayName,
            uid,
            phoneNumber,
            photoURL
        } = user || {};

        mixpanel.identify(uid);

        dispatchAction(authChanged(
            user ?
                ({
                    email,
                    emailVerified,
                    displayName,
                    uid,
                    phoneNumber,
                    photoURL
                })
                :
                null
        ));
    }

    _isRedirected(status) {
        let key = 'reDirected';
        if (status === undefined) {
            return localStorage.getItem(key);
        } else if (status) {
            localStorage.setItem(key, status);
        } else {
            localStorage.removeItem(key);
        }
    }

    getRedirectResult = () => {
        if (this._isRedirected()) {
            dispatchAction(setLoading(true));
            this.auth.getRedirectResult()
                .then(result => {
                    this._isRedirected(false);
                    dispatchAction(setLoading(false));
                    if (result.credential) {
                        this._updateAuthReducer(result.user);
                        this._updateUserInfo(result.user);
                        
                    }
                })
                .catch(() => {
                    dispatchAction(setLoading(false));
                });
        }
    }

    listenChanges = () => {
        this.auth.onAuthStateChanged((user) => {
            this._updateAuthReducer(user);
            let { uid } = user || {};
            if (uid) {
                DataFirebase.setOnline(user.uid);
                DataFirebase.listenUserData(uid);
                DataFirebase.firebaseObserveChatList(uid)
                DataFirebase.firebaseObserveNotification(uid)
            }
        });

    }

    signInWithEmail = (email, password) => {
        return new Promise((resolve, reject) => {
            this.auth.signInWithEmailAndPassword(email, password)
                .then(resolve)
                .catch(reject);
        });
    }

    signUpWithEmail = (fullName, email, password) => {
        return new Promise((resolve, reject) => {
            this.auth.createUserWithEmailAndPassword(email, password)
                .then(() => {
                    let user = {
                        ...this.auth.currentUser,
                        displayName: fullName
                    };
                    this._updateUserInfo(user, () => {
                        this._updateAuthReducer(user);
                        this.auth.currentUser.updateProfile({
                            displayName: fullName
                        })
                        resolve();
                    });
                })
                .catch(reject);
        });
    }

    signInWithGoogle = () => {
        this._isRedirected(true);
        this.auth.signInWithRedirect(this.googleProvider);
    }

    signInWithFacebook = () => {
        this._isRedirected(true);
        this.auth.signInWithRedirect(this.facebookProvider);
    }

    sendForgotPasswordEmail = (email) => {
        return new Promise((resolve, reject) => {
            this.auth.sendPasswordResetEmail(email)
                .then(resolve)
                .catch(reject);
        });
    }

    resetPassword = (obbCode, newPassword) => {
        return this.auth.confirmPasswordReset(obbCode, newPassword);
    }

    logout = () => {
        let currentUser = this.auth.currentUser;
        this.auth.signOut().then(() => {
            dispatchAction(resetUser());
            DataFirebase.removeFirebaseChatListObserver()
            DataFirebase.removeFirebaseChatNotificationObserver(currentUser.uid)
        });

    }
}

export default new AuthFirebase();