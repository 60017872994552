export const ValidationMessages = {
    ENTER_FIRST_NAME: 'Please enter your first name.',
    Enter_VALID_FIRST_NAME: 'Please enter valid first name.',
    ENTER_LAST_NAME: 'Please enter your last name.',
    Enter_VALID_LAST_NAME: 'Please enter valid last name.',
    ENTER_EMAIL: 'Please enter your email.',
    ENTER_VALID_EMAIL: 'Please enter a valid email.',
    ENTER_PASSWORD: 'Please enter your password.',
    ENTER_VALID_PASSWORD: 'Please enter a valid password.',
    ENTER_NEW_PASSWORD: 'Please enter new password.',
    ENTER_PASSWORD_TO_CREATE: 'Please enter password to create.',
    ENTER_FULL_NAME: 'Please enter your full name.',
    ENTER_VALID_FULL_NAME: 'Please enter a valid full name.',
    ENTER_CONFIRM_PASSWORD: 'Please re-enter your password.',
    NO_MATCH_CONFIRM_PASSWORD: 'Re-entered password does not match.',
    PASSWORD_INFO: 'Password should be 8 character long and must contain uppercase, lowercase, digit and special character.',
    SELECT_COUNTRY: 'Please select country.',
    ENTER_CITY: 'Please enter city.',
    ENTER_STATE: 'Please enter state.',
    ENTER_ACCOUNT: 'Please enter account number',
    ENTER_ROUTING: 'Please enter routing number',
    ENTER_ADDRESS: 'Please enter address.',
    ENTER_POSTAL_CODE: 'Please enter postal code.',
    ENTER_PHONE_NUMBER: 'Please enter your phone number.',
    ENTER_VALID_PHONE_NUMBER: 'Please enter valid phone number.',
    ENTER_VALID_TIME: 'Please enter valid time',
    SELECT_EXPIRY_DATE: 'Select expiry date.',
    ENTER_CARD_NUMBER: 'Please enter card number.',
    ENTER_VALID_CARD_NUMBER: 'Please enter valid card number.',
    ENTER_CVV: 'Please enter CVV.',
    ENTER_VALID_CVV: 'Invalid CVV.',
    ENTER_PRODUCT_NAME: 'Please enter the product name',
    ENTER_RATE: 'Please enter valid price',
    ENTER_DESC: 'Please enter the description',
    ENTER_LOC: 'Please enter the location',
    ENTER_CATEGORY: 'Please choose category',
    ENTER_CONDITION: 'Please choose condition',
}

export const LOADING_MESSAGES = {
    LOADING: 'Loading ...',
    SIGNING_IN: 'Signing in ...'
}