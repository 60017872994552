import React from 'react';
import './styles.scss';
import {Images} from '../../../Shared/Assets'
import Header from '../../Molecule/Header';
import Footer from '../../Molecule/Footer';
import MetaTag from '../../Cell/Helmet';

const Page = ({
    className = '',
    product,
    sectionClassName = '',
    containerClassName = '',
    children,
    noPadding = false,
    noVerticalPadding = false,
    noHorizontalPadding = false,
    noHeader = false,
    noSection = false,
    noFooter = false,
    lightView = false,
    hidePost = true,
    renderHeaderSection = () => null
}) => {
    
    return (
        <div
            className={`
            page 
                ${className} 
                ${noPadding ?
                    'no-padding'
                    :
                    noVerticalPadding ?
                        'no-v-padding'
                        :
                        noHorizontalPadding ?
                            'no-h-padding'
                            :
                            ''
                }
            `}>
                <MetaTag product={product}/>
           
            {/* <div className={'header-information'}>
            <section>
            <img src={Icons.topIcon}/> <span> We-Assure got you covered. Learn more</span>
            </section>
                
                <section className="right-section">  
                <img src={Icons.X} className="X-icon" />

                </section>
            </div> */}

            {!noSection ?
            
                <div className={`header-section ${sectionClassName}`}>
                
                
                    {!noHeader &&
                        <Header
                            lightContent={false}//{!noSection}
                            showNewPost={hidePost}
                        />
                    }
                    <img className={'landing-Image'} alt={'landing-img'} src={Images.landingHome}></img>
                    <div className={'section-container'}>
                        {renderHeaderSection()}
                        
                    </div>
                </div>
                :
                !noHeader && 
                <Header 
                    lightContent={lightView}
                    showNewPost={hidePost}
                    
                />
            }
            <div className={`page-container ${containerClassName}`}>
                {children}
            </div>
            {!noFooter &&
                <Footer />
            }
        </div>
    )
}

export default Page;
