import React from "react";
import {
  AiFillHeart,
  AiFillShopping,
  AiOutlineHeart,
  AiOutlineShopping,
} from "react-icons/ai";
import {
  BsCaretLeftFill,
  BsCaretRightFill,
  BsCart,
  BsCartFill,
  BsChat,
  BsChatFill,
  BsHeart,
  BsHeartFill,
} from "react-icons/bs";

export const Icons = {
  messageLight: require("../Assets/Icons/message-light.png"),
  basketLight: require("../Assets/Icons/basket-light.png"),
  heartLight: require("../Assets/Icons/heart-light.png"),
  crossLight: require("../Assets/Icons/cross-light.png"),
  messageDark: require("../Assets/Icons/message-dark.png"),
  heartDark: require("../Assets/Icons/heart-dark.png"),
  basketDark: require("../Assets/Icons/basket-dark.png"),
  shareDark: require("../Assets/Icons/share-dark.png"),
  heartFilled: require("../Assets/Icons/heart-filled.png"),
  heartOrangeFilled: require("../Assets/Icons/heart-filled-secondary.png"),
  EditIcon: require("../Assets/Icons/edit.png"),
  heartGreyFilled: require("../Assets/Icons/heart-filled-grey.png"),
  ShipingIcon: require("../Assets/Icons/shipping.png"),
  Verifyicon: require("../Assets/Icons/verified.png"),
  sendMessage: require("../Assets/Icons/SendMessage.png"),
  delete: require("../Assets/Icons/delete.png"),
  view: require("../Assets/Icons/view.png"),
  delImg: require("../Assets/Icons/cross-dark.png"),
  logo: require("../Assets/Images/Logo1.png"),
  pause: require("../Assets/Icons/pause.png"),
  topIcon: require("../Assets/Icons/top-icon.png"),
  X: require("../Assets/Icons/X.png"),
  //Icons for placeholder
  floatLink: require("../Assets/Placeholder/Icons/float-link.png"),
  iosGreenLink: require("../Assets/Placeholder/Icons/ios-link-green.png"),
  iosLink: require("../Assets/Placeholder/Icons/ios-link.png"),
  angleUp: require("../Assets/Placeholder/Icons/angle-up.png"),
  serviceHome: require("../Assets/Placeholder/Icons/home.png"),
  serviceWallet: require("../Assets/Placeholder/Icons/wallet.png"),
  serviceLeaf: require("../Assets/Placeholder/Icons/leaf.png"),
  featureArrow: require("../Assets/Placeholder/Icons/arrow.png"),
  featureChat: require("../Assets/Placeholder/Icons/chat.png"),
  featureRefresh: require("../Assets/Placeholder/Icons/refresh.png"),
  featureShare: require("../Assets/Placeholder/Icons/share.png"),
};

export const Images = {
  appleButton: require("../Assets/Images/apple-button.png"),
  landingHome: require("../Assets/Images/home.png"),
  landingHomemobile: require("../Assets/Images/homemobile.png"),
  landingHomeSlider: require("../Assets/Images/bannerone.png"),
  landingHomeIllustration: require("../Assets/Images/banner-illustration.svg"),
  StepOne: require("../Assets/Images/step-one.png"),
  StepTwo: require("../Assets/Images/step-two.png"),
  StepThree: require("../Assets/Images/step-three.png"),
  One: require("../Assets/Images/Numberone.png"),
  two: require("../Assets/Images/Numbertwo.png"),
  three: require("../Assets/Images/Numberthree.png"),
  Business: require("../Assets/Images/business.png"),
  VideoBg: require("../Assets/Images/video-bg.png"),
  BgBlog1: require("../Assets/Images/bg-1.png"),
  BgBlog2: require("../Assets/Images/bg-2.png"),
  BgBlog3: require("../Assets/Images/bg-3.png"),
  BgBlog4: require("../Assets/Images/bg-4.png"),
  video: require("../Assets/Images/video.mp4"),
  program: require("../Assets/Images/program.png"),
  //Images for placeholder
  home: require("../Assets/Placeholder/Images/home.png"),
  about: require("../Assets/Placeholder/Images/about.png"),
  aboutPath: require("../Assets/Placeholder/Images/about-path.png"),
  feature: require("../Assets/Placeholder/Images/feature.png"),
  featuresPath: require("../Assets/Placeholder/Images/feature-path.png"),
  contact: require("../Assets/Placeholder/Images/contact.png"),
};

export const ReactIcon = {
  HeartIcon: ({ fill, ...rest }) =>
    fill ? <BsHeartFill {...rest} /> : <BsHeart {...rest} />,
  ShopIcon: ({ fill, ...rest }) =>
    fill ? <BsCartFill {...rest} /> : <BsCart {...rest} />,
  ChatIcon: ({ fill, ...rest }) =>
    fill ? <BsChatFill {...rest} /> : <BsChat {...rest} />,
  RightIcon: ({ fill, ...rest }) => <BsCaretRightFill {...rest} />,
  LeftIcon: ({ fill, ...rest }) => <BsCaretLeftFill {...rest} />,
};
