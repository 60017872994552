import React, { PureComponent } from 'react';
import './styles.scss';
import { Row, Col } from 'antd';
import ModalWrap from '../../Atom/Modal';
import { Modal } from 'react-bootstrap';
import CustomButton from '../../Atom/CustomButton';
import DataFirebase from '../../../Firebase/DataFirebase';
import { connect } from 'react-redux';
import { RouteConfig } from '../../../Config/RouteConfig';
import { Redirect } from 'react-router-dom';
import RouterService from '../../../Services/RouterService';

class ModalReset extends PureComponent {

    constructor(props) {
        super(props);
        this.state = {
            isRedirect: false,
            cartProductData: props.cartProductData
        }
    }
    
    cancel = () => {
        this.setState({
            isRedirect: true
        })
    }

    confirm = (isBooknow) => {
        // if(!isBooknow){
            DataFirebase.cartClearAllProduct(this.props.user.uid)
            DataFirebase.addedToCart(this.state.cartProductData, this.props.user.uid, isBooknow)
            .then(()=> {
                if (isBooknow) {
                    RouterService.pushRoute(RouteConfig.CHECKOUT);
                } else {
                this.setState({
                    isRedirect: true
                })
            }
            });
        // }
    }
    
    render(){
        return(
            <div className={'modalReset'}>

            { this.state.isRedirect ? <Redirect to={RouteConfig.LANDING}/> : null}
                <ModalWrap >   
                    <Modal.Dialog>
                        <Modal.Header className={'modal-head'}>
                        <Modal.Title><h3>Are You sure you want to reset</h3></Modal.Title>
                        </Modal.Header>
                        <Modal.Footer>
                                    <Row>
                                    <Col xs={11} sm={11} md={11} lg={11}>
                                         <CustomButton className={'btn-cancel'}
                                        //  onClick={this.cancel}
                                         onClick={this.props.isCloseClicked}
                                         
                                         >

                                                CANCEL
                                        </CustomButton>
                                    </Col>
                                    <Col xs={2} sm={2} md={2} lg={2}>

                                    </Col>
                                    <Col xs={11} sm={11} md={11} lg={11}>
                                        <CustomButton 
                                        className={'btn-confirm'}
                                        onClick={this.confirm.bind(this, this.props.isBooknow)}>
                                                CONFIRM
                                        </CustomButton>
                                    </Col>    
                                    </Row> 
                                </Modal.Footer>
                    </Modal.Dialog>
                </ModalWrap>
            </div>
        );
    }
}
const mapStateToProps = state => {
    return {
        user : state.AuthReducer.userData
    };
}

export default connect(mapStateToProps)(ModalReset);