import { CartActionTypes } from '../Actions/CartActions';

const defaultCartState = {
    bookNowProduct: { "categoryId": 1, "conditionId": 2, "desc": "Hat", "description_lowecased": "hat", "g": "dr5ru09uen", "hourlyRate": 2, "images": [{ "downloadURL": "https://firebasestorage.googleapis.com/v0/b/wicycle-9e525.appspot.com/o/product_images%2F-LRm2-w2gwgdsyZBHJqz.jpg?alt=media&token=2f92d654-0f13-4c9e-b670-969e62fb3658" }], "isArchived": false, "l": [40.740856099999995, -74.00136669999999], "locationId": "-LRm2-v99U6YufR_Lxlh", "name": "Hat", "name_lowercased": "hat", "userId": "4ZVC4bulmqaXqspfPnRz86zJeC72", "weeklyRate": 3, "id": "-LRm2-v99U6YufR_Lxlg", "condition": "Good", "ownerData": { "avatar": "https://lh4.googleusercontent.com/-kYuVD4L5mBU/AAAAAAAAAAI/AAAAAAAAF_g/Z9zefOon-cQ/s96-c/photo.jpg", "email": "kb.tanvir@gmail.com", "favoritesId": { "-LRsLjFCIJvyNf-G2R8M": "-LRsLjFCIJvyNf-G2R8M", "-LVyti23RK4Jx2cAxsvZ": "-LVyti23RK4Jx2cAxsvZ" }, "name": "kb chowdhury", "providerID": "google.com", "token": "AEu4IL0s5ytrUan7ZXKkaiNH-68rcweDzB7zV5bkOmnjta9VgGcOZGTJm99yw7rRMPEoj0UYSYep_2L3GrvPsscpgZvfbONUP16jyacbC8LaqgiVbk2n4MLtZLIzgvnJqxY447xHSAYo4IgWXFX8ImZJR__ZGshQeKG2sDr6KPbJpZYeesvoJJH_VmoCnb8VjkyoFh2z4wNWcR1GMM__6VtVbhE-k738J1Bl5GVnJ_BawgnAGgIDX5k1TzSgIjK9dLn8CO66jRHcBx9kKpdhDOi3huO50BbDvnv4CHhwwgJWbybqbfwM7L1xLHZPI21temZhDdDwWsro", "uid": "4ZVC4bulmqaXqspfPnRz86zJeC72" } },
    cartProducts: [],
    isLoading: false
}

const CartReducer = (state = { ...defaultCartState }, action) => {
    switch (action.type) {
        case CartActionTypes.BOOK_NOW:
            return {
                ...state,
                bookNowProduct: action.product
            };

        case CartActionTypes.CLEAR_BOOK_NOW:
            return {
                ...state,
                bookNowProduct: null
            };

        case CartActionTypes.ADD_CART:
        let cartProduct = action.product;
        let productSimilar = state.cartProducts.find(cartProducts => cartProducts.product.id === cartProduct.product.id);

        if (!productSimilar) {
            let cart = [...state.cartProducts, cartProduct];
            return {
                ...state,
                cartProducts: cart,
                isLoading : false
            };
        }
        return { ...state };    

        case CartActionTypes.CLEAR_CART:
        return {
            ...state,
            cartProducts: [],
            
        };  

        case CartActionTypes.SHOW_LOADER:
            return {
                ...state,
                isLoading: true
            };

        default:
            return { ...state };
    }
}

export default CartReducer;