import React from 'react';
import './styles.scss';

import { withGoogleMap, GoogleMap, Circle } from 'react-google-maps';
import { ColorConstants } from '../../../Shared/Constants';


const CustomGoogleMap = withGoogleMap(({
    center = { lat: -34.397, 
                lng: 150.644 },
    options= {  strokeWeight: 1,
                strokeColor: ColorConstants.COLOR_GREEN, 
                fillColor: ColorConstants.COLOR_GREEN }
}) => {
    return (
           <GoogleMap
                defaultZoom={16}
                defaultCenter={center}
                >
            
                <Circle
                  defaultCenter={center}
                  radius={350}
                  options={options}
                />
               
           </GoogleMap>
    );
});
export default CustomGoogleMap;
