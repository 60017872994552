import React from "react";
import "./style.scss";
import CustomButton from "../../../Atom/CustomButton";
import { Row, Col } from "antd";
import { RouteConfig } from "../../../../Config/RouteConfig";
import RouterService from "../../../../Services/RouterService";

const ProductDetails = ({ message = {} }) => {
  let onProductDetails = (productKey) => {
    RouterService.pushRoute(`${RouteConfig.PRODUCT}/${productKey}`);
  };
  // let productImages = message.product.images.map(image => image.downloadURL);

  let value = "";

  if (message.product.id) {
    value = (
      <div>
        <h4 className="mx-3">{message.product.name}</h4>
        {/* <h2 className={"inbox-product-title"}>Product Details</h2> */}
        <hr />
        <div className="mx-3">
          {/* <br/> */}
          <div className="d-flex justify-content-between">
            <strong>
              <p>Rate</p>
            </strong>
            <strong>
              <p>
                ${message.product.hourlyRate} D / ${message.product.weeklyRate}{" "}
                W
              </p>
            </strong>
          </div>
          <div className="d-flex justify-content-between">
            <strong>
              <p>Location</p>
            </strong>
            <strong>
              <p>
                {message.product.address.address.street +
                  " " +
                  message.product.address.address.state +
                  " " +
                  message.product.address.address.country +
                  " " +
                  message.product.address.address.zipCode}
              </p>
            </strong>
          </div>
          <div className="d-flex justify-content-between">
            <strong>
              <p>Condition</p>
            </strong>
            <strong>
              <p>{message.product.condition}</p>
            </strong>
          </div>
        </div>
        <Row className={"more-btn-wrap"}>
          <CustomButton
            className={"more-btn"}
            onClick={onProductDetails.bind(this, message.product.id)}
          >
            More
          </CustomButton>
        </Row>
      </div>
    );
  }

  return <div className={"inbox-product"}>{value}</div>;
};

export default ProductDetails;
