import React from 'react';
import './styles.scss';

import CustomHeader from '../CustomHeader';
import { Layout } from 'antd';
let { Content } = Layout;

const SectionWrapper = ({
    children,
    headerProps = {},
    id,
    home,
    isHide,
    ...props
}) => {
    return (
        <Layout className={'section'} id={id} {...props}>
          { !isHide ?  <CustomHeader
                home={home}
                activeId={id}
            /> : null }
            <Content className={'placeholder-section-container'}>
                {children}
            </Content>
        </Layout>
    );
}

export default SectionWrapper;
