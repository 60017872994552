import React from 'react';
import './styles.scss';

import { connect } from 'react-redux';
import { Spin } from 'antd';
import { setLocation } from '../../../Redux/Actions/AuthActions';
import OnlineStatus from '../../Atom/OnlineStatus';

const UIProvider = ({
    loading,
    children
}) => {
    return (
        <Spin
            size={'large'}
            spinning={loading}
        >
            <OnlineStatus />
            {children}
        </Spin>
    );
}

const mapStateToProps = state => {
    return {
        loading: state.CommonReducer.loading
    };
}

export default connect(mapStateToProps, { setLocation })(UIProvider);