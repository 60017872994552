import React from 'react';
import './styles.scss';
import Page from '../../Components/HOC/Page';
import { About } from '../../Components/Placeholder/Sections';
import Footer from '../../Components/Molecule/Footer';


const AboutUs = () => {
 
    return (
        <div
        className={'AboutUs'}>
            <Page
            noSection
            className={'landing-page'}> 
            <About isHide={true}/>
            </Page>
        </div>
    )
}

export default AboutUs;