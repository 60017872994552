import React, { Component } from "react";
import Page from "../../Components/HOC/Page";
import UserBanner from "../../Components/Cell/UserBanner";
import { connect } from "react-redux";
import { Tabs } from "antd";
import "./styles.scss";
import PersonalDetail from "../../Components/Molecule/UserPersonalDetail";
import Verification from "../../Components/Molecule/Verification";
import ManagePosts from "../../Components/Molecule/ManagePosts";
import Reviews from "../../Components/Molecule/Reviews";
import { Redirect } from "react-router-dom";
import { RouteConfig } from "../../Config/RouteConfig";
import TagManager from "react-gtm-module";

class Profile extends Component {
  tabDidScrolled = (index) => {
    switch (index) {
      case 0:
        return <PersonalDetail />;
      case 1:
        return <Verification />;
      case 2:
        return <ManagePosts />;
      // case 3:
      //   return <Reviews />;
      default:
        return null;
    }
  };

  render() {
    let tagManagerArgs = {
      dataLayer: {
        page: "Landing",
      },
      dataLayerName: "PageDataLayer",
    };

    TagManager.dataLayer(tagManagerArgs);

    const { TabPane } = Tabs;
    let heading = [
      "PERSONAL DETAIL",
      "VERIFICATION",
      "MANAGE POSTS",
      // "REVIEWS",
      // "PAYMENT",
      // "SETTING",
      // "HELP",
    ];
    let page = null;
    if (!this.props.user) {
      page = <Redirect to={RouteConfig.LANDING} />;
    } else {
      page = (
        <div>
          <UserBanner userData={this.props.user} />
          <div className={"user-Profile-Content"}>
            <Tabs
              defaultActiveKey="0"
              className={"user_profile_tab _verificationTabs"}
            >
              {heading.map((name, index) => {
                return (
                  <TabPane tab={name} key={index}>
                    {this.tabDidScrolled(index)}
                  </TabPane>
                );
              })}
            </Tabs>
          </div>
        </div>
      );
    }
    return (
      <Page
        className={"user-profile"}
        noSection={true}
        noPadding={true}
        lightView={false}
      >
        {page}
      </Page>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.AuthReducer.userData,
  };
};

export default connect(mapStateToProps)(Profile);
