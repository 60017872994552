import React from "react";
import { Row } from "react-bootstrap";
import "./styles.scss";
import SentButton from "../../../../Assets/Icons/SendButton.png";
import AttachFile from "../../../../Assets/Icons/AttachFile.png";
import { connect } from "react-redux";
import Database from "../../../../Firebase/DataFirebase";
import ExifOrientationImg from "react-exif-orientation-img";
import { removeChatMessageData } from "../../../../Redux/Actions/ChatAction";
import { dispatchAction } from "../../../../Redux/Store";
import firebase from "firebase/app";
import DateExtension from "../../../../Services/DateExtention";
import TimeAgo from "react-timeago";
import CustomUploadButton from "react-firebase-file-uploader/lib/CustomUploadButton";
import APIManager from "../../../../APIManager";

class Inbox extends React.Component {
  messagesEndRef = React.createRef();
  state = {
    textMessage: "",
  };

  scrollToBottom = () => {
    if (this.messagesEndRef && this.messagesEndRef.current) {
      this.messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  componentDidUpdate() {
    this.scrollToBottom();
  }

  componentWillMount() {
    Database.firebaseObserveChatMessage(
      this.props.message.id,
      this.props.user.uid
    );
  }

  componentWillUnmount() {
    Database.firebaseRemoveObserveChat(this.props.message.id);
    dispatchAction(removeChatMessageData());
  }

  shouldComponentUpdate(nextProps, nextState) {
    return this.props !== nextProps || this.state !== nextState;
  }

  getReceiveMessage = (message) => {
    var t = new Date(message.time);
    return (
      <Row className={"wrap-recieved"} key={message.time}>
        <div ref={this.messagesEndRef}>
          <p className={"recieved"}>
            {message.text ? (
              message.text
            ) : (
              <ExifOrientationImg
                className={"msg-image"}
                src={message.photoURL}
              />
            )}
          </p>
          <div className={"after_1"}></div>
          <p className={"msg-time-left"}>
            <TimeAgo date={t} />
          </p>
        </div>
      </Row>
    );
  };

  sendMessage = (event) => {
    event.preventDefault();
    if (this.state.textMessage.trim().length > 0) {
      Database.sendTextMessage(
        this.props.user,
        this.state.textMessage.trim(),
        this.props.message.id,
        this.props.message.user.uid
      ).then(() => {
        this.triggerPushNotification();
        this.setState({
          ...this.state,
          textMessage: "",
        });
      });
    }
  };

  triggerPushNotification = () => {
    let requireData = {
      senderDisplayName: this.props.user.first_Name
        ? this.props.user.first_Name
        : this.props.user.name,
      channelID: this.props.message.id,
      channelName: this.props.user.first_Name
        ? this.props.user.first_Name
        : this.props.user.name,
      productId: this.props.message.product.id,
      "custom-payload-id": this.props.message.product.id,
      notificationType: "0",
    };
    let parameters = {
      to: this.props.message.user.token,
      priority: "high",
      mutable_content: true,
      notification: {
        title: `${
          this.props.user.first_Name
            ? this.props.user.first_Name
            : this.props.user.name
        } send you a message`,
        body: `${
          this.props.user.first_Name
            ? this.props.user.first_Name
            : this.props.user.name
        } send you a message`,
        sound: "default",
        badge: 0,
      },
      data: requireData,
      notificationType: 0,
    };
    APIManager.chatPushNotification(parameters);
  };

  getSendMessage = (message) => {
    var t = new Date(message.time);
    return (
      <Row className={"wrap-send"} key={message.time}>
        <div ref={this.messagesEndRef}>
          <p className={"send"}>
            {message.text ? (
              message.text
            ) : (
              <ExifOrientationImg
                className={"msg-image"}
                src={message.photoURL}
              />
            )}
          </p>
          <div className={"after"}></div>
          <p className={"msg-time"}>
            <TimeAgo date={t} minPeriod={5} />
          </p>
        </div>
      </Row>
    );
  };

  handleUploadSuccess = (filename) => {
    firebase
      .storage()
      .ref(`ChatImages/${this.props.user.uid}`)
      .child(filename)
      .getDownloadURL()
      .then((url) => {
        Database.sendImageMessage(
          this.props.user,
          url,
          this.props.message.id,
          this.props.message.user.uid
        );
      });
  };

  render() {
    let messages = this.props.chat.map((message) => {
      if (message.messageType === 1) {
        return (
          <div className={"req-accept-wrap"} key={message.time}>
            <p className={"req-accept"}>Request Accepted</p>
          </div>
        );
      }
      if (this.props.message.user.uid === message.senderId) {
        return this.getReceiveMessage(message);
      } else {
        return this.getSendMessage(message);
      }
    });

    return (
      <div className={"inbox"}>
        <div>
          <div className={"chat-wrap"}>
            <Row className={"inbox-head"}>
              {this.props.message.user.avatar ? (
                <img
                  src={this.props.message.user.avatar}
                  className={"avatar"}
                  alt="profile"
                />
              ) : (
                <h1 className={"avatar-text"}>
                  {(this.props.message.user.first_Name
                    ? this.props.message.user.first_Name
                    : this.props.message.user.name
                  ).charAt(0)}
                </h1>
              )}

              <p className={"profile-name"}>
                {this.props.message.user.first_Name}{" "}
                {this.props.message.user.last_Name} <br />
                <span className={"time"}>Online</span>
              </p>
            </Row>
            <Row className={"chat-box"}>
              <Row className={"msgs"}>{messages}</Row>
            </Row>
          </div>
          <Row>
            <div className={"field-wrap"}>
              <div className={"attachFile-wrap"}>
                <CustomUploadButton
                  accept="image/*"
                  filename={(file) => DateExtension.getTimeStamp()}
                  onUploadSuccess={this.handleUploadSuccess}
                  storageRef={firebase
                    .storage()
                    .ref(`ChatImages/${this.props.user.uid}`)}
                >
                  <img
                    src={AttachFile}
                    alt="AttachFile"
                    className={"AttachFile"}
                  />
                </CustomUploadButton>
              </div>
              <form className={"form"} onSubmit={this.sendMessage}>
                <input
                  type="text"
                  className={"input-msg"}
                  placeholder="Type a message here…"
                  onChange={(event) => {
                    this.setState({
                      ...this.state,
                      textMessage: event.target.value,
                    });
                  }}
                  value={this.state.textMessage}
                ></input>
              </form>
              <img
                src={SentButton}
                alt="SentButton"
                className={"SentButton"}
                onClick={this.sendMessage}
              />
            </div>
          </Row>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    chat: state.ChatReducer.chat,
    user: state.AuthReducer.userData,
  };
};

export default connect(mapStateToProps)(Inbox);
