
export const SEO = {
    '/' : {
        title : 'WeCycle (Beta)',
        description: 'Rent what you want and Lend what you don\'t',
        og_Title : ''
    },
    '/product/:productKey' : {
        title : 'WeCycle (Beta)',
        description: 'Search product you want to buy or want to lend'
    },

}



