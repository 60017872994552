import React from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import "./style.scss";

export default function ProductGalleryLoader() {
  return (
    <div className="loader">
      <div className="gallery-view">
        {Array.from({ length: 10 }).map((_, ind) => (
          <Skeleton key={ind} height={350} width={250}/>
        ))}
      </div>
    </div>
  );
}
