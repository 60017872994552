import React, { Component } from "react";
import "./styles.scss";
import { Popover } from "antd";
import FilterCalender from "../FilterCalender";
import { DateUtils } from "react-day-picker";
import moment from "moment";
import PriceFilter from "../PriceFilter";
import ConditionFilter from "../ConditionFilter";
import CategoryFilter from "../CategoryFilter";
import { connect } from "react-redux";
import {
  setPriceRange,
  clearPriceRange,
  setConditionFilter,
  clearConditionFilter,
  clearRatingFilter,
  setUserRatingFilter,
  setProductRatingFilter,
  clearProductRatingFilter,
  setCategoryFilter,
  clearCategoryFilter,
} from "../../../Redux/Actions/FilterAction";
import DataFirebase from "../../../Firebase/DataFirebase";
import PropTypes from "prop-types";
import FilterUserRating from "../FilterUserRating";

const filters = [
  //"Date",
  "Price",
  // "Product Rating",
  // "User Rating",
  "Condition",
  "Category",
  // "Type",
  // "Verification Label Badge",
];

class FilterBar extends Component {
  state = {
    from: undefined,
    range: undefined,
    to: undefined,
    selectedIndex: null,
    isDateFilterApplied: false,
    isConditionApplied: false,
    isCategoryApplied: false,
    priceRange: null,
    selectedConditions: [],
    selectedCategory: [],
  };

  getInitialState() {
    return {
      from: null,
      to: null,
      selectedIndex: null,
      isDateFilterApplied: false,
    };
  }

  componentWillMount() {
    let { range, from, to, conditions, categories } = this.props;
    this.setState({
      from: to,
      to: from,
      priceRange: range,
      selectedConditions: conditions,
      selectedCategory: categories,
    });
  }

  handleResetClick = () => {
    this.setState(this.getInitialState());
  };

  handleDayClick = (day) => {
    const range = DateUtils.addDayToRange(day, this.state);
    this.setState(range);
  };

  dateApplyClicked = () => {
    this.setState({ selectedIndex: null, isDateFilterApplied: true });
    // Filter Data According to this
  };

  priceApplyClicked = () => {
    this.setState({ selectedIndex: null });
    this.props.onFilterPriceChanged(this.state.priceRange);
  };

  handlePriceReset = () => {
    this.setState({ selectedIndex: null });
    this.props.onClearFilterPrice();
  };

  handleConditionReset = () => {
    this.setState({ selectedIndex: null, selectedConditions: [] });
    this.props.onClearFilterCondition();
  };

  handleConditionImplemented = (event) => {
    if (this.state.selectedConditions.length > 0) {
      this.setState({ selectedIndex: null });
      this.props.onFilterConditionChanged(this.state.selectedConditions);
    } else {
      this.setState({ selectedIndex: null, selectedConditions: [] });
      this.props.onClearFilterCondition();
    }
  };

  handleCategoryReset = () => {
    this.setState({ selectedIndex: null, selectedCategory: [] });
    this.props.onClearFilterCategory();
  };

  handleCategoryImplemented = (event) => {
    if (this.state.selectedCategory.length > 0) {
      this.setState({ selectedIndex: null });
      this.props.onFilterCategoryChanged(this.state.selectedCategory);
    } else {
      this.setState({ selectedIndex: null, selectedCategory: [] });
      this.props.onClearFilterCategory();
    }
  };

  handleRatingReset = () => {
    this.setState({ selectedIndex: null });
    this.props.onClearRating();
  };

  handleRatingApply = (event) => {
    this.setState({ selectedIndex: null });
    this.props.onFilterRatingChanged(event);
  };

  handleProductRatingReset = () => {
    this.setState({ selectedIndex: null });
    this.props.onClearProductRating();
  };

  handleProductRatingApply = (event) => {
    this.setState({ selectedIndex: null });
    this.props.onFilterProductRatingChanged(event);
  };

  getPopOverContent = (index) => {
    let caledarFilter = (
      <FilterCalender
        from={this.state.from}
        to={this.state.to}
        range={this.state.range}
        handleDayClick={this.handleDayClick}
        handleResetClick={this.handleResetClick}
        clearClicked={this.handleResetClick}
        applyClicked={this.dateApplyClicked}
      />
    );

    let priceFilter = (
      <PriceFilter
        sliderChanged={(event) => {
          this.setState({ priceRange: event });
        }}
        clearClicked={this.handlePriceReset}
        applyClicked={this.priceApplyClicked}
      />
    );

    let conditionFilter = (
      <ConditionFilter
        conditions={this.props.allConditions}
        clearClicked={this.handleConditionReset}
        applyClicked={this.handleConditionImplemented}
        selectedItems={this.state.selectedConditions}
        itemsSelected={(items) => {
          this.setState({
            ...this.state,
            selectedConditions: items,
          });
        }}
      />
    );

    let categoryFilter = (
      <CategoryFilter
        conditions={this.props.allCategories}
        clearClicked={this.handleCategoryReset}
        applyClicked={this.handleCategoryImplemented}
        selectedItems={this.state.selectedCategory}
        itemsSelected={(items) => {
          this.setState({
            ...this.state,
            selectedCategory: items,
          });
        }}
      />
    );

    let filterRating = (
      <FilterUserRating
        selectedFilter={this.props.userRate}
        clearClicked={this.handleRatingReset}
        applyClicked={this.handleRatingApply}
      />
    );

    let productRating = (
      <FilterUserRating
        selectedFilter={this.props.productRate}
        clearClicked={this.handleProductRatingReset}
        applyClicked={this.handleProductRatingApply}
      />
    );

    switch (index) {
      // case 0:
      //   return caledarFilter;

      case 0:
        return priceFilter;

      case 1:
        return conditionFilter;

      case 2:
        return categoryFilter;

      // case 2:
      //   return productRating;

      // case 3:
      //   return filterRating;

      default:
        return null;
    }
  };

  render() {
    let { className } = this.props;
    return (
      <div className={`filter-bar ${className}`}>
        {filters.map((filter, index) => {
          var content = this.getPopOverContent(index);
          var name = filter;
          var selectedClass = false;

          if (
            this.state.to &&
            this.state.from &&
            index === 999 &&
            this.state.isDateFilterApplied
          ) {
            selectedClass = true;
            name = `${moment(this.state.from).format("d MMM")} - ${moment(
              this.state.to
            ).format("d MMM")}`;
          } else if (this.props.range && index === 0) {
            selectedClass = true;
            name = `${this.props.range[0]} - ${this.props.range[1]}`;
          } else if (
            this.props.productRate !== null &&
            this.props.productRate !== undefined &&
            index === 999
          ) {
            selectedClass = true;
            name = `${
              this.props.productRate === 0
                ? "Any"
                : `${this.props.productRate} Star`
            }`;
          } else if (
            this.props.userRate !== null &&
            this.props.userRate !== undefined &&
            index === 999
          ) {
            selectedClass = true;
            name = `${
              this.props.userRate === 0 ? "Any" : `${this.props.userRate} Star`
            }`;
          } else if (
            this.props.conditions?.length > 0 &&
            index === 1
          ) {
            selectedClass = true;
            let cond = this.props.conditions.map((condition) => {
              return condition.name;
            });
            name = `${cond.join(", ")}`;
          } else if (
            this.props.categories?.length > 0 &&
            index === 2
          ) {
            selectedClass = true;
            let cond = this.props.categories.map((category) => {
              return category.name;
            });
            name = `${cond.join(", ")}`;
          }

          return (
            <Popover
              key={index}
              placement="bottomLeft"
              title={filter}
              content={content}
            >
              <span
                className={`filter-item ${
                  selectedClass ? "filter-Selected" : ""
                }`}
                onClick={() => this.setState({ selectedIndex: index })}
              >
                {name}
              </span>
            </Popover>
          );
        })}
      </div>
    );
  }
}

FilterBar.defaultProps = {
  className: "",
};

FilterBar.propTypes = {
  className: PropTypes.string,
};

const mapStateToProps = (state) => {
  return {
    range: state.FilterReducer.range,
    conditions: state.FilterReducer.conditions,
    allConditions: state.ProductReducer.conditions,
    categories: state.FilterReducer.categories,
    allCategories: state.ProductReducer.category,
    userRate: state.FilterReducer.userRate,
    productRate: state.FilterReducer.productRate,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onFilterPriceChanged: (range) => dispatch(setPriceRange(range)),
    onClearFilterPrice: () => dispatch(clearPriceRange()),
    onFilterConditionChanged: (items) => dispatch(setConditionFilter(items)),
    onClearFilterCondition: () => dispatch(clearConditionFilter()),
    onFilterCategoryChanged: (items) => dispatch(setCategoryFilter(items)),
    onClearFilterCategory: () => dispatch(clearCategoryFilter()),
    onFilterRatingChanged: (rate) => dispatch(setUserRatingFilter(rate)),
    onClearRating: () => dispatch(clearRatingFilter()),
    onFilterProductRatingChanged: (rate) =>
      dispatch(setProductRatingFilter(rate)),
    onClearProductRating: () => dispatch(clearProductRatingFilter()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FilterBar);
