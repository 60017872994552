export const GEOCODE_API = 'AIzaSyBIm_78EWbeigfa5yYdYydzGGcWILjBNSM';

export const CATEGORIES = {
    POPULAR: 'popular'
}

export const iOSLink = 'https://itunes.apple.com/us/app/wecycle/id1418742905?mt=8';

export const NYCAddress = {
    coords: [40.730610, -73.935242],
    address: 'New York City'
}

export const AuthTab = {
    LOGIN: {
        id: 1,
        event: 'login',
        title: 'Login'
    },
    SIGNUP: {
        id: 2,
        event: 'signup',
        title: 'Signup'
    },
    FORGOT_PASSWORD: {
        id: 3,
        event: 'forgot_password',
        title: ''
    }
}