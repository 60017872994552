import React from 'react';
import './styles.scss';

import { Alert } from 'antd';


const CustomAlert = ({
    className = '',
    type = 'error',
    message
}) => {
    if (!(!!message)) return null;
    return (
        <Alert
            className={`custom-alert ${className}`}
            type={type}
            message={message}
        />
    );
}

export default CustomAlert;
