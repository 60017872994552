export const RouteConfig = {
    LANDING: '/',
    PLACEHOLDER: '/placeholder',
    PRODUCT: '/product',
    PRODUCTS: '/products',
    CART: '/cart',
    FAVORITES: '/favorites',
    CHECKOUT: '/checkout',
    TERMS_OF_SERVICES: '/terms-of-services',
    PRIVACY_POLICY: '/privacy-policy',
    RESET_PASSWORD: '/reset-password',
    BLOGS: '/blogs',
    BLOG: '/blog',
    CHAT: '/chat',
    PROFILE: '/profile',
    USERPROFILE: '/user-profile',
    POST: '/post',
    LIKE: '/like',
    ABOUT: '/about-us',
    CONTACT: '/contacts',
    BUSINESS: '/business-contact',
}