import { combineReducers } from 'redux';

import CommonReducer from './CommonReducer';
import AuthReducer from './AuthReducer';
import ProductReducer from './ProductReducer';
import CartReducer from './CartReducer';
import ChatReducer from './ChatReducer';
import FilterReducer from './FilterReducer'
import BlogReducer from './BlogReducer'

export default combineReducers({
    CommonReducer,
    AuthReducer,
    BlogReducer,
    ProductReducer,
    CartReducer,
    ChatReducer,
    FilterReducer
});