import axios from 'axios';
import { dispatchAction } from '../Redux/Store/index';
import { setBlogs } from '../Redux/Actions/BlogActions'

class APIManager {
  // https://us-central1-wecycleprod.cloudfunctions.net/widgets/
  // https://us-central1-wicycle-9e525.cloudfunctions.net/widgets/
  // baseURL: 'https://us-central1-wecycleprod.cloudfunctions.net/widgets/',
  // baseURL: 'https://us-central1-wicycle-9e525.cloudfunctions.net/widgets/',
  constructor() {
    this.axiosInstance = axios.create({
      baseURL: 'https://us-central1-wecycleprod.cloudfunctions.net/widgets/',
      timeout: 10000,
      headers: {
        'Content-Type': 'application/json',
        "Access-Control-Allow-Credentials": true
      }
    });
  }
  //http://tanbel.com/blog/wc/wp-json/wp/v2/posts?_embed
  getAllBlogs() {
    axios.get('https://tanbel.com/blog/wc/wp-json/wp/v2/posts?_embed')
      .then(function (response) {
        dispatchAction(setBlogs(response.data))
      })
      .catch(function (error) {
      })
  }
chatPushNotification(param)
{
  axios.post('https://fcm.googleapis.com/fcm/send', param, {headers: {
    "Content-Type": "application/json",
    "Authorization": "key=AAAA-gYvrOA:APA91bFPQtMj8HEOZadl-Ns5_dfUEjhpZxaNJT1nw3RphKCEKrjCxKjyGOigfmVN5zihQiBpCMe0e8u_kMIRBe9BRg_dNag7rb2jgrO4mX2v_O8S1jQxOppUXiTP-CZgwuBY9K5hxpM_puhXMqtDvzs-8lSA0oWZMQ"
 }})
  .then(function(response)
  {
    console.log(response,'push Notification success')
  })
  .catch(function(error)
  {
    console.log(error,'push Notification fail')
  })
}

  payAmount(param) {
    return new Promise((resolve, reject) => {
      this.axiosInstance.post('pay', param)
        .then(function (response) {
          resolve(response)
        })
        .catch(function (error) {
          console.log(error)
          reject(error)
        })
    });
  }

  addCard(param) {
    return new Promise((resolve, reject) => {
      this.axiosInstance.post('saveCard', param)
        .then(function (response) {
          resolve(response)
        })
        .catch(function (error) {
          reject(error)
        })
    });
  }

  getAllCards(param) {
    return new Promise((resolve, reject) => {
      this.axiosInstance.post('getAllCards', param)
        .then(function (response) {
          resolve(response)
        })
        .catch(function (error) {
          reject(error)
        })
    });
  }

  deleteCard(param) {
    return new Promise((resolve, reject) => {
      this.axiosInstance.post('deleteCard', param)
        .then(function (response) {
          resolve(response)
        })
        .catch(function (error) {
          reject(error)
        })
    })
  }

  updateDefaultCard(param) {
    return new Promise((resolve, reject) => {
      this.axiosInstance.post('updateSource', param)
        .then(function (response) {
          resolve(response)
        })
        .catch(function (error) {
          reject(error)
        })
    })
  }
 
  sendMail(params) {
    return new Promise((resolve, reject) => {
      this.axiosInstance.post('sendemail', params).then((response) => {
        resolve(response)
      })
        .catch(() => {
          reject()
        })
    });
  }
}



export default new APIManager()