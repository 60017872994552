import React, { Component } from "react";
import "./styles.scss";
import firebase from "firebase";
import { connect } from "react-redux";
import Database from "../../../Firebase/DataFirebase";
import { Button, Icon } from "antd";
import Spinner from "../../Atom/Loader/Circle";

class Verification extends Component {
  setRef = (webcam) => {
    this.webcam = webcam;
  };

  state = {
    isGovtUploading: false,
    image: null,
    isWebCamEnabled: false,
    front: null,
    back: null,
    isImageUploaded: false,
    visible: false,
  };

  constructor(props) {
    super(props);
    let front_side = null;
    let back_side = null;
    if (props.user.govtProof) {
      front_side = this.props.user.govtProof?.front_side
        ? this.props.user.govtProof?.front_side
        : null;
      back_side = this.props.user.govtProof?.back_side
        ? this.props.user.govtProof?.back_side
        : null;
    }
    this.state = {
      front: front_side,
      back: back_side,
    };
  }

  handleCancel = () => {
    this.setState({
      visible: false,
    });
  };

  handleUploadSuccess = (filename) => {
    firebase
      .storage()
      .ref(`user_verification/${this.props.user.uid}/govt`)
      .child(filename)
      .getDownloadURL()
      .then((url) => {
        this.setState({
          ...this.state,
          isImageUploaded: true,
          back: url,
        });
      });
  };

  toggleLoading = (status) => {
    this.setState({
      ...this.state,
      isImageUploaded: status,
    });
  };

  updatebackendImages = () => {
    let detail = {
      back_side: this.state.back,
      front_side: this.state.front,
    };
    console.log(detail);
    this.toggleLoading(true);
    Database.updateGovtDocuments(this.props.user.uid, detail).then(() => {
      Database.updateUserProfile(this.props.user.uid, {
        proofVerifiedStatus: 1,
      });
      this.toggleLoading(false);
    });
  };

  removeImageFromServer = (image) => {
    Database.removeGovtDocuments(this.props.user.uid, image).then(() => {
      this.setState({
        ...this.state,
        front: null,
        back: null,
      });
    });
    Database.updateUserProfile(this.props.user.uid, { proofVerifiedStatus: 0 });
  };

  onSelect = (e, state) => {
    this.toggleLoading(true);
    const blob = e.target.files[0];
    const baseImg = new FileReader();
    baseImg.readAsDataURL(blob);
    baseImg.onload = () => {
      const base64 = baseImg.result;
      const data = base64.split(",")[1];
      let ref = firebase
        .storage()
        .ref(
          `user_verification/${this.props.user.uid}/${new Date().getTime()}`
        );

      ref
        .putString(data, "base64", {
          contentType: "image/jpeg",
        })
        .then(
          (res) => {
            ref.getDownloadURL().then((url) => {
              this.toggleLoading(false);
              this.setState({
                ...this.state,
                [state]: url,
              });
            });
          },
          (err) => {
            this.toggleLoading(false);
            console.log("image upload failed");
          }
        );
    };
  };

  render() {
    let front_side = this.state.front;
    let back_side = this.state.back;
    const isChanged = () => {
      if (
        this.state.front !== this.props.user.govtProof?.front_side ||
        this.state.back !== this.props.user.govtProof?.back_side
      ) {
        return true;
      } else {
        return false;
      }
    };
    let status = this.state.isImageUploaded;
    return status ? (
      <Spinner />
    ) : (
      <div className={"user-Detail-verification-detail"}>
        <div className={"user-detail-verification-model"}>
          <label
            htmlFor="front"
            className={`user-detail-selfie user-detail-borderImagePosition`}
          >
            {front_side ? (
              <img
                src={front_side}
                width={340}
                height={140}
                style={{
                  objectFit: "cover",
                }}
              />
            ) : (
              <></>
            )}
          </label>
          <label
            htmlFor="back"
            className={`user-detail-govtDocument user-detail-borderImagePosition`}
          >
            {back_side ? (
              <img
                src={back_side}
                width={340}
                height={140}
                style={{
                  borderRadius: 10,
                  objectFit: "cover",
                }}
              />
            ) : (
              <></>
            )}
          </label>
          <input
            id="front"
            type="file"
            className="d-none"
            onChange={(e) => this.onSelect(e, "front")}
          />
          <input
            id="back"
            type="file"
            className="d-none"
            onChange={(e) => this.onSelect(e, "back")}
          />
          {!!front_side && !!back_side && (
            <div style={{ display: "flex", flexDirection: "row", gap: 10 }}>
              {isChanged() && (
                <Button
                  onClick={this.updatebackendImages}
                  size="large"
                  style={{ marginTop: 20 }}
                  type="primary"
                >
                  Save Changes
                </Button>
              )}
              <Button
                onClick={this.removeImageFromServer}
                size="large"
                style={{ marginTop: 20 }}
                type="danger"
              >
                Remove Identity
              </Button>
            </div>
          )}
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    user: state.AuthReducer.userData,
  };
};

export default connect(mapStateToProps)(Verification);
