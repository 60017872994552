import React, { Component } from "react";
import CustomInput from "../../Atom/CustomInput";
import CustomSelection from "../../Atom/CustomSelect";
import CustomButton from "../../Atom/CustomButton";
import { connect } from "react-redux";
import { DatePicker, Spin } from "antd";
import moment from "moment";
import Database from "../../../Firebase/DataFirebase";
import "./styles.scss";
import FacebookLogin from "react-facebook-login";

import { Row, Modal } from "react-bootstrap";
import ModalWrap from "../../Atom/Modal";

class UserPersonalDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      firstName: props.userData.first_Name,
      lastName: props.userData.last_Name,
      accountType: props.userData.accountType,
      dob: props.userData.DOB ? moment(props.userData.DOB, "DD/MM/YYYY") : null,
      gender: props.userData.gender ? props.userData.gender : 0,
      isNameChanged: false,
      isLastNameChanged: false,
      isDateChanged: false,
      isGenderChanged: false,
      isLoading: false,
    };
  }

  onDateChange = (date) => {
    this.setState({
      ...this.state,
      dob: date,
      isDateChanged: true,
    });
  };

  onUpdateClicked = (event) => {
    event.preventDefault();

    if (this.isValidate() === true) {
      let details = {
        first_Name: this.state.firstName,
        last_Name: this.state.lastName,
        DOB: this.state.dob.format("DD/MM/YYYY"),
        gender: this.state.gender,
      };
      this.toggleLoading();
      Database.updateUserProfile(this.props.userData.uid, details)
        .then((success) => {
          this.toggleLoading();
        })
        .catch((error) => {
          console.log(error);
          this.toggleLoading();
        });
    }
  };

  toggleLoading = () => {
    this.setState({
      ...this.state,
      isLoading: !this.state.isLoading,
    });
  };

  isValidate = () => {
    let { firstName, lastName, dob, gender } = this.state;
    if (
      firstName.length > 0 &&
      lastName.length > 0 &&
      dob &&
      gender !== undefined
    ) {
      return true;
    } else {
      this.setState({
        ...this.state,
        isNameChanged: true,
        isLastNameChanged: true,
        isDateChanged: true,
        isGenderChanged: true,
      });
      return false;
    }
  };

  responseFacebook = (response) => {
    if (response.accessToken && response.id) {
      let detail = {};
      if (response.name) {
        detail.first_Name = response.name;
      }
      if (
        response.picture &&
        response.picture.data &&
        response.picture.data.url
      ) {
        detail.avatar = response.picture.data.url;
      }
      detail.Facebook_id = response.id;
      this.toggleLoading();
      Database.updateUserProfile(this.props.userData.uid, detail)
        .then((success) => {
          this.toggleLoading();
        })
        .catch((error) => {
          this.toggleLoading();
        });
    }
  };

  onIndividual() {
    Database.updateUserProfile(this.props.userData.uid, { accountType: 0 });
  }

  onBusiness() {
    Database.updateUserProfile(this.props.userData.uid, { accountType: 1 });
  }

  render() {
    const profileArray = ["First Name", "Last Name", "Gender"];
    let { isNameChanged, isLastNameChanged, isDateChanged, isGenderChanged } =
      this.state;
    return (
      <div className={"user-Personal-Detail"}>
        <div className={"user-Personal-Detail-model"}>
          {this.props.userData.accountType === undefined ? (
            <ModalWrap>
              <Modal.Dialog>
                <Modal.Header className={"modal-head"}>
                  <Modal.Title>
                    <h6>Please select a profile type</h6>
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body className={"modal-body"}>
                  <Row>
                    <CustomButton
                      onClick={this.onIndividual.bind(this)}
                      className={"modal-btn"}
                    >
                      Individual
                    </CustomButton>
                  </Row>
                  <Row>
                    <CustomButton
                      onClick={this.onBusiness.bind(this)}
                      className={"modal-btn"}
                    >
                      Business
                    </CustomButton>
                  </Row>
                </Modal.Body>
              </Modal.Dialog>
            </ModalWrap>
          ) : null}
          <form
            className={"user-Personal-Detail-form"}
            onSubmit={this.onUpdateClicked}
          >
            {profileArray.map((element, index) => {
              let returnElement = null;
              if (index === 2) {
                returnElement = (
                  <div key={index} className={"user-Personal-Detail-bday"}>
                    <div className={"two-items form-itemContainer"}>
                      <CustomSelection
                        className={"user-Personal-Detail-item"}
                        options={["Male", "Female"]}
                        defaultValue={
                          this.state.gender === 0 ? "Male" : "Female"
                        }
                        onChange={(value) => {
                          this.setState({
                            ...this.state,
                            gender: value === "Male" ? 0 : 1,
                            isGenderChanged: true,
                          });
                        }}
                      />
                      <span
                        className={`error ${
                          !isGenderChanged
                            ? "hide"
                            : this.state.gender !== undefined
                            ? "hide"
                            : ""
                        }`}
                      >
                        Select gender
                      </span>
                    </div>
                    <div className={"two-items form-itemContainer"}>
                      <DatePicker
                        onChange={this.onDateChange}
                        className={"user-Personal-Detail-item"}
                        placeholder="Select month"
                        showToday={false}
                        value={this.state.dob ? this.state.dob : null}
                        format={"DD/MM/YYYY"}
                      />
                      <span
                        className={`error ${
                          !isDateChanged ? "hide" : this.state.dob ? "hide" : ""
                        }`}
                      >
                        Enter date of birth
                      </span>
                    </div>
                  </div>
                );
              } else {
                returnElement = (
                  <div className={"form-itemContainer"} key={index}>
                    <CustomInput
                      className={"user-Personal-Detail-item"}
                      placeholder={element}
                      value={
                        index === 0 ? this.state.firstName : this.state.lastName
                      }
                      onChange={(event) => {
                        if (index === 0) {
                          this.setState({
                            ...this.state,
                            isNameChanged: true,
                            firstName: event.target.value,
                          });
                        } else {
                          this.setState({
                            ...this.state,
                            isLastNameChanged: true,
                            lastName: event.target.value,
                          });
                        }
                      }}
                    />
                    {index === 0 ? (
                      <span
                        className={`error ${
                          !isNameChanged
                            ? "hide"
                            : this.state.firstName.length > 0
                            ? "hide"
                            : ""
                        }`}
                      >
                        Enter name
                      </span>
                    ) : (
                      <span
                        className={`error ${
                          !isLastNameChanged
                            ? "hide"
                            : this.state.lastName.length > 0
                            ? "hide"
                            : ""
                        }`}
                      >
                        Enter last name
                      </span>
                    )}
                  </div>
                );
              }
              return returnElement;
            })}
            {this.state.isLoading ? (
              <Spin />
            ) : (
              <CustomButton onClick={this.onUpdateClicked}>Save</CustomButton>
            )}

            <div className={"line-Styling"}>
              <span className={"line"}></span>
              <span className={"text"}>or connect with</span>
              <span className={"line"}></span>
            </div>
            {this.state.isLoading ? null : (
              <FacebookLogin
                appId="192592471662243"
                textButton="Facebook"
                autoLoad={false}
                fields="name,email,picture"
                callback={this.responseFacebook}
                cssClass={"facebook_Button"}
              />
            )}
          </form>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  userData: state.AuthReducer.userData,
});

export default connect(mapStateToProps)(UserPersonalDetail);
