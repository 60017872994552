import React, { PureComponent } from 'react';
import './style.scss';
// import SectionWrapper from '../../Placeholder/SectionWrapper';
import ContactForm from '../BusinessForm';
import CustomButton from '../../Atom/CustomButton'
// import { Icons, Images } from '../../../Shared/Assets';


export default class Business extends PureComponent {
    render() {
        return (
            <div className={'Business-Container'}>
                <div className={'app-info'}>
                <h2 className={'title'}>WeCycle.</h2>
                <p className={'tagline'}>Rent what you want. Lend what you don't</p>
                <div className={'splitter'}>
                    <div className={'form-wrapper-business'}>
                                <ContactForm />
                    </div>
                    {/* <div className={'or-text'}>
                        <div className={'line'}></div>
                        <div className={'text-or'}>or</div>
                        <div className={'line'}></div>
                                
                    </div> */}
                    {/* <div className={'submit-button-bu'}>
                                <CustomButton>Schedule a meeting</CustomButton>
                    </div> */}
                </div>
                </div>
            </div>
            
        );
    }
}