import React from 'react';
import './styles.scss';
import { Tabs, Menu } from 'antd';
import { FaUser, FaMoneyCheck, FaShippingFast, FaBackward } from 'react-icons/fa';
import { BiArrowBack } from 'react-icons/bi'
import CustomButton from '../../Atom/CustomButton';
import BillingForm from '../../Molecule/BillingForm';
import { connect } from 'react-redux';
import PersonalDetail from '../../Molecule/UserPersonalDetail'
import Verification from '../../Molecule/Verification'
import { message } from 'antd'
import { Redirect } from 'react-router-dom';
import { RouteConfig } from '../../../Config/RouteConfig';
import DeliveryStep from './Component/DeliveryStep'


class CheckoutSteps extends React.Component {

    constructor(props) {
        super(props);
        let steps = []
        if (this.props.user) {
            if (this.props.user.first_Name ? this.props.user.first_Name.length === 0 : true) {
                steps.push({ menuKey: 1, name: 'Customer Info', Icon: FaUser })
            }
            // if ( this.props.user.proofVerifiedStatus ? (this.props.user.proofVerifiedStatus === 0 || this.props.user.proofVerifiedStatus === 3) : true ){
            //     steps.push({ menuKey: 2, name: 'Verification', Icon: FaUser })
            // }  else if (this.props.user.proofVerifiedStatus === 1) {
            //     message.info('Documents are under review')
            // }
        }

        steps.push({ menuKey: 3, name: 'Delivery', Icon: FaShippingFast })
        steps.push({ menuKey: 4, name: 'Payment', Icon: FaMoneyCheck })


        this.state = {
            activeTab: steps[0].menuKey,
            steps: steps,
            selectedAddress: null,
            isPickUp: false,
            isCashOnDelivery: false,
        }
    }

    getSnapshotBeforeUpdate(prevProps) {
        return { notifyRequired: prevProps.user.proofVerifiedStatus !== this.props.user.proofVerifiedStatus || prevProps.user.first_Name !== this.props.user.first_Name };
    }

    //   componentDidMount() {
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (snapshot.notifyRequired) {
            let steps = []
                if (this.props.user) {
                    if (this.props.user.first_Name ? this.props.user.first_Name.length === 0 : true) {
                        steps.push({ menuKey: 1, name: 'Customer Info', Icon: FaUser })
                    }
                    if (this.props.user.proofVerifiedStatus ? (this.props.user.proofVerifiedStatus === 0 || this.props.user.proofVerifiedStatus === 3) : true) {
                        steps.push({ menuKey: 2, name: 'Verification', Icon: FaUser })
                    }
                }
                steps.push({ menuKey: 3, name: 'Delivery', Icon: FaMoneyCheck })
                steps.push({ menuKey: 4, name: 'Payment', Icon: FaMoneyCheck })
            this.setState({
                activeTab: steps[0].menuKey,
                steps: steps,
            })
        }

    }

    setActiveTab(activeTab) {

        this.setState({
            activeTab: this.state.steps[activeTab].menuKey
        });
    }

    getCurrentTab = (index) => {
        let tab = this.state.steps[index].name
        if (tab === 'Customer Info') {
            return <PersonalDetail />
        } else if (tab === 'Verification') {
            return <Verification />
        } else if (tab === 'Payment') {
            return <BillingForm
                cards={this.props.cards}
                showLoader={this.props.fethingCards}
                slectedCard={this.props.slectedCard}
                isCashOnDeliveryClicked={(isCashOnDelivery) => {
                    this.setState({ isCashOnDelivery })
                }}
                cardElement={this.props.cardElement}
                dateElement={this.props.dateElement}
                cvvElement={this.props.cvvElement}
                isCashOnDeliveryAvaibale={this.props.cartProducts.filter(item => item.isCashOnDelivery === false).length === 0}
                updateCard={index => {
                    this.props.updateSelectedCard(index)
                }}
                defaultCardChanged={this.props.defaultCardChanged}
                handleMenuClick={this.props.handleMenuClick}
                setForm={() => {
                    // this.props.onPaymentFormData();
                }}
            />
        } else if (tab === 'Delivery') {
            return <DeliveryStep
                user={this.props.user}
                pickUpSelected={() => {
                    this.setState({ isPickUp: true, selectedAddress: null })
                }}
                addressSelected={(selectedAddress) => {
                        this.setState({ selectedAddress, isPickUp: false })
                    
                }}
            />
        }
    }

    moveToPaymentScreen(index) {
        if (this.state.isPickUp === false && this.state.selectedAddress === null) {
            message.error('Please select shipping address')
        } else {
            this.setActiveTab(++index)
        }
    }

    render() {
        let { activeTab } = this.state;
        return (
            <div className={'checkout-steps'}>
                {this.props.user ? null : <Redirect to={RouteConfig.LANDING} />}
                <Tabs
                    activeKey={activeTab.toString()}
                    className={'step-tabs'}
                    renderTabBar={() => (
                        <Menu
                            mode={'horizontal'}
                            selectable={false}
                            className={'step-nav-menu'}
                            selectedKeys={[activeTab.toString()]}
                        >
                            {this.state.steps.map((Step, index) => (
                                <Menu.Item className={'nav-menu'} key={Step.menuKey}>
                                    <Step.Icon /> {Step.name}
                                </Menu.Item>
                            ))}
                        </Menu>
                    )}
                >
                    {this.state.steps.map((Step, index) => (
                        <Tabs.TabPane
                            key={this.state.steps[index].menuKey.toString()}
                        >
                            {
                                this.getCurrentTab(index)
                            }
                        </Tabs.TabPane>
                    ))}
                </Tabs>
                <div className={'nav-buttons mt-2'}>
                    <button
                        className={'back-button'}
                        onClick={() => {
                            if (activeTab === 4) {
                                this.setActiveTab(0)
                            }
                        }}
                    >


                        {
                            activeTab === 4 &&
                            <div>
                                <BiArrowBack />
                                Back To Shipping
                            </div>
                        }
                        {/* <FaArrowLeft className={'back-arrow'} /> */}
                        {/* {activeTab === 1 &&
                                'Main'
                            } */}

                        {/* {activeTab === 2 &&
                                'Customer Info'}
                            {activeTab === 3 &&
                                'Customer Shipping'} */}
                    </button>
                    <CustomButton
                        className={'continue-button mt-4'}
                        onClick={() => {
                            // if (activeTab <= this.state.steps.length) {

                            switch (activeTab) {
                                case 3:
                                    this.moveToPaymentScreen(0)
                                    break;
                                case 4:
                                    let data = {
                                        selectedAddress: this.state.selectedAddress,
                                        isPickUp: this.state.isPickUp,
                                        isCashOnDelivery: this.state.isCashOnDelivery,
                                    }
                                    this.props.addRequestOnFirebase(data)
                                    break;
                                default:
                                    break;
                            }
                            // }
                        }}
                    >
                        {activeTab === 1 &&
                            'Continue to Shipping'
                        }
                        {activeTab === 2 &&
                            'Continue to Delivery'
                        }
                        {activeTab === 3 &&
                            'Send Request to Payment'
                        }
                        {activeTab === 4 &&
                            'Send Request to lender'
                        }
                    </CustomButton>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    user: state.AuthReducer.userData,
    cartProducts: state.CartReducer.cartProducts,

});

export default connect(mapStateToProps)(CheckoutSteps);
