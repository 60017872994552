import React from 'react';
import undraw from '../../../Assets/Images/undraw_newsletter_vovu.png';
import CustomButton from '../../Atom/CustomButton';
import './styles.scss';
import RouterService from '../../../Services/RouterService';
import { RouteConfig } from '../../../Config/RouteConfig';
import { CATEGORIES } from '../../../Config';

const NoMsg = ({
    message,
}) => {
    
    return(
        <div className={'msg'}>
                <img src={undraw} alt='No-msg' className={'no-msg'}/>
                <h2>{message}</h2>
                <p className={'msg-default'}>You can find the product you are <br/>interested in by searching</p>
            <CustomButton onClick={((event) => {
                RouterService.pushRoute(`${RouteConfig.PRODUCTS}/${CATEGORIES.POPULAR}`);
            })}>
                Search
            </CustomButton>
        </div>
    );
    
}

export default NoMsg;
