import React, { PureComponent } from "react";
import "./styles.scss";
import CustomUploadButton from "react-firebase-file-uploader/lib/CustomUploadButton";
import Page from "../../Components/HOC/Page";
import { Icons } from "../../Shared/Assets";
import { Row, Col, Button } from "antd";
import DataFirebase from "../../Firebase/DataFirebase";
import PostForm from "../../Components/Cell/PostForm";
import { connect } from "react-redux";
import { postProduct, postRemove } from "../../Redux/Actions/ProductActions";
import { Redirect } from "react-router-dom";
import { RouteConfig } from "../../Config/RouteConfig";
import { Spin } from "antd";
import RouterService from "../../Services/RouterService";
import TagManager from "react-gtm-module";
import Background4 from ".././../Assets/Images/addimg.png";

class Post extends PureComponent {
  constructor(props) {
    super(props);
    if (this.props.post == null) {
      this.state = {
        images: [],
        placeholderImages: ["1", "2", "3", "4", "5", "6"],
        isProductAdded: false,
        isLoading: false,
        productDetails: null,
      };
    } else {
      this.state = {
        images: this.props.post.images,
        placeholderImages: ["1", "2", "3", "4", "5", "6"],
        isProductAdded: false,
        isLoading: false,
        productDetails: null,
      };
    }
  }

  async componentDidMount() {
    let { id } = this.props.match.params;
    if (id) {
      this.updateform(id);
    }
    DataFirebase.getConditions();
    DataFirebase.getProductCategory();
  }

  async updateform(id) {
    try {
      this.setState({
        ...this.state,
        isLoading: true,
      });
      const product = await DataFirebase.getProduct(id);
      this.setState({
        ...this.state,
        isLoading: false,
      });
      this.setState({
        ...this.state,
        images: product.images.map((image) => image.downloadURL),
        productDetails: {
          id: id,
          addressString: product.address.add,
          categoryId: product.categoryId,
          conditionId: product.conditionId,
          name: product.name,
          hourlyRate: product.hourlyRate,
          weeklyRate: product.weeklyRate,
          desc: product.desc,
          address: product.address,
          isArchived: product.isArchived,
        },
      });
    } catch (error) {
      this.setState({
        ...this.state,
        isLoading: false,
      });
    }
  }

  imageHandler = (index, event) => {
    try {
      if (this.state.images.length > index) {
        //  Replace Image
        var array = [...this.state.images];
        array[index] = URL.createObjectURL(event.target.files[0]);
        this.setState({
          ...this.state,
          images: array,
        });
      } else {
        this.setState({
          ...this.state,
          images: this.state.images.concat(
            URL.createObjectURL(event.target.files[0])
          ),
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  deleteImage = (index) => {
    if (this.state.images.length > index) {
      var array = [...this.state.images];
      array.splice(index, 1);
      this.setState({
        ...this.state,
        images: array,
      });
    }
  };

  archivePost = () => {
    this.setState({
      ...this.state,
      isLoading: true,
    });
    console.log(this.state);
    const id = this.state.productDetails.id;
    const status = this.state.productDetails.isArchived;
    DataFirebase.firebaseArchiveProduct(id, !status)
      .then(() => {
        this.setState({
          ...this.state,
          isLoading: false,
          productDetails: {
            ...this.state.productDetails,
            isArchived: !status,
          },
        });
      })
      .catch(() => {
        this.setState({
          ...this.state,
          isLoading: false,
        });
      });
  };

  previewClicked = (props) => {
    let imagesUpload = null;
    imagesUpload = this.state.images;
    if (!props.address.latitude && !props.address.longitude) {
    } else {
      let postData = {
        images: imagesUpload,
        id: props.id,
        name: props.name,
        name_lowercased: props.name.toLowerCase(),
        hourlyRate: props.hourlyRate,
        weeklyRate: props.weeklyRate,
        categoryId: props.categoryId,
        conditionId: props.conditionId,
        desc: props.desc,
        description_lowecased: props.desc.toLowerCase(),
        address: props.address,
        isArchived: false,
        userId: this.props.user.uid,
        condition: props.condition,
      };
      this.props.onAddProduct(postData);
      RouterService.pushRoute({ pathname: `${RouteConfig.PRODUCT}/preview` });
    }
  };

  postClicked = (props) => {
    let imagesUpload = null;
    imagesUpload = this.state.images;
    if (!props.address.latitude && !props.address.longitude === false) {
    } else {
      let postData = {
        images: imagesUpload,
        id: props.id,
        name: props.name,
        name_lowercased: props.name.toLowerCase(),
        hourlyRate: props.hourlyRate,
        weeklyRate: props.weeklyRate,
        categoryId: props.categoryId,
        conditionId: props.conditionId,
        desc: props.desc,
        description_lowecased: props.desc.toLowerCase(),
        address: props.address,
        isArchived: false,
        userId: this.props.user.uid,
        condition: props.condition,
        isDeliveryAvailable: true,
        isApproved: 1,
      };
      this.props.onAddProduct(postData);
      this.setState({
        isLoading: true,
      });
      if (this.state.productDetails) {
        DataFirebase.firebaseUpdateProduct(postData)
          .then(() => {
            this.setState({
              ...this.state,
              isProductAdded: true,
            });
            this.props.onPostRemove();
          })
          .catch(() => {
            this.setState({
              ...this.state,
              isLoading: false,
            });
          });
      } else {
        DataFirebase.firebaseAddProduct(postData)
          .then(() => {
            this.setState({
              ...this.state,
              isLoading: false,
              isProductAdded: true,
            });
            this.props.onPostRemove();
          })
          .catch(() => {
            this.setState({
              ...this.state,
              isLoading: false,
            });
          });
      }
    }
  };

  render() {
    const toEdit = this.props.match?.params?.id;
    let tagManagerArgs = {
      dataLayer: {
        page: "Landing",
      },
      dataLayerName: "PageDataLayer",
    };

    TagManager.dataLayer(tagManagerArgs);

    let imageComponent = null;
    imageComponent = this.state.placeholderImages.map((element, index) => {
      let image = null;
      if (this.state.images.length > index) {
        image = this.state.images[index];
      }

      return (
        <div className={"main-img-div"} key={index}>
          <div className={"wrap-img-cover"}>
            {image ? (
              <div className={"del-img"}>
                <img
                  src={Icons.delImg}
                  onClick={() => {
                    this.deleteImage(index);
                  }}
                  alt={"delete"}
                />
              </div>
            ) : null}
            <CustomUploadButton
              accept="image/*"
              className={"upload-button"}
              style={
                image
                  ? { display: "block" }
                  : { backgroundImage: `url(${Background4})` }
              }
              onChange={(event) => {
                this.imageHandler(index, event);
              }}
            >
              {image ? (
                <img src={image} className={"add-img"} alt={"upload"} />
              ) : null}
            </CustomUploadButton>
          </div>
        </div>
      );
    });

    let redirectUrl = null;
    if (!this.props.user) {
      redirectUrl = <Redirect to={RouteConfig.LANDING} />;
    }
    if (this.state.isProductAdded) {
      redirectUrl = <Redirect to={RouteConfig.LANDING} />;
    }
    const isArchived = this.state?.productDetails?.isArchived;
    return (
      <div className={"newPost"}>
        <Page noSection noFooter hidePost={false} className={"newPost-pg"}>
          {redirectUrl}
          {!this.state.isLoading ? (
            <div className={"new-section"}>
              <div className={"Head-section"}>
                <h1>{toEdit ? "Update Post" : "New Post"}</h1>
                {toEdit && (
                  <Button
                    type={isArchived ? "primary" : "danger"}
                    onClick={this.archivePost}
                  >
                    {isArchived ? "Unarchive" : "Archive"}
                  </Button>
                )}
              </div>
              <Row className={"top-main-section"}>
                <Col md={6} lg={5} className={"top-left"}>
                  <h2>Adding new photo</h2>
                  <p>
                    Ads with photos receive an average of 3-5 times more
                    responses
                  </p>
                </Col>
                <Col md={6} lg={12} className={"top-right"}>
                  {imageComponent}
                </Col>
              </Row>
              {redirectUrl ? (
                redirectUrl
              ) : (
                <PostForm
                  userData={this.props.user}
                  productDetails={this.state.productDetails}
                  onSelect={(props) => {
                    this.postClicked(props);
                  }}
                  onPreview={(props) => {
                    this.previewClicked(props);
                  }}
                  images={this.state.imagesUpload}
                />
              )}
            </div>
          ) : (
            <Spin className={"spinner"} />
          )}
        </Page>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.AuthReducer.userData,
    post: state.ProductReducer.postProduct,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onAddProduct: (product) => dispatch(postProduct(product)),
    onPostRemove: () => dispatch(postRemove()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Post);
