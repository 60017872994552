import React, { Component } from 'react';
import './styles.scss';

import { Icon } from 'antd';

export default class OnlineStatus extends Component {
    constructor(props) {
        super(props);
        this.state = {
            online: navigator.onLine,
            visible: false
        }

        window.addEventListener('online', this.setOnlineStatus.bind(this));
        window.addEventListener('offline', this.setOnlineStatus.bind(this));
    }

    setOnlineStatus() {
        this.setState({
            online: navigator.onLine,
            visible: true,
        }, () => {
            if (this.state.online) {
                this.timeout = setTimeout(() => {
                    this.setState({ visible: false });
                }, 2000);
            }
        });
    }

    componentWillUnmount() {
        clearTimeout(this.timeout);
        window.removeEventListener('online', this.setOnlineStatus.bind(this));
        window.removeEventListener('offline', this.setOnlineStatus.bind(this));
    }

    render() {
        let { visible, online } = this.state;

        if (!visible) return null;

        return (
            <div className={`online-status-bar ${online ? 'online' : 'offline'}`}>
                <Icon type={online ? 'smile' : 'frown'} />
                <span className={'status-message'}>You are {online ? 'online' : 'offline. Please check your internet connection'}.</span>
            </div>
        );
    }
}
