import React, { PureComponent } from 'react';
import './styles.scss';

import SectionWrapper from '../../SectionWrapper';
import ContactForm from '../../ContactForm';
import { Icons, Images } from '../../../../Shared/Assets';
import { sections } from '../../../../Config/Placeholder';

export default class Home extends PureComponent {
    render() {
        return (
            <SectionWrapper home={true} id={'home'}>
                <div className={'home-container'} style={{ backgroundImage: `url(${Images.home})` }}>
                    <div className={'app-info'}>
                        <h2 className={'title'}>WeCycle.</h2>
                        <p className={'tagline'}>Rent what you want. Lend what you don't</p>
                        <div className={'form-wrapper'}>
                            <ContactForm />
                        </div>
                    </div>
                    <a href={`#${sections.about.id}`} className={'float-button'}>
                        <img alt={'explore'} src={Icons.floatLink} />
                    </a>
                </div>
            </SectionWrapper>
        );
    }
}
