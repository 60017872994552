import React, { Component } from "react";
import "./styles.scss";
import ExifOrientationImg from "react-exif-orientation-img";
import { FiEdit } from "react-icons/fi";
import DataFirebase from "../../../Firebase/DataFirebase";
import Spinner from "../../Atom/Loader/Circle";

class userBanner extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
    };
  }

  toggleLoading = () => {
    this.setState({
      ...this.state,
      isLoading: !this.state.isLoading,
    });
  };

  async upload(e) {
    console.log(this);
    try {
      this.toggleLoading();
      const image = e.target.files[0];
      const url = await DataFirebase.generateImageUrl(image, "user_avatars");
      let details = {
        avatar: url,
      };
      await DataFirebase.updateUserProfile(this.props.userData.uid, details);
      this.props.userData.avatar = url;
      this.toggleLoading();
    } catch (error) {
      this.toggleLoading();
    }
  }
  render() {
    return (
      <div className={"user_profile_Banner"}>
        <div className={"user_profile_Banner_avatar"}>
          <label htmlFor="input">
            <div className={"user_profile_Banner_avatar_body"}>
              <div className="user_profile_Banner_avatar_icon">
                {this.state.isLoading ? <Spinner small={true} /> : <FiEdit size={13} />}
              </div>
              <ExifOrientationImg
                className={"user_profile_Banner_avatar_image"}
                src={this.props.userData.avatar}
              />
            </div>
          </label>
          <input
            id="input"
            onChange={(e) => this.upload(e)}
            className="d-none"
            accept="image/*"
            type="file"
          />
        </div>
        <p>
          {this.props.userData.first_Name
            ? `${this.props.userData.first_Name} ${this.props.userData.last_Name}`
            : this.props.userData.name}
        </p>
      </div>
    );
  }
}

export default userBanner;
