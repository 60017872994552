import React, {Component} from 'react';
import './style.scss';
import Page from '../../Components/HOC/Page';
import Business from '../../Components/Molecule/BusinessContact'
import Footer from '../../Components/Molecule/Footer';
import TagManager from 'react-gtm-module'


export default class BusinessContact extends Component {
    
    render() {
        let tagManagerArgs = {
            dataLayer: {
               page: 'Landing'
              }, 
              dataLayerName: 'PageDataLayer'
           } 
        
           TagManager.dataLayer(tagManagerArgs)
        return (
                <Page
                    noSection
                    className={'contact-pg'}> 
                    <Business/>
                </Page>
            
        )
    }
}