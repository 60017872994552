import React, { Component } from 'react';
import './styles.scss';
import { connect } from 'react-redux';
import Page from '../../Components/HOC/Page';
import { Spin, Row, Col } from 'antd';
import CheckoutCart from '../../Components/Cell/CheckoutCart';
import CheckoutSteps from '../../Components/Cell/CheckoutSteps';
import { injectStripe } from 'react-stripe-elements';
import APIManager from '../../APIManager';
import { Redirect } from 'react-router-dom';
import { RouteConfig } from '../../Config/RouteConfig';
import TagManager from 'react-gtm-module'
import Database from '../../Firebase/DataFirebase'
import { message } from 'antd';

class Checkout extends Component {
    state = {
        cards: [],
        fethingCards: true,
        slectedCard: 0,
        cardElement: null,
        dateElement: null,
        cvvElement: null,
        isRedirect: false
    }

    componentDidMount() {
        if (this.props.user) {
            this.getAllCards()
        }
    }

    getAllCards() {
        let params = { "customer": this.props.user.customer_id }
        this.setState({
            fethingCards: true
        })
        APIManager.getAllCards(params)
            .then((response) => {
                let data = response.data;
                if (data.success) {
                    let card = data.cards || {}
                    let cards = card.data || []
                    this.setState({
                        cards,
                        fethingCards: false
                    })
                }
            })
            .catch(() => {
                this.setState({
                    fethingCards: false
                })
                console.log('All cards error')
            })
    }

    toggleloading() {
        this.setState({
            fethingCards: !this.state.fethingCards
        })
    }

    onPaymentFormData() {
        if (this.props.stripe) {
            this.toggleloading()
            this.props.stripe
                .createToken()
                .then(payload => {
                    if (payload.token) {
                        let param = {
                            'token': payload.token.id,
                            'customer': this.props.user.customer_id
                        }
                        this.addCardToFirebase(param)
                    } else {
                        this.toggleloading()
                        if (payload.error) {
                            message.error(payload.error.message)
                        } else {
                            message.error('unable to add card')
                        }
                    }
                })
        } else {
            this.toggleloading()
        }
    }

    handleMenuClick(event) {
        let param = {
            'card': this.state.cards[event.key].id,
            'customer': this.props.user.customer_id
        }
        APIManager.deleteCard(param)
            .then(response => {
                if (response.data.success) {
                    this.getAllCards()
                }
            })
            .catch(error => {
                console.log(error);
                this.toggleloading()
            })
    }

    addCardToFirebase(params) {
        console.log(params)
        APIManager.addCard(params)
            .then((response) => {
                if (response.data.success) {
                    this.state.cardElement.clear()
                    this.state.dateElement.clear()
                    this.state.cvvElement.clear()
                    this.getAllCards()
                } else {
                    this.toggleloading()
                }
            })
            .catch((error) => {
                console.log('error', error)
                this.toggleloading()
            })
    }

    addRequestOnFirebase(data) {
        // console.log(data);
        // console.log(this.state.cards[this.state.slectedCard])


        if (!this.props.user.customer_id) {
            message.error('Payment gateway error')
        } else if (this.state.cards.length === 0 && data.isCashOnDelivery === false) {
            message.error('Sorry, No card found!')
        }
        else if (this.props.cartProducts.length === 0) {
            message.error('Sorry, No product found!')
        }
        else {
            let cardDetail = {}
            if (data.isCashOnDelivery === false) {
                let card = this.state.cards[this.state.slectedCard]
                cardDetail = {
                    id: card.id,
                    brand: card.brand,
                    expYear: card.exp_year,
                    expMonth: card.exp_month,
                    last4: card.last4
                }
            }
            this.props.cartProducts.map((product) => {
                let detail = {
                    cardDetail: cardDetail,
                    senderID: this.props.user.uid,
                    receiverID: product.receiverID,
                    userName: product.userName,
                    message: product.messageTitle,
                    messageTitle: product.message,
                    isRead: false,
                    finalPrice: product.finalPrice,
                    notificationType: 1,
                    timeStamp: 0,
                    time: { ".sv": "timestamp" },
                    productId: product.productId,
                    startDate: product.startDate || '',
                    endDate: product.endDate || '',
                    totalDays: product.totalDays ,
                    startTime: product.startTime || '',
                    endTime: product.endTime || '',
                    isAvailable: true,
                    isPickUp: data.isPickUp,
                    isCashOnDelivery: data.isCashOnDelivery,
                    deliveryAddress: data.selectedAddress,

                }

                if (product.startTimeStamp) {
                    detail.startTimeStamp = product.startTimeStamp
                }

                if (product.endTimeStamp) {
                    detail.endTimeStamp = product.endTimeStamp
                }
                Database.sendRequestToSender(detail)
                    .then(response => {
                        Database.cartClearAllProduct(this.props.user.uid)
                            .then(respionse => {
                                this.setState({
                                    isRedirect: true
                                })
                            })
                            .catch(error => {

                            })
                    })
                    .catch(error => {
                        console.log(error)
                    })

            })
        }
    }

    updateSelectedCard() {
        this.onPaymentFormData()
    }

    defaultCardChanged(index) {
        let card = this.state.cards[index]
        let param = {
            "customer": this.props.user.customer_id,
            "cardID": card.id
        }
        APIManager.updateDefaultCard(param)
            .then((response) => {
                this.setState({
                    slectedCard: index
                })
            })
            .catch((error) => {

            })
    }

    render() {
        let tagManagerArgs = {
            dataLayer: {
                page: 'Landing'
            },
            dataLayerName: 'PageDataLayer'
        }

        TagManager.dataLayer(tagManagerArgs)


        let products = this.props.cartProducts;
        return (
            <Page
                noVerticalPadding
                noSection
                noFooter
                className={'cart-checkout'}
                containerClassName={'cart-checkout-container'}
            >
                {this.state.isRedirect ? <Redirect to={RouteConfig.LANDING} /> : null}
                {this.props.user ? null : <Redirect to={RouteConfig.LANDING} />}
                <Spin style={{ width: '100%' }} spinning={false}>
                    <Row>
                        <Col className={'details-steps'} sm={{ span: 24, pull: 0 }} md={{ span: 8, push: 16 }}>
                            <CheckoutCart
                                products={products}
                            />
                        </Col>
                        <Col className={'cart-details'} sm={{ span: 24, pull: 0 }} md={{ span: 16, pull: 8 }} >
                            <CheckoutSteps
                                fethingCards={this.state.fethingCards}
                                cards={this.state.cards}
                                products={products}
                                slectedCard={this.state.slectedCard}
                                onPaymentFormData={this.onPaymentFormData.bind(this)}
                                updateSelectedCard={this.updateSelectedCard.bind(this)}
                                defaultCardChanged={this.defaultCardChanged.bind(this)}
                                handleMenuClick={this.handleMenuClick.bind(this)}
                                addRequestOnFirebase={this.addRequestOnFirebase.bind(this)}

                                cardElement={(ref) => {
                                    this.setState({
                                        cardElement: ref
                                    })
                                }}
                                dateElement={(ref) => {
                                    this.setState({
                                        dateElement: ref
                                    })
                                }}
                                cvvElement={(ref) => {
                                    this.setState({
                                        cvvElement: ref
                                    })
                                }}
                            />
                        </Col>
                    </Row>
                </Spin>
            </Page>
        )
    }
}

const mapStateToProps = state => ({
    cartProducts: state.CartReducer.cartProducts,
    user: state.AuthReducer.userData
});

export default connect(mapStateToProps)(injectStripe(Checkout));
