import React from "react";
import "./styles.scss";

const CustomButton = ({
  className = "",
  onClick = () => {},
  outline = false,
  outlineColor,
  backgroundColor,
  children,
  disabled,
}) => {
  // let styles;
  //     if (outlineColor) {
  //         styles['borderColor'] = outlineColor
  //     }
  //     if (backgroundColor) {
  //         styles['backgroundColor'] = backgroundColor
  //     }
  return (
    <button
      className={`custom-button ${className} ${outline ? "outline" : ""}`}
      onClick={onClick}
      disabled={disabled}
      style={{
        border: outlineColor,
        backgroundColor: backgroundColor,
      }}
    >
      {children}
    </button>
  );
};

export default CustomButton;
