import React, { Component } from "react";
import "./styles.scss";
import { Dropdown } from "react-bootstrap";
import PropTypes from "prop-types";
import menuDots from "../../../Assets/Icons/menuDots.png";
import { Row, Col } from "antd";
import { sliceText } from "../../../utils/textConverter";

let filters = [];

class Message extends Component {
  getNotificationMessage = (notificationType, userName) => {
    switch (notificationType) {
      case 1:
        return `${userName} has sent a request to you`;
      case 2:
        return `Your request accepted by ${userName}`;
      default:
        return ``;
    }
  };
  render() {
    let { className, list, isMessage } = this.props;
    let listView = null;
    var sortedList = [];
    if (isMessage) {
      let unreadMessage = list.filter((message) => {
        return message.unreadCount > 0;
      });
      let readMessage = list.filter((message) => {
        return message.unreadCount === 0;
      });
      sortedList = unreadMessage.concat(readMessage);
      filters = [
        `All messages (${sortedList.length})`,
        `Unread (${unreadMessage.length})`,
      ];
    } else {
      let unreadMessage = list.filter((message) => {
        return !message.isRead;
      });
      let readMessage = list.filter((message) => {
        return message.isRead;
      });
      sortedList = unreadMessage.concat(readMessage);
      filters = [
        `All notifications (${sortedList.length})`,
        `Unread (${unreadMessage.length})`,
      ];
    }

    listView = sortedList.map((message) => {
      // let image = message.user.avatar ? message.user.avatar : picholder
      return (
        <div className={"msg-row"} key={message.id}>
          <div className={"col-left"}>
            <div className={"profile"}>
              {isMessage ? (
                message.unreadCount > 0 ? (
                  <span className={"chat_badge"}>{message.unreadCount}</span>
                ) : null
              ) : message.isRead ? null : (
                <span className={"chat_badge"}> </span>
              )}
              {message.user.avatar ? (
                <img
                  src={message.user.avatar}
                  alt="profole-pic"
                  className={"profile-pic"}
                />
              ) : (
                <h1 className={"profile-pic-text"}>
                  {(message.user.first_Name
                    ? message.user.first_Name
                    : message.user.name
                  ).charAt(0)}
                </h1>
              )}
            </div>
            <p
              className={"name"}
              onClick={() => this.props.messageClick(message)}
            >
              {message.user.first_Name
                ? message.user.first_Name + " " + message.user.last_Name
                : message.user.name}
            </p>
          </div>
          <div className={"col-middle"}>
            <p
              className={"inbox-msg"}
              onClick={() => this.props.messageClick(message)}
            >
              {isMessage
                ? sliceText(message.lastMessage)
                : this.getNotificationMessage(
                    message.notificationType,
                    message.user.first_Name
                      ? message.user.first_Name
                      : message.user.name
                  )}
            </p>
            <p
              className={"product"}
              onClick={() => this.props.messageClick(message)}
            >
              {sliceText(message.name)}
            </p>
          </div>
          {/* <div className={"col-right"}>
            {isMessage ? (
              <div className={"status"}>
                <p
                  className={"status-msg"}
                  onClick={() => this.props.messageClick(message)}
                >
                  Status
                </p>
                {!!message.product && (
                  <p
                    className={"price"}
                    onClick={() => this.props.messageClick(message)}
                  >
                    {`$${message.product.hourlyRate}/${message.product.weeklyRate}`}
                  </p>
                )}
              </div>
            ) : null}
          </div> */}
          {isMessage ? (
            <div>
              <Dropdown>
                <Dropdown.Toggle />
                <Dropdown.Menu>
                  <Dropdown.Item>Delete</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          ) : null}
        </div>
      );
    });
    return (
      <div className={"messages"}>
        <div className={`filter-bar ${className}`}>
          {filters.map((filter, index) => {
            return (
              <span key={index} className={"filter-item"}>
                {filter}
              </span>
            );
          })}
        </div>
        {listView}
      </div>
    );
  }
}

Message.defaultProps = {
  className: "",
};

Message.propTypes = {
  className: PropTypes.string,
};

export default Message;
