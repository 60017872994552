import { ChatActionTypes } from '../Actions/ChatAction';

const defaultChattate = {
    list: [],
    chat: [],
    notifications : [],
}

const ChatReducer = (state = { ...defaultChattate }, action) => {
    switch (action.type) {
        case ChatActionTypes.CHAT_LIST:
        let localMessage = [...state.list];
        let messages = localMessage.filter((list)=> {
            return list.id !==  action.chatList.id
        });
        messages.push(action.chatList);
        let sortedMessages = [...messages]
        sortedMessages.sort((a,b) => a.lastMessageTime - b.lastMessageTime)
        sortedMessages.reverse()
            return {
                ...state,
                list: sortedMessages
            };

        case ChatActionTypes.CHAT_MESSAGE:
        let message = [...state.chat];
        message.push(action.chat);
            return {
                ...state,
                chat: message
            };

        case ChatActionTypes.NOTIFICATION_LIST:
        let array = [...action.notificationList]
        return {
            ...state,
            notifications : array
        }

        case ChatActionTypes.REMOVE_CHAT_MESSAGE: 
        return {
            ...state,
            chat: []
        };

        case ChatActionTypes.REMOVE_NOTIFICATION_LIST: 
        return {
            ...state,
            notifications: []
        };
        

        default:
            return { ...state };
    }
}

export default ChatReducer;