import React, { PureComponent } from 'react';
import './styles.scss';

import { connect } from 'react-redux';
import AuthFirebase from '../../../Firebase/AuthFirebase';
import Spinner from '../../../Components/Atom/Spinner';
import CustomButton from '../../../Components/Atom/CustomButton';
import CustomInput from '../../../Components/Atom/CustomInput';
import CustomAlert from '../../../Components/Atom/CustomAlert';
import SocialButtonGroup from '../../../Components/Molecule/SocialButtonGroup';

import { Form } from 'antd';
import Rules from '../../../Services/Rules';
import { showAuthModal } from '../../../Redux/Actions/AuthActions';
import { ValidationMessages } from '../../../Shared/Messages';
import RouterService from '../../../Services/RouterService';
import { RouteConfig } from '../../../Config/RouteConfig';

class Signup extends PureComponent {
    constructor(props) {
        super(props)
        this.state = {
            errorMessage: '',
            signingUp: false
        }

        this.checkSignIn();
    }

    componentDidUpdate() {
        this.checkSignIn();
    }

    checkSignIn() {
        if (this.props.isLoggedIn) {
            this.props.showAuthModal(false);
        }
    }

    onSignUp(event) {
        event.preventDefault();
        let { validateFields } = this.props.form;
        validateFields((error, values) => {
            if (!error) {
                window.scrollTo({ top: 0 });
                this.setState({
                    signingUp: true
                });
                let { fullName, email, password } = values;
                AuthFirebase.signUpWithEmail(fullName.toLowerCase(), email, password)
                    .then(() => {
                        this.setState({
                            signingUp: false
                        });
                    })
                    .catch(({ message }) => {
                        this.setState({
                            signingUp: false,
                            errorMessage: message
                        });
                    });
            }
        });
    }

    termsaOfUse(event) {
        event.preventDefault();
        RouterService.pushRoute(`${RouteConfig.TERMS_OF_SERVICES}`);
        this.props.showAuthModal(false);
    }

    privacyPolicy(event) {
        event.preventDefault();
        RouterService.pushRoute(`${RouteConfig.PRIVACY_POLICY}`);
        this.props.showAuthModal(false);
    }

    render() {
        let { getFieldValue, getFieldDecorator } = this.props.form;
        return (
            <div className={'signup-page'}>
                <h4>Welcome to WeCycle</h4>
                <p>Get started absolutely free.</p>
                <Form
                    onSubmit={this.onSignUp.bind(this)}
                >
                    <CustomAlert
                        message={this.state.errorMessage}
                    />
                    <Form.Item>
                        {getFieldDecorator('fullName', {
                            rules: Rules.getNameRules()
                        })(
                            <CustomInput
                                placeholder={'Enter full name'}
                            />
                        )}
                    </Form.Item>
                    <Form.Item>
                        {getFieldDecorator('email', {
                            rules: Rules.getEmailRules()
                        })(
                            <CustomInput
                                placeholder={'Enter email'}
                            />
                        )}
                    </Form.Item>
                    <Form.Item
                        help={ValidationMessages.PASSWORD_INFO}
                    >
                        {getFieldDecorator('password', {
                            rules: Rules.getPasswordRules()
                        })(
                            <CustomInput
                                type={'password'}
                                placeholder={'Enter password'}
                            />
                        )}
                    </Form.Item>
                    <Form.Item>
                        {getFieldDecorator('confirmPassword', {
                            validateFirst: true,
                            rules: Rules.getConfirmPasswordRules(getFieldValue('password'))
                        })(
                            <CustomInput
                                type={'password'}
                                placeholder={'Re-enter password'}
                            />
                        )}
                    </Form.Item>

                <h6 className={'terms'}>By Register or Sign in, you agre to Wecycle's <span onClick={this.termsaOfUse.bind(this)} className={'link-css'}> Terms of Use</span> and 
                 <span onClick={this.privacyPolicy.bind(this)} className={'link-css'}> Privacy Policy</span></h6>
                 
                    <Form.Item className={'signup-button-wrapper'}>
                        {this.state.signingUp ?
                            <Spinner />
                            :
                            <CustomButton className={'signup-button'} onClick={this.onSignUp.bind(this)}>
                                Signup
                        </CustomButton>
                        }
                    </Form.Item>
                </Form>
                <SocialButtonGroup />

            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        isLoggedIn: state.AuthReducer.isLoggedIn
    };
}

export default connect(mapStateToProps, { showAuthModal })(Form.create()(Signup));
