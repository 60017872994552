import React from 'react';
import './styles.scss';

import Page from '../../Components/HOC/Page';

const TermsOfServices = () => {
    return (
        <Page
            noSection
            className={'terms-page'}
            containerClassName={'terms-page-container'}
        >
            <h2 className={'title'}>
                Terms of Service
            </h2>

            <h6 className={'instructions'}>
                PLEASE READ THIS AGREEMENT CAREFULLY. IT CONTAINS IMPORTANT INFORMATION ABOUT YOUR RIGHTS AND OBLIGATIONS.
            </h6>
            <p>
                WeCycle (a Tanbel Inc. project) provides a platform for people to share their items with each other for the purpose of saving money, reducing waste and meeting neighbors. However we only facilitate among the users and do not take any responsibility for the items involved.
            </p>
            <p>
                In order to take part in the WeCycle (a Tanbel Inc. project) community you must first agree to the following terms and conditions please read them carefully and contact us if you have any questions.
            </p>
            <p>
                We may revise and update this Agreement from time to time. If you are a registered user we will always inform you fully about any changes we make.
            </p>

            <h4>
                REGISTRATION
            </h4>
            <p>
                Eligibility. Registered users of WeCycle (a Tanbel Inc. project) must be of legal age in their home-country and shall in any case not be younger than eighteen (18) years old. By registering with WeCycle (a Tanbel Inc. project), you affirm that you are 18 years of age or older. You agree to provide accurate, current, and complete information during the registration process, including your real name and contact information this should be the case regardless of the fact that you choose to officially verify your address and bank details. We reserve the right to refuse registration for any reason at our sole discretion.
            </p>
            <p>
                Online Account Security: Upon registration, you will select a unique username and password. You are responsible for keeping that Username and Password private. You are further responsible for any and all activity on your account. You agree to notify us in writing immediately if you suspect any unauthorized use of or access to your account. WeCycle (a Tanbel Inc. project) will under no circumstances be liable for any loss or damage to you arising from unauthorized access to or use of your account caused by your failure to comply with this provision.
            </p>

            <h4>
                CONDITIONS OF USE
            </h4>
            <p>
                Conditioned on your compliance with this Agreement, we grant to you a personal, limited, nonexclusive, nontransferable license to take part in the WeCycle (a Tanbel Inc. project) community.
            </p>
            <p>
                Personal Conduct. As a condition of this license to participate in the WeCycle (a Tanbel Inc. project) community you agree that you will under no circumstances violate any applicable law, regulation, or term of this Agreement
            </p>
            <p>
                Content. You are solely responsible for the information and/or materials that you publish, display or otherwise communicate in any manner posted on or sent through WeCycle (a Tanbel Inc. project). This includes any kind of content that might offend other users or involves illegal actions or products.
            </p>
            <p>
                You acknowledge and agree that we may (but have no obligation to) review and revise or delete any User Content, message, or other material posted to or sent through WeCycle (a Tanbel Inc. project) that we in our sole discretion determine is in violation of this Agreement, or might be offensive or illegal, or might harm, threaten the safety of, or violate the rights of other Users, persons or entities. We further reserve the right to refuse use of WeCycle (a Tanbel Inc. project) or any other service to anyone, for any reason. However, you remain solely responsible for User Content posted or otherwise communicated by you, and the goods and services exchanged by you, through the usage of WeCycle (a Tanbel Inc. project).
            </p>
            <p>
                Accuracy of Information. You may under no circumstances provide inaccurate, misleading or false information to or through WeCycle (a Tanbel Inc. project). You agree to promptly notify us if information provided to or through WeCycle (a Tanbel Inc. project) or is discovered to be inaccurate, misleading or false.
            </p>
            <p>
                Insurance.I You are solely responsible for any loss or damage suffered as a result of lending, leasing, distributing, borrowing, renting, selling, or otherwise obtaining and/or using, any good or service via WeCycle (a Tanbel Inc. project) You are responsible for obtaining and maintaining any insurance, which you deem necessary or advisable to cover any loss or damage to such good and/or incurred by anyone in connection with the use or provision of the good or service. To the extent that you maintain such insurance, you are solely responsible for ensuring that your policy covers the intended exchange of the good or service.
            </p>
            <p>
                Links to Third Party Sites. WeCycle (a Tanbel Inc. project) may contain hyperlinks to websites that are not controlled by us, and which may have their own terms of service and privacy policies. WeCycle (a Tanbel Inc. project) is not responsible for, and does not endorse or accept any responsibility for, the contents of or your use of such websites.
            </p>

            <h4>
                Purpose of WeCycle (a Tanbel Inc. project)
            </h4>
            <h4>
                What We Do
            </h4>
            <p>
                WeCycle (a Tanbel Inc. project) provides a platform that facilitates the sharing of items among our users.
            </p>

            <h4>
                What We Don’t Do
            </h4>
            <p>
                We do not actively participate in any transactions, and do not verify or guarantee the truth or accuracy of any information posted by any User with regard to the existence, availability, authenticity, quality, or value of any item offered through WeCycle (a Tanbel Inc. project).
            </p>
            <p>
                We do not and cannot guarantee that transactions entered into between Users will be completed.
            </p>
            <p>
                You agree that you are solely and exclusively responsible for, must evaluate and make your own judgment about, and bear all risks associated with (i) your interactions with other members and/or Users, whether online or in person, and (ii) your use of any products or services made available by Users.
            </p>
            <p>
                You acknowledge that we do not screen or investigate the background of any Users, nor do we verify or otherwise warrant the accuracy of any representations or statements made by any User to you or others regarding products or services. Your dealings with Users, and any other terms, conditions, representations or warranties associated with such dealings, are between you and such User exclusively and do not involve WeCycle or Tanbel Inc. or its sponsors or enterprise partners. It is your sole responsibility to make whatever investigation and take whatever precautions you deem necessary or appropriate prior to interactions and transactions with other Users, whether online or in person.
            </p>
            <p>
                In no event shall WeCycle orTanbel Inc. be liable for any damages of any kind whatsoever, whether direct, indirect, general, special, compensatory, consequential, and/or incidental, arising out of or relating to the conduct of any User (including you) in connection with use of WeCycle, whether online or in person, including without limitation pecuniary loss, bodily injury or death, emotional distress, and/or any other damages resulting from communications, meetings, agreements or other dealings with other Users or other persons you encounter in connection with your use of WeCycle (a Tanbel Inc. project).
            </p>
        </Page>
    )
}

export default TermsOfServices;
