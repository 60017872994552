import React from 'react';

import 'react-dates/initialize';
import DayPicker from 'react-day-picker';
import 'react-day-picker/lib/style.css';
import './styles.scss'
import { Slider, Row } from 'antd';
import FilterBottom from '../CustomFilterBottom'
import Moment from 'moment'

const FilterCalender = ({
  from,
  to,
  handleDayClick,
  clearClicked,
  applyClicked,
  onChangeStartTime,
  onChangeEndTime,
  isEditable
}) => {
    const modifiers = { start: from, end: to };
    var numberOfMonths = 2
    if (typeof window.orientation !== 'undefined') { 
      numberOfMonths = 1
     }
     const past = { 
      before: new Date(),
    }
      return (
        <div>
        <Row>
        <DayPicker
          className="Selectable"
          numberOfMonths={numberOfMonths}
          selectedDays={[from, { from, to }]}
          modifiers={modifiers}  
          disabledDays={past}
          onDayClick={handleDayClick}
        />
        </Row>
        <Row>
        <div className={"Slider-Container"}>
          <div className={"Slider-children"}>
            <p>Start</p>
            <Slider className={'slider'}
            defaultValue={0} 
            min={0}
            max={24}
            step={1}
            onChange={onChangeStartTime}
            tipFormatter={(event)=> {
              let startTimer = Moment(from ? from : new Date())
              startTimer.set({hour:event,minute:0,second:0,millisecond:0})
              return startTimer.format('LT');
            }}
            />
          </div>
          <div className={"Slider-children"}>
            <p>End</p>
            <Slider className={'slider'}
            defaultValue={0} 
            min={0}
            max={24}
            step={1}
            onChange={onChangeEndTime}
            tipFormatter={(event)=> {
              let startTimer = Moment(to ? to : new Date())
              startTimer.set({hour:event,minute:0,second:0,millisecond:0})
              return startTimer.format('LT');
            }}
            />
          </div>
        </div>
        </Row>
        {/* this.props.isEditable */}
       {!isEditable ? <FilterBottom 
          clearClicked={clearClicked}
          applyClicked={applyClicked}
        /> : null}
        </div>
      );
    }
  // }

  export default FilterCalender;