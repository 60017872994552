import { ValidationMessages } from "../Shared/Messages";

export default class Rules {
    static getNameRules = () => (
        [
            { required: true, message: ValidationMessages.ENTER_FULL_NAME },
            { pattern: /^[a-zA-Z]+/g, message: ValidationMessages.ENTER_VALID_FULL_NAME }
        ]
    );

    static getFirstNameRules = () => (
        [
            { required: true, message: ValidationMessages.ENTER_FIRST_NAME },
            { pattern: /^[a-zA-Z]+/g, message: ValidationMessages.Enter_VALID_FIRST_NAME }
        ]
    );

    static getLastNameRules = () => (
        [
            { required: true, message: ValidationMessages.ENTER_LAST_NAME },
            { pattern: /^[a-zA-Z]+/g, message: ValidationMessages.Enter_VALID_LAST_NAME }
        ]
    );

    static getEmailRules = () => (
        [
            { required: true, message: ValidationMessages.ENTER_EMAIL },
            { pattern: /^([A-Za-z0-9_\-.+])+@([A-Za-z0-9_\-.])+\.([A-Za-z]{2,})$/, message: ValidationMessages.ENTER_VALID_EMAIL },
        ]
    );

    static getPasswordRules = () => (
        [
            { required: true, message: ValidationMessages.ENTER_PASSWORD },
            { pattern: /(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*]){8,}/g, message: ValidationMessages.ENTER_VALID_PASSWORD }
        ]
    );

    static getPhoneRules = () => (
        [
            { required: true, message: ValidationMessages.ENTER_PHONE_NUMBER },
            { pattern: /^[0-9]{10}$/g, message: ValidationMessages.ENTER_VALID_PHONE_NUMBER }
        ]
    );

    static getConfirmPasswordRules = (password) => (
        [
            { required: true, message: ValidationMessages.ENTER_CONFIRM_PASSWORD },
            {
                validator: (rule, value, callback) => {
                    let errors;
                    if (value !== password) errors = rule;
                    callback(errors);
                },
                message: ValidationMessages.NO_MATCH_CONFIRM_PASSWORD
            }
        ]
    );

    static getPostalRules = () => (
        [
            { required: true, message: ValidationMessages.ENTER_POSTAL_CODE }
        ]
    );

    static getCardRules = () => (
        [
            { required: true, message: ValidationMessages.ENTER_CARD_NUMBER },
            { pattern: /^\d{16}$/g, message: ValidationMessages.ENTER_VALID_CARD_NUMBER }
        ]
    );

    static getCVVRules = () => (
        [
            { required: true, message: ValidationMessages.ENTER_CVV },
            { pattern: /^\d{3}$/g, message: ValidationMessages.ENTER_VALID_CVV }
        ]
    );

    static getProductName = () => (
        [
            { required: true, message: ValidationMessages.ENTER_PRODUCT_NAME }
        ]
    );

    static getCategory = () => (
        [
            { required: true, message: ValidationMessages.ENTER_CATEGORY}
        ]
    );

    static getCondition = () => (
        [
            { required: true, message: ValidationMessages.ENTER_CONDITION}
        ]
    );

    static getTime = () => (
        [
            { required: true, message: ValidationMessages.ENTER_RATE },
            { pattern: /^[1-9]/, message: ValidationMessages.ENTER_RATE }
        ]
    );

    static getDescription = () => (
        [
            { required: true, message: ValidationMessages.ENTER_DESC }
        ]
    );


    static getLocation = () => (
        [
            { required: true, message: ValidationMessages.ENTER_LOC }
        ]
    );
     
}