import { FilterActionKeys } from "../Actions/FilterAction";

const defaultCommonState = {
  range: null,
  conditions: [],
  userRate: null,
  productRate: null,
};

const FilterReducer = (state = { ...defaultCommonState }, action) => {
  switch (action.type) {
    case FilterActionKeys.CLEAR_PRICE_RANGE:
      return {
        ...state,
        range: null,
      };

    case FilterActionKeys.SET_PRICE_RANGE:
      return {
        ...state,
        range: action.range,
      };

    case FilterActionKeys.SET_CONDITION_FILTER:
      return {
        ...state,
        conditions: action.conditions,
      };

    case FilterActionKeys.CLEAR_CONDITION_FILTER:
      return {
        ...state,
        conditions: null,
      };

    case FilterActionKeys.SET_CATEGORY_FILTER:
      return {
        ...state,
        categories: action.categories,
      };

    case FilterActionKeys.CLEAR_CATEGORY_FILTER:
      return {
        ...state,
        categories: null,
      };

    case FilterActionKeys.SET_USER_RATING_FILTER:
      return {
        ...state,
        userRate: action.rate,
      };

    case FilterActionKeys.CLEAR_USER_RATING_FILTER:
      return {
        ...state,
        userRate: null,
      };

    case FilterActionKeys.SET_PRODUCT_RATING_FILTER:
      return {
        ...state,
        productRate: action.rate,
      };

    case FilterActionKeys.CLEAR_PRODUCT_RATING_FILTER:
      return {
        ...state,
        productRate: null,
      };

    default:
      return { ...state };
  }
};

export default FilterReducer;
