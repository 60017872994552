import React from 'react';
import './styles.scss';
import Page from '../../Components/HOC/Page';
import { Contact } from '../../Components/Placeholder/Sections';
import Footer from '../../Components/Molecule/Footer';


const ContactUs = () => {

    return (
        <div
        className={'ContactUs'}>
            <Page
            noSection
            noFooter
            className={'contact-pg ContactUsAccess' }> 
            </Page>
                 <Contact isHide={true}/>
            <Footer />
        </div>
    )
}
export default ContactUs;