import React, {useState} from 'react';
import { Icons} from '../../../Shared/Assets'
import {
    FloatingMenu,
    MainButton,
    ChildButton,
  } from 'react-floating-button-menu';
import './Styles.scss'
import MdAdd from '@material-ui/icons/Add';
import MdClose from '@material-ui/icons/Clear';
  

const FloatingButton = ({
  buttonClicked = (index) => {},
  ownerData,
  isLiked = false
}) => {
    const [isOpen, setOpen] = useState(false);
    let {
      avatar,
      first_Name,
      name = ''
  } = ownerData || {};
    return(<FloatingMenu className="floatButton"
    slideSpeed={500}
    direction="up"
    spacing={8}
    isOpen={isOpen}
  >
    <MainButton
    iconResting={<MdAdd style={{ fontSize: 30 }}  />}
    iconActive={<MdClose style={{ fontSize: 30 }}  />}
      backgroundColor="white"
      onClick={() => setOpen(!isOpen)}
      size={50}
    />
    <ChildButton
      icon={avatar ? <img src={Icons.angleUp} alt={'avtar'} className="avatar" style={{ width: 40, height:40, 'objectFit':'cover',
      'borderRadius': 20 }}></img> : <h1 style={{fontSize: "2rem", textAlign : "center", marginTop: '10px'}}>{(first_Name ? first_Name : name).charAt(0)}</h1>}
      // icon={<ImageIcon src={Icons.u} alt={'avtar'} className="avatar" style={{ width: 40, height:40, 'objectFit':'cover',
      // 'borderRadius': 20 }}></ImageIcon>}
      
      backgroundColor="white"
      size={40}
      onClick={() => buttonClicked(0)}
    />
    <ChildButton
      icon={<img src={Icons.messageDark} alt={'messageDarkIcon'} style={{ width: 20, height:20 }}></img>}
      backgroundColor="white"
      size={40}
      onClick={() => buttonClicked(1)}
    />
    <ChildButton
      icon={<img src={Icons.shareDark} alt={'shareDarkIcon'} style={{ width: 20, height:20 }}></img>}
      backgroundColor="white"
      size={40}
      onClick={() => buttonClicked(2)}
    />
    <ChildButton
      icon={<img src={isLiked ? Icons.heartFilled : Icons.heartDark} alt={'heartIcons'} style={{ width: 20, height:20 }}></img>}
      backgroundColor="white"
      size={40}
      onClick={() => buttonClicked(3)}
    />
  </FloatingMenu>)
}

export default FloatingButton;