import React, { PureComponent } from "react";
import "./styles.scss";
import { connect } from "react-redux";
import { Col, Container, Row } from "react-bootstrap";
import Page from "../../Components/HOC/Page";
import LabelWraper from "../../Components/HOC/LabelWraper";
import ProductCard from "../../Components/Cell/ProductCard";
import DataFirebase from "../../Firebase/DataFirebase";
import RouterService from "../../Services/RouterService";
import { RouteConfig } from "../../Config/RouteConfig";
import { CATEGORIES } from "../../Config";
import { clearProductList } from "../../Redux/Actions/ProductActions";
import Search from "../../Components/Molecule/Search";
import CarouselSlider from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { Icons, Images } from "../../Shared/Assets";
import "bootstrap/dist/css/bootstrap.min.css";
import ButtonGroup from "../../Components/Molecule/ButtonGroup";
import ReactPlayer from "react-player";
import TagManager from "react-gtm-module";
import APIManager from "../../APIManager";
import moment from "moment";
import Utility from "../../Services/Utility";
// const CarouselSlider = loadable(() => import('react-multi-carousel'))

class Landing extends PureComponent {
  state = {
    playing: false,
  };

  setProductQuery() {
    DataFirebase.updateProductQuery({ radius: 20 });
  }

  onProductDetails(productKey) {
    RouterService.pushRoute(`${RouteConfig.PRODUCT}/${productKey}`);
  }

  onProductSearch(param) {
    RouterService.pushRoute({
      pathname: `${RouteConfig.PRODUCTS}/${CATEGORIES.POPULAR}`,
      search: "?" + new URLSearchParams(param).toString(),
    });
  }

  componentDidMount() {
    APIManager.getAllBlogs();
    Utility.analyticsUpdates("LandingPage", {});
  }

  createBlogs() {
    if (this.props.blogs.length === 0 || this.props.blogs.length < 4) {
      return null;
    }
    let children = [];
    for (let i = 0; i < 2; i++) {
      let variable = i * 2;
      let firstIndex = variable;
      let secondIndex = variable + 1;
      let firstBlog = this.props.blogs[variable];
      let secondBlog = this.props.blogs[variable + 1];
      if (i % 2 !== 0) {
        firstIndex = variable + 1;
        secondIndex = variable;
        firstBlog = this.props.blogs[variable + 1];
        secondBlog = this.props.blogs[variable];
      }
      let firstBlogData = (
        <div
          key={"firstBlogData" + i}
          className="mb-3 p-3 blog-box h-box-1 w-100"
          style={{
            backgroundImage: `radial-gradient(rgb(0 0 0 / 40%), rgb(66 66 66 / 25%)), url(${firstBlog._embedded["wp:featuredmedia"][0].source_url})`,
            backgroundSize: "cover",
          }}
          onClick={() => {
            RouterService.pushRoute(`${RouteConfig.BLOG}/${firstIndex}`);
          }}
        >
          <div className="blog-content-box pt-2 mt-5">
            <label>{moment(firstBlog.date).format("d MMM YYYY")}</label>
            <h1
              className="blog-title  text-white"
              style={{ textShadow: "0.5px 0.5px #000000" }}
            >
              {firstBlog.title.rendered}
            </h1>
          </div>
        </div>
      );

      let secondBlogData = (
        <div
          key={"secondBlogData" + i}
          className="mb-3 p-3 blog-box  h-box-2 w-100"
          style={{
            backgroundImage: `radial-gradient(#00000000, rgb(0 0 0 / 50%)), url(${secondBlog._embedded["wp:featuredmedia"][0].source_url})`,
            backgroundSize: "cover",
          }}
          onClick={() =>
            RouterService.pushRoute(`${RouteConfig.BLOG}/${secondIndex}`)
          }
        >
          <div className="blog-content-box pt-2 mt-5 ">
            <label>{moment(secondBlog.date).format("d MMM YYYY")}</label>
            <h1
              className="blog-title  text-white"
              style={{ textShadow: "0.5px 0.5px #000000" }}
            >
              {secondBlog.title.rendered}
            </h1>
            {/* <p className="  text-white">{secondBlog.excerpt.rendered}</p> */}
            <div
              className="  text-white"
              dangerouslySetInnerHTML={{ __html: secondBlog.excerpt.rendered }}
            />
          </div>
        </div>
      );

      let child = [firstBlogData, secondBlogData];
      if (i % 2 !== 0) {
        child = child.reverse();
      }
      children.push(
        <Col md={6} key={"children" + i}>
          {child}
        </Col>
      );
    }
    return children;
  }

  render() {
    let tagManagerArgs = {
      dataLayer: {
        page: "Landing",
      },
      dataLayerName: "PageDataLayer",
    };

    TagManager.dataLayer(tagManagerArgs);

    var width = window.innerWidth;
    let products = null;
    let Buyproducts = null;
    Buyproducts = this.props.products.filter(
      (product, index) => product.isSell === true
    );
    let rentalProducts = this.props.products.filter(
      (product, index) => product.isSell === false
    );
    if (width > 768 && width < 1200) {
      rentalProducts = rentalProducts.filter((product, index) => index < 9);
      Buyproducts = Buyproducts.filter((product, index) => index < 9);
    } else {
      rentalProducts = rentalProducts.filter((product, index) => index < 10);
      Buyproducts = Buyproducts.filter((product, index) => index < 10);
    }
    const responsive = {
      superLargeDesktop: {
        // the naming can be any, depends on you.
        breakpoint: { max: 4000, min: 3000 },
        items: 14,
      },
      desktop: {
        breakpoint: { max: 3000, min: 1260 },
        items: 6,
      },
      tablet: {
        breakpoint: { max: 1260, min: 768 },
        items: 5,
      },
      mobile: {
        breakpoint: { max: 768, min: 0 },
        items: 2,
      },
    };
    return (
      <Page
        noSection
        className={"landing-page"}
        containerClassName={"landing-container"}
      >
        <div>
          <div style={{ background: "#f2f2f2" }}>
            <div className={"landing-Top"}>
              <div className={"search-wrapper"}>
                <div>
                  <p className="title colored">Rent what you want</p>
                  <p className="title">Lend what you don't</p>
                </div>
                <div className={"form-wrapper"}>
                  <Search />
                </div>
              </div>
              <div className="slider-img">
                <img
                  width={500}
                  className={"people landing-Image-slier"}
                  src={Images.landingHomeSlider}
                  alt="slider-img"
                />
              </div>
            </div>
          </div>
          <section className="step-section-outer">
            {rentalProducts.length > 0 && (
              <div>
                <div className="left-path trending_buy">
                  <LabelWraper
                    className={"labelwrap"}
                    heading={"Trending Rental"}
                    id={"popular"}
                  >
                    <CarouselSlider
                      responsive={responsive}
                      swipeable={true}
                      draggable={true}
                      arrows={false}
                      infinite={true}
                      customButtonGroup={
                        <ButtonGroup
                          seeMoreClicked={(item) => {
                            let param = {
                              type: "rent",
                            };
                            DataFirebase.updateQueryRadius();
                            RouterService.pushRoute({
                              pathname: `${RouteConfig.PRODUCTS}/${CATEGORIES.POPULAR}`,
                              search:
                                "?" + new URLSearchParams(param).toString(),
                            });
                          }}
                        />
                      }
                      itemClass={"Landing-productSlider-Item"}
                      className={"Landing-productSlider"}
                    >
                      {rentalProducts.slice(0, 8).map((product, ind) => {
                        let { id } = product;

                        return (
                          <ProductCard
                            key={ind}
                            className={"Cards-Product"}
                            onClick={this.onProductDetails.bind(this, id)}
                            product={product}
                          />
                        );
                      })}
                    </CarouselSlider>
                  </LabelWraper>
                </div>
              </div>
            )}

            {Buyproducts.length > 0 && (
              <div>
                <div className="left-path trending_buy">
                  <LabelWraper
                    className={"labelwrap"}
                    heading={"Trending Buy"}
                    id={"popular"}
                    // onSeeMore={() => {
                    //    DataFirebase.updateQueryRadius();
                    //    RouterService.pushRoute(`${RouteConfig.PRODUCTS}/${CATEGORIES.POPULAR}`);
                    // }}
                  >
                    <CarouselSlider
                      responsive={responsive}
                      swipeable={true}
                      draggable={true}
                      arrows={false}
                      infinite={true}
                      customButtonGroup={
                        <ButtonGroup
                          seeMoreClicked={() => {
                            let param = {
                              type: "buy",
                            };
                            DataFirebase.updateQueryRadius();
                            RouterService.pushRoute({
                              pathname: `${RouteConfig.PRODUCTS}/${CATEGORIES.POPULAR}`,
                              search:
                                "?" + new URLSearchParams(param).toString(),
                            });
                          }}
                        />
                      }
                      itemClass={"Landing-productSlider-Item"}
                      className={"Landing-productSlider"}
                    >
                      {Buyproducts.slice(0, 8).map((product, ind) => {
                        let { id } = product;
                        return (
                          <ProductCard
                            key={ind}
                            className={"Cards-Product"}
                            onClick={this.onProductDetails.bind(this, id)}
                            product={product}
                          />
                        );
                      })}
                    </CarouselSlider>
                  </LabelWraper>
                </div>
              </div>
            )}
          </section>
          <section className="step-section-outer" style={{ marginTop: 100 }}>
            <Container>
              <div className="step-section">
                <Row className="step-list">
                  <Col md={12}>
                    <h1 className="title text-center">How it works</h1>
                  </Col>
                  <Col md={6}>
                    <img
                      className={"count-image img-fluid"}
                      src={Images.One}
                      alt="Images-One"
                    />
                    <h3>Step 1</h3>
                    <p>
                      Find the item you want to rent, select the length of the
                      rental period, then book the selected item.
                    </p>
                  </Col>
                  <Col
                    md={6}
                    className="d-flex justify-content-center  align-items-center"
                  >
                    <img
                      className={"step-Image img-fluid"}
                      src={Images.StepOne}
                      alt="StepOneimage"
                    />
                  </Col>
                </Row>
                <Row className="step-list ">
                  <Col md={{ order: 2 }}>
                    <div className=" float-right FloatSmall">
                      <img
                        className={"count-image img-fluid"}
                        src={Images.two}
                        alt="Imagestwo"
                      />
                      <h3>Step 2</h3>
                      <p>Wait for the lender to accept your request.</p>
                    </div>
                  </Col>
                  <Col
                    md={{ order: 1 }}
                    className="d-flex justify-content-center  align-items-center"
                  >
                    <img
                      className={"step-Image img-fluid"}
                      src={Images.StepTwo}
                      alt="Imagestwo"
                    />
                  </Col>
                </Row>
                <Row className="step-list">
                  <Col md={6}>
                    <img
                      className={"count-image img-fluid"}
                      src={Images.three}
                      alt="Imagesthree"
                    />
                    <h3>Step 3</h3>
                    <p>
                      Meet with the lender at a mutually selected location and
                      pick up your item.
                    </p>
                  </Col>
                  <Col
                    md={6}
                    className="d-flex justify-content-center  align-items-center"
                  >
                    <img
                      className={"step-Image img-fluid"}
                      src={Images.StepThree}
                      alt="Imagesthree"
                    />
                  </Col>
                </Row>
              </div>
            </Container>
          </section>
          <section className="video-section">
            <Container className="video-section-container">
              {!this.state.playing && (
                <div className="video-section-div1 video-content">
                  <h3>As Featured In</h3>
                  {/* <p>As Featured In</p> */}
                  {/* <button className="video-section-div1-btn custom-button read-more">
                           Read More</button> */}
                </div>
              )}
              <div className="video-section-div2 W-100 position-relative z-index-9">
                {
                  <ReactPlayer
                    className={"video-image w-100 img-fluid"}
                    url={Images.video}
                    width="100%"
                    height="100%"
                    byline=""
                    playing={this.state.playing}
                    loop={true}
                    onReady={(player) => {
                      if (!this.state.playing) {
                        player.seekTo(1, "seconds");
                      }
                    }}
                  />
                }

                <button
                  className="  play-btn"
                  onClick={() =>
                    this.setState({ playing: !this.state.playing })
                  }
                >
                  {this.state.playing ? (
                    <img
                      src={Icons.pause}
                      alt="pauseIcon"
                      style={{ width: "100%" }}
                    />
                  ) : (
                    <i className="fa fa-play-circle" aria-hidden="true"></i>
                  )}
                </button>
              </div>
            </Container>
          </section>
          <section className="businesses-section businesses-section-image">
            <Container>
              <Row>
                <Col
                  md={6}
                  className="d-flex  justify-content-center align-items-center flex-column"
                >
                  <img
                    className={"step-Image img-fluid"}
                    alt="programImage"
                    src={Images.program}
                  />
                </Col>
                <Col md={6}>
                  <div
                    style={{ height: "100%" }}
                    className="d-flex justify-content-center flex-column align-center"
                  >
                    <h1 className="title mr-auto">
                      We-Assured<br></br> Program
                    </h1>
                    <p>
                      Your items are covered by We-Assured for up to 1k when you
                      lend{" "}
                    </p>
                  </div>
                </Col>
              </Row>
            </Container>
          </section>

          <section className="businesses-section businesses-section-image">
            <Container>
              <Row>
                <Col
                  md={6}
                  className="d-flex  justify-content-center align-items-center flex-column"
                >
                  <div>
                    <h1 className="title mr-auto">
                      Connecting <br></br> Businesses
                    </h1>
                    <p className=" mr-auto">
                      {" "}
                      WeCycle will partner with local businesses to maintain,
                      expand, and to secure their rentals with an innovative,
                      safe solution for rental management.
                    </p>
                    <button
                      className="custom-button mr-auto z-in-9"
                      onClick={() => {
                        RouterService.pushRoute(`${RouteConfig.BUSINESS}`);
                      }}
                    >
                      {" "}
                      Get in touch
                    </button>
                  </div>
                </Col>
                <Col md={6}>
                  <img
                    className={"step-Image img-fluid"}
                    src={Images.Business}
                    alt="BusinessImage"
                  />
                </Col>
              </Row>
            </Container>
          </section>

          <section className="blog-section blog-section-image ">
            {this.props.blogs.length > 0 && (
              <Container>
                <Row style={{ zIndex: "20", opacity: "5" }}>
                  <Col md={12} className="mb-3">
                    <h1 className="title text-center">Blog</h1>
                  </Col>
                  {this.createBlogs()}
                </Row>
              </Container>
            )}
          </section>
        </div>
      </Page>
    );
  }
}

const mapStateToProps = (state) => ({
  location: state.AuthReducer.location,
  loadingProducts: state.ProductReducer.loadingProducts,
  products: state.ProductReducer.products,
  blogs: state.BlogReducer.blogs,
});

export default connect(mapStateToProps, { clearProductList })(Landing);
