import React from 'react';
import './styles.scss';
import cross from '../../../Assets/Icons/cross-light.png'

const modal = ({children, isClose, isCloseClicked, modelSizeClass='', modelContentSize=``}) => {
    return(
        <div className={"content"}>
       { isClose ? 
       <img alt={'cross'} src={cross} onClick={isCloseClicked}
       className={"cross"}/> : null }
            <div className={`${modelSizeClass} model `}>
            <div className={`model-Content ${modelContentSize}`}>
                    {children}
            </div>
            </div>
        </div>
    )
}

export default modal;