import React from 'react'
// import { Form  , Row , Col} from 'react-bootstrap';
import { Row, Col } from 'react-bootstrap';
import { Form, Select, Checkbox } from 'antd'
import Modal from '../../../../Atom/Modal/index'
import { NavLink } from 'react-router-dom';
import Rules from '../../../../../Services/Rules';
import CustomInput from '../../../../Atom/CustomInput';
import '../ShipAddressModal/style.scss'
import Database from '../../../../../Firebase/DataFirebase'
import { connect } from 'react-redux';
import STATES from '../../../../../Shared/states'
const { Option } = Select;

// const ModalShipAdress =(props) =>{
class ModalShipAdress extends React.Component {



  state = {
    area: '',
    address: '',
    country: 'US',
    houseNumber: '',
    landmark: '',
    name: '',
    phone: '',
    pincode: '',
    state: '',
    town: '',
    isdefaultAddress: false
  }

  onAddressAddClicked = (event) => {
    event.preventDefault();
        let { validateFields } = this.props.form;
        validateFields((error, values) => {
            if (!error) {
                let data = {
                  name : this.state.name,
                  phone : this.state.phone,
                  pincode: this.state.pincode,
                  town: this.state.town,
                  state: this.state.state,
                  houseNumber: this.state.address,
                  country: this.state.country,
                  area: this.state.area
                }
                
                Database.addShippingAddress(this.props.user.uid, data, this.state.isdefaultAddress)
                .then(success => {
                  this.props.addressAdded()
                })
            }
        });
  }


  render() {

    let { getFieldDecorator } = this.props.form;
    return (
      <>
        <Modal
          className="ShipAddresMain"
          isClose
          isCloseClicked={this.props.isCloseClicked}>
          <Row>
            <h3>Address</h3>
          </Row>
          <Form>

            <Row>
              <Col lg={6} md={6}>
                <h8>Full Name (First and Last)</h8>
                <Form.Item>
                  {getFieldDecorator('name', {
                    initialValue: this.state.name,
                    rules: [
                      { required: true, message: 'Enter First and Last name' }
                    ]
                  })(
                    <CustomInput
                      type={'text'}
                      placeholder={'Enter Name'}
                      autoCorrect={'none'}
                      autoCapitalize={'none'}
                      onChange={(event) => {
                        this.setState({
                            name: event.target.value
                        });
                    }}
                    />
                  )}
                </Form.Item>
              </Col>
              <Col lg={6} md={6}>
                <h8>Phone Number</h8>
                <Form.Item>
                  {getFieldDecorator('phone', {
                    initialValue: this.state.phone,
                    rules: Rules.getPhoneRules()
                  })(
                    <CustomInput
                      type={'phone'}
                      placeholder={'Enter Phone Number'}
                      autoCorrect={'none'}
                      autoCapitalize={'none'}
                      onChange={(event) => {
                        this.setState({
                          phone: event.target.value
                        });
                      }}
                    />
                  )}
                </Form.Item>
              </Col>

              <Col lg={6} md={6}>
                <h8>Street Address</h8>
                <Form.Item>
                  {getFieldDecorator('street', {
                    initialValue: this.state.address,
                    rules: [
                      { required: true, message: 'Enter Street Address' }
                    ]
                  })(
                    <CustomInput
                      type={'text'}
                      placeholder={'Enter Street Address'}
                      autoCorrect={'none'}
                      autoCapitalize={'none'}
                      onChange={(event) => {
                        this.setState({
                          address: event.target.value
                        });
                      }}
                    />
                  )}
                </Form.Item>
              </Col>

              <Col lg={6} md={6}>
                <h8>Unit#</h8>
                <Form.Item>
                  {getFieldDecorator('Unit#', {
                    initialValue: this.state.area,
                    // rules: [
                    //   { required: true, message: 'Enter Unit#' }
                    // ]
                  })(
                    <CustomInput
                      type={'text'}
                      placeholder={'Enter Unit#'}
                      autoCorrect={'none'}
                      autoCapitalize={'none'}
                      onChange={(event) => {
                        this.setState({
                          area: event.target.value
                        });
                      }}
                    />
                  )}
                </Form.Item>
              </Col>

              <Col lg={6} md={6}>
                <h8>City/Town</h8>
                <Form.Item>
                  {getFieldDecorator('City/Town', {
                    initialValue: this.state.town,
                    rules: [
                      { required: true, message: 'Enter City/Town' }
                    ]
                  })(
                    <CustomInput
                      type={'text'}
                      placeholder={'Enter City/Town'}
                      autoCorrect={'none'}
                      autoCapitalize={'none'}
                      onChange={(event) => {
                        this.setState({
                          town: event.target.value
                        });
                      }}
                    />
                  )}
                </Form.Item>
              </Col>

              <Col lg={6} md={6}>
                <h8>State</h8>
                <Form.Item>
                  {getFieldDecorator('State', {
                    initialValue: this.state.state,
                    rules: [
                      { required: true, message: 'Enter State' }
                    ]
                  })(
                    <Select placeholder="Please choose State"
                      onChange={(value) => {
                        this.setState({
                          state: value
                        });
                      }}>
                      {
                        STATES.map((state, index) => {
                          return (
                            <Option 
                            key={index} 
                            value={state.adminCode1}>{state.name}</Option>
                          )
                        })
                      }
                    </Select>

                  )}
                </Form.Item>
              </Col>
              <Col lg={6} md={6}>
                <h8>Zip Code</h8>
                <Form.Item>
                  {getFieldDecorator('ZipCode', {
                    initialValue: this.state.pincode,
                    rules: [
                      { required: true, message: 'Enter Zip Code' }
                    ],
                  })(
                    <CustomInput
                      type={'text'}
                      placeholder={'Enter Zip Code'}
                      autoCorrect={'none'}
                      autoCapitalize={'none'}
                      onChange={(event) => {
                        this.setState({
                          pincode: event.target.value
                        });
                      }}
                    />
                  )}
                </Form.Item>
              </Col>

              <Col lg={6} md={6}>
                <h8>Country</h8>
                <Form.Item>
                  {getFieldDecorator('Country', {
                    rules: [
                      { required: true, message: 'Enter Country' }
                    ],
                    initialValue: 'US',
                  })(
                    <CustomInput
                      type={'text'}
                      placeholder={'Enter Country'}
                      autoCorrect={'none'}
                      autoCapitalize={'none'}
                      disabled
                    />
                  )}
                </Form.Item>
              </Col>
            </Row>
            <Row>

            <Col lg={6} md={6}>
              <Form.Item 
              name="remember"
              valuePropName="checked"
              >
                <Checkbox
                defaultValue={this.state.isdefaultAddress}
                onChange={(e)=> this.setState({isdefaultAddress: e.target.checked})}
                >Default Address</Checkbox>
              </Form.Item>
              </Col>
            </Row>
            <Row>
              <NavLink to="#" onClick={this.onAddressAddClicked} className="SaveShipAddress" >SAVE</NavLink>
            </Row>

          </Form>

        </Modal>
      </>
    )
  }
}

const mapStateToProps = state => {
  return {
      user: state.AuthReducer.user,
  };
}

export default connect(mapStateToProps)(Form.create()(ModalShipAdress));