import React, { Component } from 'react';

import { connect } from 'react-redux';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { RouteConfig } from '../Config/RouteConfig';
import { GEOCODE_API, NYCAddress } from '../Config';
import Geocode from 'react-geocode';
import RouterService from '../Services/RouterService';
import { setLocation } from '../Redux/Actions/AuthActions';
import mixpanel from 'mixpanel-browser'

import DataFirebase from '../Firebase/DataFirebase';
/**
 * Importing all the required routes
 */
import Placeholder from './Placeholder';
import Landing from './Landing';
import ProductDetails from './ProductDetails';
import CategoryProducts from './CategoryProducts';
import Checkout from './Checkout';
import TermsOfServices from './TermsOfServices';
import PrivacyPolicy from './PrivacyPolicy';
import ResetPassword from './ResetPassword';
import PageNotFound from './PageNotFound';
import Blog from './Blog';
import Chat from './Chat';
import Profile from './Profile';
import UserProfile from './UserProfile';
import Blogs from './Blogs';
import Post from './Post';
import Like from './Like';
import AboutUs from './AboutUs';
import ContactUs from './ContactUs';
import BusinessContact from './BusinessContact'
import { message } from 'antd';
import { FaCommentsDollar } from 'react-icons/fa';


class AppRoutes extends Component {
    constructor(props) {
        super(props);
        mixpanel.init("e53ed5aba605a63ee13dd90ef6f0ea9f");
        Geocode.setApiKey(GEOCODE_API);
        message.config({
            top: 100,
            duration: 2,
        });
       

    }

    componentDidMount() {
        this.getUserAddress();   
    }

    getUserAddress() {
        if (!navigator) return this.setDefaultAddress();
        navigator.geolocation.getCurrentPosition(({ coords }) => {
            let { latitude, longitude } = coords || {};
            DataFirebase.setProductQuery(latitude, longitude);
            /**
             * Get address from latitude and longitude
             */
            Geocode.fromLatLng(latitude, longitude)
                .then(({ results }) => {
                    const address = ((results[0] || {}).address_components[1].long_name);
                    this.props.setLocation(address, latitude, longitude);
                })
                .catch(error => {
                    console.log('error', error)
                });
        },
            (error) => { 
                console.log(error)
                if (!this.props.location) {
                    this.setDefaultAddress();
                }
            },
            {
                enableHighAccuracy: true,
                maximumAge:Infinity,
            }
        );
    }

    setDefaultAddress() {
        let { coords, address } = NYCAddress;
        DataFirebase.setProductQuery(...coords);
        this.props.setLocation(address, ...coords);
    }

    render() {
        return (
            <BrowserRouter ref={RouterService.setBrowserHistoryRef}>
                <Switch>
                    <Route exact path={RouteConfig.RESET_PASSWORD} component={ResetPassword} />
                    <Route render={() => {
                        return (
                            <Switch>
                                <Route exact path={RouteConfig.LANDING} component={Landing} />
                                <Route path={RouteConfig.PLACEHOLDER} component={Placeholder} />
                                <Route path={`${RouteConfig.PRODUCT}/:productKey`} component={ProductDetails} />
                                <Route path={`${RouteConfig.PRODUCTS}/:category`} component={CategoryProducts} />
                                <Route path={RouteConfig.CHECKOUT} component={Checkout} />
                                <Route path={`${RouteConfig.BLOG}/:id?`} component={Blog} />
                                <Route path={RouteConfig.TERMS_OF_SERVICES} component={TermsOfServices} />
                                <Route path={RouteConfig.PRIVACY_POLICY} component={PrivacyPolicy} />
                                <Route path={`${RouteConfig.CHAT}/:id?`} component={Chat} />
                                <Route path={`${RouteConfig.PROFILE}`} component={Profile} />
                                <Route path={`${RouteConfig.USERPROFILE}/:id?`} component={UserProfile} />
                                <Route path={`${RouteConfig.POST}/:id?`} component={Post} />
                                <Route path={RouteConfig.BLOGS} component={Blogs} />
                                <Route path={RouteConfig.LIKE} component={Like}/>
                                <Route path={RouteConfig.ABOUT} component={AboutUs} />
                                <Route path={RouteConfig.CONTACT} component={ContactUs} />
                                <Route path={RouteConfig.BUSINESS} component={BusinessContact} />
                                <Route component={PageNotFound} />
                            </Switch>
                        );
                    }} />
                </Switch>
            </BrowserRouter>
        )
    }
}

export default connect(null, { setLocation })(AppRoutes);
