export const ProductActionTypes = {
    LOADING_PRODUCTS: 'LOADING_PRODUCTS',
    LOAD_MORE: 'LOAD_MORE',
    SET_PRODUCTS: 'SET_PRODUCTS',
    PRODUCT_ENTERED: 'PRODUCT_ENTERED',
    CLEAR_PRODUCT_LIST: 'CLEAR_PRODUCT_LIST',
    PRODUCT_CONDITIONS: 'PRODUCT_CONDITIONS',
    PRODUCT_CATEGORY: 'PRODUCT_CATEGORY',
    PRODUCT_ADD: 'PRODUCT_ADD',
    PRODUCT_REMOVE: 'PRODUCT_REMOVE',
    ADD_USER_PRODUCTS: 'ADD_USER_PRODUCTS',
    CLEAR_USER_PRODUCTS: 'CLEAR_USER_PRODUCTS',
    FAVORITE_ADD: 'FAVORITE_ADD',
    FAVORITE_REMOVE: 'FAVORITE_REMOVE',
    FAVORITE_ENTERED: 'FAVORITE_ENTERED',
    LENDING_ADD: 'LENDING_ADD',
    LENDING_REMOVE: 'LENDING_REMOVE',
    LENDING_ENTERED: 'LENDING_ENTERED',
    SHIPPING_PRODUCTS: 'SHIPPING_PRODUCTS',
}

export const loadingProducts = status => {
    return {
        type: ProductActionTypes.LOADING_PRODUCTS,
        status
    };
}

export const setConditions = conditions => {
    return {
        type : ProductActionTypes.PRODUCT_CONDITIONS,
        conditions
    }
}

export const setCategory = category => {
    return {
        type : ProductActionTypes.PRODUCT_CATEGORY,
        category
    }
}

export const loadMore = status => {
    return {
        type: ProductActionTypes.LOAD_MORE,
        status
    };
}

export const postProduct = product => {
    return {
        type: ProductActionTypes.PRODUCT_ADD,
        product
    };
}
export const addFavorite = favorites => {
    return {
        type: ProductActionTypes.FAVORITE_ADD,
        favorites
    };
}

export const addLending = product => {
    return {
        type: ProductActionTypes.LENDING_ADD,
        product
    };
}

export const addUserProduct = product => {
    return {
        type: ProductActionTypes.ADD_USER_PRODUCTS,
        product
    };
}

export const clearUserProduct = product => {
    return {
        type: ProductActionTypes.CLEAR_USER_PRODUCTS,
    };
}

export const postRemove = () => {
    return {
        type: ProductActionTypes.PRODUCT_REMOVE
    };
}

export const favoriteRemove = () => {
    return {
        type: ProductActionTypes.FAVORITE_REMOVE,
    };
}

export const lendingRemove = () => {
    return {
        type: ProductActionTypes.LENDING_REMOVE,
    };
};

export const setProducts = (products = []) => {
    return {
        type: ProductActionTypes.SET_PRODUCTS,
        products
    };
};

export const productEntered = (product) => {
    return {
        type: ProductActionTypes.PRODUCT_ENTERED,
        product
    };
}

export const favoriteEntered = (favorite) => {
    return {
        type: ProductActionTypes.FAVORITE_ENTERED,
        favorite
    };
}

export const updateShippingProducts = (products) => {
    return {
        type : ProductActionTypes.SHIPPING_PRODUCTS,
        products
    }
}

export const lendingEntered = (product) => {
    return {
        type: ProductActionTypes.LENDING_ENTERED,
        product
    };
}

export const clearProductList = () => {
    return {
        type: ProductActionTypes.CLEAR_PRODUCT_LIST
    };   
}