import React, { PureComponent } from "react";
import "./styles.scss";
import { connect } from "react-redux";
import DataFirebase from "../../Firebase/DataFirebase";
import { Icon, Select } from "antd";
import Page from "../../Components/HOC/Page";
import FilterBar from "../../Components/Cell/FilterBar";
import SearchBar from "../../Components/Molecule/SearchBar";
import LabelWrapper from "../../Components/HOC/LabelWraper";
import ProductCard from "../../Components/Cell/ProductCard";
import RouterService from "../../Services/RouterService";
import { RouteConfig } from "../../Config/RouteConfig";
import InfiniteScroll from "react-infinite-scroller";
import TagManager from "react-gtm-module";

class ProductDetails extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      productDetails: {},
      fetched: false,
      loading: false,
      sortBy: "newest",
      searchText: null,
      from: null,
      type: "all",
      to: null,
      isUserProducts: false,
    };
  }

  componentWillMount() {
    let param = new URLSearchParams(this.props.history.location.search);
    DataFirebase.getConditions();
    DataFirebase.getProductCategory();
    for (let p of param.entries()) {
      if (p[0] === "search") {
        this.setState({
          ...this.state,
          searchText: p[1],
        });
      } else if (p[0] === "product") {
        this.setState({
          ...this.state,
          isUserProducts: true,
        });
      } else if (p[0] === "type") {
        this.setState({
          ...this.state,
          type: p[1],
        });
      }
    }
  }

  toggleLoading() {
    this.setState((state) => ({
      loading: !state.loading,
    }));
  }

  onProductDetails(productKey) {
    RouterService.pushRoute(`${RouteConfig.PRODUCT}/${productKey}`);
  }

  filterProducts(products = []) {
    let filteredProducts = products;
    if ( this.props.selectedCategory?.length > 0) {
      let selectedID = this.props.selectedCategory.map(
        (condition) => condition.id
      );
      filteredProducts = filteredProducts.filter((product) => {
        return selectedID.includes(`${product.categoryId}`);
      });
    }
    if (this.state.searchText) {
      filteredProducts = filteredProducts.filter((product) =>
        product.name.toLowerCase().includes(this.state.searchText.toLowerCase())
      );
    }
    if (this.state.type === "rent") {
      filteredProducts = filteredProducts.filter(
        (product) => product.isSell === false
      );
    } else if (this.state.type === "buy") {
      filteredProducts = filteredProducts.filter(
        (product) => product.isSell === true
      );
    }
    if (this.props.range) {
      let minValue = this.props.range[0];
      let maxValue = this.props.range[1];
      filteredProducts = filteredProducts.filter((product) => {
        return product.weeklyRate >= minValue && product.weeklyRate <= maxValue;
      });
    }
    if ( this.props.selectedCondition?.length > 0) {
      let selectedID = this.props.selectedCondition.map(
        (condition) => condition.id
      );
      filteredProducts = filteredProducts.filter((product) => {
        return selectedID.includes(`${product.conditionId}`);
      });
    }
    return filteredProducts;
  }

  sortProducts(products = []) {
    let { sortBy } = this.state;
    switch (sortBy) {
      case "newest":
        return products;
      case "popular":
        return products.sort((first, second) => {
          if (first.conditionId === second.conditionId) return 0;
          return first.conditionId > second.conditionId ? -1 : 1;
        });
      case "hlth":
        return products.sort((first, second) => {
          if (first.hourlyRate === second.hourlyRate) return 0;
          return first.hourlyRate > second.hourlyRate ? 1 : -1;
        });
      case "hhtl":
        return products.sort((first, second) => {
          if (first.hourlyRate === second.hourlyRate) return 0;
          return first.hourlyRate > second.hourlyRate ? -1 : 1;
        });
      case "wlth":
        return products.sort((first, second) => {
          if (first.weeklyRate === second.weeklyRate) return 0;
          return first.weeklyRate > second.weeklyRate ? 1 : -1;
        });
      case "whtl":
        return products.sort((first, second) => {
          if (first.weeklyRate === second.weeklyRate) return 0;
          return first.weeklyRate > second.weeklyRate ? -1 : 1;
        });
      default:
        return products;
    }
  }

  searchButtonClicked = (text) => {
    this.setState({
      ...this.state,
      searchText: text,
    });
  };

  render() {
    let tagManagerArgs = {
      dataLayer: {
        page: "Landing",
      },
      dataLayerName: "PageDataLayer",
    };

    const loading = this.props.loadingProducts;

    TagManager.dataLayer(tagManagerArgs);

    var products = [];
    if (this.state.isUserProducts) {
      products = this.props.userProducts;
    } else {
      products = this.props.products;
    }
    // var { products = [] } = this.props;
    products = this.filterProducts([...products]);
    let sortedProducts = this.sortProducts([...products]);

    return (
      <Page noVerticalPadding noSection className={"products-page"}>
        <div className={"search-bar-wrapper"}>
          <SearchBar
            searched={this.state.searchText}
            onClick={this.searchButtonClicked}
            dark
          />
        </div>
        <FilterBar
          className={"product-filters"}
          to={this.state.to}
          from={this.state.from}
          priceRange={this.state.priceRange}
          conditions={this.props.conditions}
          conditionFilterUpdated={(event) => {
            this.setState({
              ...this.state,
              selectedCondition: event,
            });
          }}
          conditionFilterClear={() => {
            this.setState({
              ...this.state,
              selectedCondition: [],
            });
          }}
          categories={this.props.categories}
          CategoryFilterUpdated={(event) => {
            this.setState({
              ...this.state,
              selectedCategory: event,
            });
          }}
          CategoryFilterClear={() => {
            this.setState({
              ...this.state,
              selectedCategory: [],
            });
          }}
        />
        <div className={"result-info-wrapper"}>
          <span className={"result-count"}>
            {products.length.toLocaleString()} results
          </span>
          <div className={"sort-wrapper "}>
            <span>Sort By:</span>
            <Select
              defaultValue={this.state.sortBy}
              onChange={(sortBy) => {
                this.setState({ sortBy });
              }}
            >
              <Select.OptGroup label={"Normal"}>
                <Select.Option value={"newest"}>Newest</Select.Option>
                <Select.Option value={"popular"}>Popular</Select.Option>
              </Select.OptGroup>
              <Select.OptGroup
                className={"SelctLine"}
                label={"Sort by Hourly Rate"}
              >
                <Select.Option value={"hlth"}>Low to High</Select.Option>
                <Select.Option value={"hhtl"}>High to Low</Select.Option>
              </Select.OptGroup>
              <Select.OptGroup label={"Sort by Weekly Rate"}>
                <Select.Option value={"wlth"}>Low to High</Select.Option>
                <Select.Option value={"whtl"}>High to Low</Select.Option>
              </Select.OptGroup>
            </Select>
          </div>
        </div>
        <LabelWrapper>
          <InfiniteScroll
            pageStart={0}
            hasMore={this.state.isUserProducts ? false : this.props.loadMore}
            loadMore={() => {
              DataFirebase.updateQueryRadius();
            }}
            loader={
              loading && (
                <div key={-1} className={"pagination-loader"}>
                  <Icon type="loading" className={"loading-icon"} />
                </div>
              )
            }
          >
            <div className={"product-grid"}>
              {sortedProducts.map((product, index) => {
                let { id } = product;
                return (
                  <ProductCard
                    key={index}
                    onClick={this.onProductDetails.bind(this, id)}
                    product={product}
                  />
                );
              })}
            </div>
          </InfiniteScroll>
        </LabelWrapper>
      </Page>
    );
  }
}

const mapStateToProps = (state) => ({
  isLoggedIn: state.AuthReducer.isLoggedIn,
  userData: state.AuthReducer.userData,
  loadingProducts: state.ProductReducer.loadingProducts,
  products: state.ProductReducer.products,
  userProducts: state.ProductReducer.userProducts,
  loadMore: state.ProductReducer.loadMore,
  range: state.FilterReducer.range,
  selectedCondition: state.FilterReducer.conditions,
  selectedCategory: state.FilterReducer.categories,
  location: state.AuthReducer.address.location,
});

export default connect(mapStateToProps)(ProductDetails);
