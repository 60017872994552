import React, { PureComponent } from 'react';
import './styles.scss';
import {  Spin, Row, Col } from 'antd'
import ModalWrap from '../../Atom/Modal'
import { Modal } from 'react-bootstrap';
import CustomButton from '../../Atom/CustomButton'
import { RouteConfig } from '../../../Config/RouteConfig'
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import DataFirebase from '../../../Firebase/DataFirebase';

class Cart extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            isClose: true,
            isRedirect: false,
            isProductCart: false
        }
    }

    checkOut = () => {
        this.setState({
            isRedirect: true
        })
    }

    remove = (id) => {
        DataFirebase.cartRemoveProduct(this.props.user.uid, id)
    }

    render(){
        let cartProducts = this.props.cartProducts;
        let total = 0;
        cartProducts.map((product, index) => {   
            // let {
            //     id
            // } = product;
            total = total + Number(product.finalPrice);
        })
        return (
    
            <div className={"cart"}>
                <ModalWrap 
                isClose={this.state.isClose && cartProducts.length > 0}
                isCloseClicked={this.props.close}
                className={'wrap-modal'}>  

                { this.state.isRedirect ? <Redirect to={RouteConfig.CHECKOUT}/> : null}
 
                    <Modal.Dialog>
                        <Modal.Header className={'modal-head'}>
                        <Modal.Title className={'modal-head-title'}><h6>Cart</h6></Modal.Title>
                        </Modal.Header>

                        <Spin className={'spinner'} spinning={this.props.isLoading}/>
                <div>
                        <Modal.Body className={'modal-body'}> 
                        {cartProducts.map((product, index) => {   
                            let {
                                id,
                            } = product;
                                let productImages = product.product.images.map(image => image.downloadURL);
                                
                            return (                   
                        <Row className={"cart-product"} 
                        key={index}>
                            <Col xs={6} sm={6} md={6}><img alt={'cart-Img'} className={"cart-Img"} src={productImages}/></Col>
                            <Col className={"cart-info"} xs={12} sm={12} md={12}>
                                <h4>{product.product.name}</h4>
                                <h6>{product.product.desc}</h6>
                                <h4 className={"rate"}>${product.product.hourlyRate} / ${product.product.weeklyRate}</h4>
                            </Col>
                            <Col className={"remove"} xs={6} sm={6} md={6} onClick={this.remove.bind(this, id)}>Remove</Col>
                        </Row> 
                        );
                    })}
                         
                        </Modal.Body>  

                        <Modal.Footer>
                            <Row className={"amount"}>
                            <Col xs={18} sm={18} md={18}>
                                {cartProducts.length} items
                            </Col>
                            <Col xs={6} sm={6} md={6}>
                                Total: <span className={"total"}>${total.toFixed(2)}</span> 
                            </Col>
                            </Row>
                        </Modal.Footer>
                        </div>
                        
                        <Modal.Footer className={"checkout-sec"}>
                            <Row>
                            <Col xs={24} sm={24} md={24}>
                                <CustomButton onClick={this.checkOut} 
                                className={'modal-btn'}>
                                       Checkout
                                </CustomButton>
                            </Col>
                            </Row> 
                        </Modal.Footer>
                    </Modal.Dialog> 
                </ModalWrap> 
            </div>
          );
    }
  
}

const mapStateToProps = state => {
    return {
        cartProducts : state.CartReducer.cartProducts,
        user : state.AuthReducer.userData,
        isLoading : state.CartReducer.isLoading
    };
}

export default connect(mapStateToProps)(Cart);
