import React, { Component } from "react";
import { connect } from "react-redux";
import Page from "../../Components/HOC/Page";
import { Tabs, Spin } from "antd";
import "./Chat.scss";
import Moment from "moment";
import { AppConstants } from "../../Shared/Constants";
import NoMsg from "../../Components/Molecule/NoMsg";
import Message from "../../Components/Molecule/Message";
import OpenMsg from "../../Components/Molecule/OpenMsg";
import { RouteConfig } from "../../Config/RouteConfig";
import { Redirect } from "react-router-dom";
import CustomModel from "../../Components/Atom/CustomModel";
import Database from "../../Firebase/DataFirebase";
import APIManager from "../../APIManager";
import { message } from "antd";
import firebase from "firebase/app";
import TagManager from "react-gtm-module";

class Chat extends Component {
  state = {
    selectedMessage: null,
    id: null,
    selectedNotification: null,
    loading: false,
    activeKey: "1",
  };

  // constructor(props) {
  //     super(props)
  //     let id =
  // }

  componentDidMount() {
    let { id } = this.props.match.params;
    this.setState({
      ...this.state,
      id: id,
    });
  }

  componentDidUpdate(prevProps) {
    if (this.state.id) {
      this.getSeletedMessage();
    }
  }
  toggleLoading() {
    this.setState({
      ...this.state,
      loading: !this.state.loading,
    });
  }

  messageListClicked = (event) => {
    if ("notificationType" in event) {
      if (event.notificationType === 1 && event.isRead === false) {
        this.setState({
          ...this.state,
          selectedNotification: event,
        });
      } else if (event.notificationType === 2 && event.isRead === false) {
        Database.readNotification(event.id, this.props.user.uid);
      }
    } else {
      this.setState({
        ...this.state,
        selectedMessage: event,
      });
    }
  };

  removeMessageListClicked = (event) => {
    this.setState({
      ...this.state,
      selectedMessage: null,
    });
  };

  acceptNotification = () => {
    this.toggleLoading();
    var notification = this.state.selectedNotification;
    if (notification.startTimeStamp && notification.endTimeStamp) {
      Database.checkProductAvailable(
        notification.startTimeStamp,
        notification.endTimeStamp,
        notification.productId
      ).then((isAvailable) => {
        if (!isAvailable) {
          if (notification.isCashOnDelivery) {
            this.sendAcceptNotification(null);
          } else {
            this.callPayWebservice(notification)
              .then((result) => {
                if (result.data) {
                  if (result.data.success) {
                    this.sendAcceptNotification(result.data.charge);
                  } else {
                    message.error(result.data.message);
                  }
                }
              })
              .catch((error) => {
                this.toggleLoading();
              });
          }
        } else {
          this.toggleLoading();
          message.error("Sorry Product is not available for seleted dates");
        }
      });
    } else {
      this.toggleLoading();
      message.error("try latest database");
    }
  };

  callPayWebservice(notification) {
    return new Promise((resolve, reject) => {
      var buyerUser = notification.user;
      let currentUser = this.props.user;
      // buyerUser.stripeID = 'cus_EndYgUCxWYnB2O';
      // currentUser.accountNumber = 'acct_1EhVIrDf1VdMgXl7';
      if (!buyerUser.customer_id) {
        reject();
        message.error("User dont have billing account");
      } else if (
        !currentUser.accountNumber &&
        !currentUser.accountNumberVerified
      ) {
        reject();
        message.error("Update Account details first");
      } else if (
        currentUser.accountNumber.length === 0 &&
        currentUser.accountNumberVerified === false
      ) {
        reject();
        message.error("Update Account details second");
      } else {
        let finalAmount = parseFloat(notification.finalPrice) * 100;
        let param = {
          customer: buyerUser.customer_id,
          amount: Math.floor(finalAmount),
          currency: "usd",
          description: `${notification.product.name} ${notification.productId}`,
          transfer_data: {
            amount: Math.floor(finalAmount * 0.85),
            destination: currentUser.accountNumber,
          },
        };

        APIManager.payAmount(param)
          .then((result) => resolve(result))
          .catch((error) => reject(error));
      }
    });
  }

  sendAcceptNotification(charge) {
    // return new Promise((resolve, reject) => {

    let Paycharge = {};
    if (charge) {
      Paycharge = {
        id: charge.id,
        amount: charge.amount,
        receipt: charge.receipt_url,
        status: charge.status,
      };
    }
    let notification = this.state.selectedNotification;
    let acceptNotification = {
      ...notification,
      message: "",
      userName: "Request Accepetd",
      receiverID: notification.senderID,
      productId: notification.productId,
      notificationType: 2,
      charge: Paycharge,
    };

    acceptNotification.notificationType = 2;
    acceptNotification.isRead = false;
    acceptNotification.messageTitle = `Your Request Accepted by ${
      this.props.user.first_Name
        ? this.props.user.first_Name
        : this.props.user.name
    }`;
    delete acceptNotification.product;
    delete acceptNotification.user;
    Database.updateNotification(acceptNotification).then(() => {
      Database.readNotification(notification.id, this.props.user.uid).then(
        () => {
          this.toggleLoading();
          this.moveToChat();
        }
      );
    });

    // })
  }

  rejectNotification = () => {
    var notification = this.state.selectedNotification;
    let acceptNotification = {
      ...notification,
      message: "",
      userName: "Request Rejected",
      receiverID: notification.senderID,
      productId: notification.productId,
      notificationType: 2,
      messageTitle: `Your Request canceled by ${
        this.props.user.first_Name
          ? this.props.user.first_Name
          : this.props.user.name
      }`,
      isRead: false,
    };
    delete acceptNotification.product;
    delete acceptNotification.user;
    Database.updateNotification(acceptNotification).then(() => {
      Database.readNotification(notification.id, this.props.user.uid).then(
        () => {
          this.setState({
            ...this.state,
            selectedNotification: null,
          });
        }
      );
    });
  };

  moveToChat() {
    let notification = this.state.selectedNotification;
    let { id, name } = notification.product;
    let { uid, first_Name } = this.props.user || {};

    if (uid !== notification.senderID) {
      let userIdArray = [uid, notification.senderID];
      userIdArray.sort();
      let userIDs = userIdArray.join("_");
      let channelID = id + "_" + userIDs;
      let details = {
        name: name,
        UserToChat: notification.senderID,
        currentUser: uid,
        productID: id,
      };
      this.toggleLoading();
      Database.firebaseCreateChannel(true, channelID, details)
        .then(() => {
          let messageItem = {
            senderId: uid,
            senderName: first_Name ? first_Name : this.props.userData.name,
            text: "Request Accepted",
            time: firebase.database.ServerValue.TIMESTAMP,
            messageType: 1,
          };
          Database.updateChatMessage(
            channelID,
            "Request accepted",
            messageItem
          ).then(() => {
            this.setState({
              ...this.state,
              activeKey: "1",
              id: channelID,
              selectedNotification: null,
              loading: false,
            });
          });
        })
        .catch(() => this.toggleLoading());
    } else {
      message.warning("Cannot message to yourself");
    }
  }

  getSeletedMessage() {
    let seletedMessageArray = this.props.list.filter((message) => {
      return message.id === this.state.id;
    });
    if (seletedMessageArray.length > 0) {
      this.setState({
        ...this.state,
        id: null,
        selectedMessage: seletedMessageArray[0],
      });
    }
  }

  render() {
    let tagManagerArgs = {
      dataLayer: {
        page: "Landing",
      },
      dataLayerName: "PageDataLayer",
    };

    TagManager.dataLayer(tagManagerArgs);
    const { TabPane } = Tabs;
    let page = null;
    let notificationList = null;
    if (!this.props.user) {
      page = <Redirect to={RouteConfig.LANDING} />;
    } else if (this.state.selectedMessage) {
      page = (
        <OpenMsg
          backClicked={this.removeMessageListClicked}
          message={this.state.selectedMessage}
        />
      );
    } else {
      if (this.props.list.length !== 0) {
        page = (
          <Message
            isMessage={true}
            list={this.props.list}
            messageClick={this.messageListClicked}
          />
        );
      } else {
        page = <NoMsg message="No messages yet" />;
      }
      if (this.props.notification.length !== 0) {
        notificationList = (
          <Message
            isMessage={false}
            list={this.props.notification}
            messageClick={this.messageListClicked}
          />
        );
      } else {
        notificationList = <NoMsg message="No notification yet" />;
      }
    }

    return (
      <div className={"chat-page"}>
        {this.state.selectedNotification && !this.state.loading ? (
          <CustomModel
            handleOk={this.acceptNotification}
            handleCancel={this.rejectNotification}
            notification={this.state.selectedNotification}
            crossClickes={() => {
              this.setState({
                ...this.state,
                selectedNotification: null,
              });
            }}
          ></CustomModel>
        ) : null}
        <Page
          className="chat"
          containerClassName={"chat-container"}
          noSection
          noFooter
        >
          <Spin
            style={{ width: "100%", zIndex: 1000 }}
            spinning={this.state.loading}
          >
            <div className="heading-Buttons">
              <Tabs
                defaultActiveKey="1"
                style={{ marginBottom: "4rem" }}
                activeKey={this.state.activeKey}
                onChange={(key) => {
                  this.setState({
                    ...this.state,
                    activeKey: key,
                  });
                }}
              >
                <TabPane tab="Chat" key="1">
                  <div>{page}</div>
                </TabPane>
                <TabPane tab="Notification" key="2">
                  {notificationList}
                </TabPane>
              </Tabs>
            </div>
          </Spin>
        </Page>
        {/* <div className={"footer-bottom"}>
          <p>
            &copy; {Moment().year()} {AppConstants.APP_NAME} a Tanbel Inc.
            Product. Tanbel Inc. All rights reserved.
          </p>
        </div> */}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    list: state.ChatReducer.list,
    user: state.AuthReducer.userData,
    notification: state.ChatReducer.notifications,
  };
};

export default connect(mapStateToProps)(Chat);
