import React, { PureComponent } from "react";
import "./styles.scss";
import { Row, message } from "antd";
import ModalWrap from "../../Atom/Modal";
import { Button, Modal } from "react-bootstrap";
import CustomButton from "../../Atom/CustomButton";
import FilterCalender from "../../Cell/FilterCalender";
import { DateUtils } from "react-day-picker";
import { connect } from "react-redux";
import DataFirebase from "../../../Firebase/DataFirebase";
import { RouteConfig } from "../../../Config/RouteConfig";
import { Redirect } from "react-router-dom";
import { bookProductNow } from "../../../Redux/Actions/CartActions";
import Moment from "moment";
import RouterService from "../../../Services/RouterService";
import ModalReset from "../ModalReset";

class CartDate extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isRedirect: false,
      from: undefined,
      range: undefined,
      to: undefined,
      isClose: true,
      isProductCart: false,
      startTime: 8,
      endTime: 8,
      disabled: true,
      modalReset: false,
      days: 0,
    };
  }

  handleDayClick = (day) => {
    let currentDate = new Date();
    if (currentDate.getTime() <= day.getTime()) {
      const range = DateUtils.addDayToRange(day, this.state);
      this.setState(range);
      this.setState({ disabled: false });
    }
  };

  handleResetClick = () => {
    this.setState(this.getInitialState());
  };

  dateApplyClicked = (purchaserId) => {
    this.setState({
      selectedIndex: null,
      isDateFilterApplied: true,
      isProductCart: true,
      isRedirect: true,
    });
    let startDate = Moment(this.state.from).startOf("day");
    let endDate = this.state.to
      ? Moment(this.state.to).startOf("day")
      : Moment(this.state.from).startOf("day");
    // Filter Data According to this
    let days = startDate.diff(endDate, "days");
    if (days === 0 && this.state.endTime === 0 && this.state.startTime === 0) {
      endDate = Moment(this.state.from).endOf("day");
    }
    let isBooknow = false;
    let finalPrice = this.calculateFinalPrice();
    this.addToCart(days, finalPrice, isBooknow, purchaserId);
  };

  calculateFinalPrice = () => {
    //days
    let { product } = this.props;
    let startDate = Moment(this.state.from).startOf("day");
    let endDate = this.state.to
      ? Moment(this.state.to).startOf("day")
      : Moment(this.state.from).startOf("day");
    let days = Math.abs(endDate.diff(startDate, "days"));
    //Hours
    var hours = endDate
      .add(this.state.endTime, "hours")
      .diff(startDate.add(this.state.startTime, "hours"), "hours");
    //Weeks
    let weeks = Math.abs(endDate.diff(startDate, "weeks"));
    let daysLeft = days % 7;

    let finalPrice = 0;
    // weekly price
    if (days === 0) {
      if (hours <= 0 || hours > 4) {
        finalPrice = product.weeklyRate / 7;
        this.setState({
          days: 1,
        });
      } else {
        finalPrice = product.hourlyRate * hours;
      }
    } else if (days === 2) {
      let hours = Math.abs(endDate.diff(startDate, "hours"));
      if (hours < 24) {
        finalPrice = product.weeklyRate / 7;
        this.setState({
          days: 1,
        });
      } else {
        finalPrice = (product.weeklyRate / 7) * days;
        this.setState({
          days: days,
        });
      }
    } else if (days < 5) {
      let hours = Math.abs(endDate.diff(startDate, "hours"));
      let day = Math.ceil(hours / 24);
      finalPrice = (product.weeklyRate / 7) * day;
      this.setState({
        days: day,
      });
    } else {
      if (weeks === 0) {
        finalPrice = product.weeklyRate;
      } else {
        if (daysLeft >= 5) {
          weeks = weeks + 1;
          finalPrice = product.weeklyRate * weeks;
        } else {
          let weeklyPrice = product.weeklyRate * weeks;
          let daysPrice = (product.weeklyRate / 7) * daysLeft;
          finalPrice = weeklyPrice + daysPrice;
        }
      }
    }
    let tax = finalPrice * 0.15;
    finalPrice = finalPrice + tax;
    return finalPrice;
  };

  addToCart = (days, finalPrice, isBooknow, purchaserId) => {
    let startDate = Moment(this.state.from).startOf("day");
    let endDate = this.state.to
      ? Moment(this.state.to).startOf("day")
      : Moment(this.state.from).startOf("day");
    let cartProductData = {
      endDate: endDate.format("MMM DD, YYYY"),
      endTime: this.state.endTime,
      endTimeStamp: endDate.add(this.state.endTime, "hours").unix(),
      finalPrice: `${finalPrice}`,
      isAvailable: true,
      isPickUp: false,
      isRead: false,
      message: "",
      messageTitle: `${
        this.props.user.name ? this.props.user.name : this.props.user.first_Name
      } + 'has send a request to you'`,
      notificationType: 1,
      productId: this.props.product.id,
      receiverID: this.props.product.ownerData.uid,
      senderID: this.props.user.uid,
      startDate: startDate.format("MMM DD, YYYY"),
      startTime: this.state.startTime,
      startTimeStamp: startDate.add(this.state.startTime, "hours").unix(),
      time: "",
      totalDays: Math.abs(this.state.days === 0 ? 1 : this.state.days),
      userName: `${
        this.props.user.name ? this.props.user.name : this.props.user.first_Name
      }`,
      //
    };
    if (cartProductData.senderId === cartProductData.receiverID) {
      message.info("Can Not Purchase Your Own product");
    } else if (this.props.cartProducts.length !== 0) {
      if (
        this.props.cartProducts[0].product.userId !== cartProductData.receiverID
      ) {
        message.info("Are You sure you want to reset");
        this.setState({
          modalReset: true,
          isRedirect: false,
          cartProductData: cartProductData,
        });
      } else {
        DataFirebase.addedToCart(
          cartProductData,
          this.props.user.uid,
          isBooknow
        ).then(() => {
          if (!this.state.isRedirect) {
            RouterService.pushRoute(RouteConfig.CHECKOUT);
          }
        });
      }
    } else {
      DataFirebase.addedToCart(
        cartProductData,
        this.props.user.uid,
        isBooknow
      ).then(() => {
        if (!this.state.isRedirect) {
          RouterService.pushRoute(RouteConfig.CHECKOUT);
        }
      });
    }
  };

  onBookNow = (purchaserId) => {
    this.setState({
      selectedIndex: null,
      isDateFilterApplied: true,
      isProductCart: true,
      isRedirect: false,
    });
    let startDate = Moment(this.state.from).startOf("day");
    let endDate = this.state.to
      ? Moment(this.state.to).startOf("day")
      : Moment(this.state.from).startOf("day");
    // Filter Data According to this
    let days = endDate.diff(startDate, "days");
    if (days === 0 && this.state.endTime === 0 && this.state.startTime === 0) {
      endDate = Moment(this.state.from).endOf("day");
    }
    let isBooknow = true;
    let finalPrice = this.calculateFinalPrice();
    this.addToCart(days, finalPrice, isBooknow, purchaserId);
  };

  render() {
    let cartProducts = this.props.cartProducts;
    let purchaserId = "";
    if (cartProducts.length !== 0) {
      purchaserId = cartProducts[0].receiverId;
    }

    return (
      // <Modal show={this.state.isClose} onHide={this.props.close}>
      //   <Modal.Header closeButton>
      //     <Modal.Title>Modal heading</Modal.Title>
      //   </Modal.Header>
      //   <Modal.Body>Woohoo, you're reading this text in a modal!</Modal.Body>
      //   <Modal.Footer>
      //     <Button variant="secondary">
      //       Close
      //     </Button>
      //     <Button variant="primary">
      //       Save Changes
      //     </Button>
      //   </Modal.Footer>
      // </Modal>
      <div className={"cartDate"}>
        <ModalWrap
          isClose={this.state.isClose}
          isCloseClicked={this.props.close}
        >
          {this.state.isRedirect ? <Redirect to={RouteConfig.LANDING} /> : null}
          {this.state.modalReset ? (
            <ModalReset
              cartProductData={this.state.cartProductData}
              isCloseClicked={this.props.close}
              isBooknow={this.state.isBooknow}
            />
          ) : (
            <Modal.Dialog>
              <Modal.Header className={"modal-head"}>
                <Modal.Title>
                  <h6>Choosing Date</h6>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body className={"modal-body"}>
                <Row>
                  <FilterCalender
                    isEditable={true}
                    from={this.state.from}
                    onChangeStartTime={(event) => {
                      this.setState({
                        ...this.state,
                        startTime: event,
                      });
                    }}
                    onChangeEndTime={(event) => {
                      this.setState({
                        ...this.state,
                        endTime: event,
                      });
                    }}
                    to={this.state.to}
                    range={this.state.range}
                    handleDayClick={this.handleDayClick}
                    handleResetClick={this.handleResetClick}
                    clearClicked={this.handleResetClick}
                    applyClicked={this.dateApplyClicked}
                  />
                </Row>
              </Modal.Body>
              <Modal.Footer>
                <Row>
                  {this.props.isBooknow ? (
                    <CustomButton
                      onClick={this.onBookNow.bind(this, purchaserId)}
                      disabled={this.state.disabled}
                      className={"modal-btn"}
                    >
                      Book Now
                    </CustomButton>
                  ) : (
                    <CustomButton
                      onClick={this.dateApplyClicked.bind(this, purchaserId)}
                      disabled={this.state.disabled}
                      className={"modal-btn"}
                    >
                      Add To Cart
                    </CustomButton>
                  )}
                </Row>
              </Modal.Footer>
            </Modal.Dialog>
          )}
        </ModalWrap>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    user: state.AuthReducer.userData,
    cartProducts: state.CartReducer.cartProducts,
  };
};

const mapDispatchToProps = (dispatch) => ({
  bookProductNow: (product) => {
    dispatch(bookProductNow(product));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(CartDate);
