import React from 'react';
import './styles.scss';

const CheckoutCart = ({
    products = [],
    shippingPrice = 0
}) => {
    var subtotal = 0
    var taxAmount = 0
    return (
        <div className={'checkout-cart'}>
            <h2 className={'cart-title'}>Shopping Cart</h2>
            <hr />
            <div className={'cart-products'}>
                {products.map((product, index) => {
                    let { name, images, isSell } = product.product;
                    let { downloadURL: image } = (images || [])[0] || {};
                    subtotal = subtotal + (isSell ? Number(product.finalPrice) : (Number(product.finalPrice)/1.15) )
                    taxAmount = isSell ? taxAmount : (taxAmount + (Number(product.finalPrice) - (Number(product.finalPrice)/1.15)))
                    return (
                        <div key={index} className={'cart-product'}>
                            <img alt={'product'} src={image} className={'product-image'} />
                            <div className={'product-details'}>
                                <p className={'product-name'}>{name}</p>
                                <span className={'product-price'}>$ {Number(product.finalPrice).toFixed(2)}</span>
                            </div>
                        </div>
                    );
                })}
            </div>
            <hr />
            <table className={'price-details'}>
                <tbody>
                    <tr>
                        <td>Subtotal</td>
                        <td>$ {subtotal.toFixed(2)}</td>
                    </tr>
                    <tr>
                        <td>Service Charge</td> 
                        <td>$ {taxAmount.toFixed(2)}</td>
                    </tr>
                </tbody>
            </table>
            <hr />
            <table className={'total-price'}>
                <tbody>
                    <tr>
                        <td>Total</td>
                        <td>$ {(subtotal + taxAmount).toFixed(2)}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
}

export default CheckoutCart;
