import React, { PureComponent } from 'react';
import './styles.scss';

import SectionWrapper from '../../SectionWrapper';
import { sections } from '../../../../Config/Placeholder';
import { Icons } from '../../../../Shared/Assets';
import ImageIcon from '../../../Atom/ImageIcon';

const services = [
    {
        icon: Icons.serviceHome,
        title: 'One Platform',
        description: 'Make money on the that lawnmower that you don’t need for next few days or rent a karaoke machine for a night when your old friends are coming over! All in one platform'

    },
    {
        icon: Icons.serviceWallet,
        title: 'Make and Save Money',
        description: 'Earning some extra income on things that are collecting dust in your garage is like a cherry on top on your favorite ice cream.',
        right: true
    },
    {
        icon: Icons.serviceLeaf,
        title: 'Go Green',
        description: 'As we share this beautiful world together, why not share the responsibility of keeping this world beautiful through proper utilization of our resources.'

    }
];

export default class OurServices extends PureComponent {
    renderService({ icon, title, description, right }, index) {
        return (
            <div key={index} className={`service ${right ? 'right' : ''}`}>
                <ImageIcon
                    className={'service-icon'}
                    src={icon}
                    size={50}
                />
                <h2 className={'title'}>{title}</h2>
                <p className={'description'}>{description}</p>
            </div>
        );
    }

    render() {
        return (
            <SectionWrapper
                id={sections.services.id}
            >
                <div className={'services-container'}>
                    <div className={'name-wrapper'}>
                        <h2>{sections.services.name}</h2>
                    </div>
                    <div className={'service-description'}>
                        {services.map((service, index) => {
                            return this.renderService(service, index);
                        })}
                    </div>
                </div>
            </SectionWrapper>
        );
    }
}
