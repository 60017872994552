import Firebase from "firebase/app";

let config;
// if (process.env.NODE_ENV === "development") {
// config = {
//   apiKey: "AIzaSyAlNrBTNREIrwxMctTUpTPKsJzlybGCbXU",
//   authDomain: "wicycle-9e525.firebaseapp.com",
//   databaseURL: "https://wicycle-9e525.firebaseio.com",
//   projectId: "wicycle-9e525",
//   storageBucket: "wicycle-9e525.appspot.com",
//   messagingSenderId: "84454061413",
// };
// } else {
config = {
  apiKey: "AIzaSyBXUKXS0Qnf2aiCfaDBzz2MTgEsPPKrOt8",
  authDomain: "wecycleprod.firebaseapp.com",
  databaseURL: "https://wecycleprod.firebaseio.com",
  projectId: "wecycleprod",
  storageBucket: "wecycleprod.appspot.com",
  messagingSenderId: "1073845611744",
};
// }

export default Firebase.initializeApp(config);
