import React from 'react';
import './styles.scss';
import CustomButton from '../../Atom/CustomButton';
import { Form, Card, Row, Icon, Dropdown, Menu, } from 'antd';
import { CardNumberElement, CardExpiryElement, CardCVCElement} from 'react-stripe-elements';
import {injectStripe} from 'react-stripe-elements';
import { Radio , Spin, Collapse} from 'antd';


import { Tabs} from 'antd';
const { TabPane } = Tabs;


const createOptions = () => {
    return {
      style: {
        borderRadius: '5px',
        borderStyle: 'solid',
        borderWidth: 1,
        base: {
          fontSize: '16px',
          color: '#424770',
          letterSpacing: '0.025em',
          '::placeholder': {
            color: '#aab7c4',
          },
        },
        invalid: {
          color: '#c23d4b',
        },
      },
    };
  };
                                
const BillingForm = ({
    showLoader,
    form,
    cards,
    slectedCard,
    setForm = () => { },
    updateCard = () => { },
    defaultCardChanged = () => { },
    isCashOnDeliveryClicked = () => {},
    handleMenuClick,
    cardElement,
    dateElement,
    cvvElement,
    isCashOnDeliveryAvaibale
}) => {
    // let { getFieldDecorator } = form;
    const { Panel } = Collapse;
    const menu = (key) => (
        <Menu onClick={handleMenuClick}>
          <Menu.Item key={key}>
            Delete
            </Menu.Item>
        </Menu>
      );
      
    return (
        
        <div
            className={'billing-info-form'}
        >
        <Tabs 
        defaultActiveKey="1" 
        className="StyleDelvryAddrs StyleBilingSteps" 
        onTabClick={key => {
            isCashOnDeliveryClicked(key === "2")
        }}
        centered type={'card'}>
                    <TabPane tab="Card" key="1">
                    <h4>Cards</h4>
                        {/* <Row></Row> */}
        { showLoader ? null  :  <Spin className={'center-loader'}/>}
        <div>
                <Collapse 
                bordered={false}
                // accordion
                defaultActiveKey={["1"]} 
                >
                {cards.length > 0 &&
                <Panel header="Select Card" key="1">
                <Radio.Group  value={slectedCard} onChange={(e) => {                    
                    defaultCardChanged(e.target.value)
                }}>
                    {cards.map((card, index)=>{
                        return <Row className={'cards-row'} key={index}> 
                            <div className={'card-Selection'}>
                            <Radio value={index}></Radio>
                            <h5>****{card.last4}</h5>
                            <h5>{card.exp_month}/{card.exp_year}</h5>
                            <div className={'more-option'}>
                                <h5>{card.brand}</h5>
                                <Dropdown overlay={menu(`${index}`)} className={'more-option-button'} > 
                                <button className={'drop-down'}>
                                    <Icon type="ellipsis" />
                                </button>
                                </Dropdown>
                            </div>
                            </div>
                        </Row>   
                    })}
                </Radio.Group>
                </Panel>
                }
                {/* <BillingSteps 


                /> */}
                
                <Panel header="Add Card" key="2">
                <Card className={'card-view'}>
                    <Form>
                        <div className={'field-row'}>
                            <div className={'field-col'}>
                                <Form.Item
                                    label={'Card Number'}
                                >
                                <CardNumberElement
                                onReady={cardElement}
                                    {...createOptions()}
                                    />
                                </Form.Item>
                            </div>
                        </div>
                        <div className={'field-row'}>
                            
                            <div className={'field-row field-col half-col'}>
                                <div className={'field-col half-col'}>
                                    <Form.Item
                                        label={'Expiry Date'}
                                    >
                                    <CardExpiryElement
                                    onReady={dateElement}
                                    {...createOptions()}
                                />
                                    </Form.Item>
                                </div>
                                <div className={'field-col half-col'}>
                                    <Form.Item
                                        label={'CVV'}
                                    >
                                        <CardCVCElement 
                                        onReady={cvvElement}
                                        {...createOptions()}  />
                                    </Form.Item>
                                </div>
                            </div>
                        </div>
                    </Form>
                </Card>
                <div className={'save-button'}>
                 { showLoader ? <CustomButton className={'save-button-btn'} onClick={()=> {updateCard()}}>Save Card</CustomButton> : <Spin className={'center-loader'}/> }
                </div>
                </Panel>
            </Collapse>
            
            
            </div>
            </TabPane>
            <TabPane 
            tab="Cash" 
            key="2" 
            disabled={isCashOnDeliveryAvaibale}
            >
                    <h4>Cash</h4>
                        <p>Confirm pick up location with sender</p>
                </TabPane>
            </Tabs>

        </div>
    );
}

// export default injectStripe(BillingForm)
export default Form.create()(injectStripe(BillingForm))