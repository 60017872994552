import React from "react";
import FilterBottom from "../CustomFilterBottom";
import "./styles.scss";

const conditionFilter = ({
  clearClicked,
  applyClicked,
  conditions,
  selectedItems = [],
  itemsSelected,
}) => {
  let items = selectedItems;
  return (
    <div className={"container-condition"}>
      <div className={"wrapper"}>
        {conditions?.map((condition, index) => {
              return (
                <span
                  key={index}
                  className={`item ${
                    items?.includes(condition) ? "selected-condition" : ""
                  }`}
                  onClick={() => {
                    if (items?.includes(condition)) {
                      items = items.filter((cond) => cond.id !== condition.id);
                    } else {
                      items?.push(condition);
                    }
                    itemsSelected(items);
                  }}
                >
                  {condition.name}
                </span>
              );
            })}
      </div>
      <FilterBottom clearClicked={clearClicked} applyClicked={applyClicked} />
    </div>
  );
};

export default conditionFilter;
