import React from 'react';
import { Row, Radio , Col } from 'antd';
import { Menu, Dropdown } from 'antd';
import {GrMoreVertical} from 'react-icons/gr';
import Database from '../../../Firebase/DataFirebase'

import '../CustomAddress/style.scss'
// import { GrMoreVertical } from "grommet-icons";

const CustomAddress = ({
      address,
      user,
      reloadAddress,
      isSelected = false
}) => {

    const menu = (
         <Menu>
             <Menu.Item>
                 <a onClick={()=>{
                     Database.deleteShippingAddress(user.uid, address.id)
                     .then(success=> {
                        reloadAddress()
                     })
                  }} href="/checkout">
                     Delete
                 </a>
             </Menu.Item>
         </Menu>
     );

    return (
        <Row>
            <Col lg={12} md={12} className={'d-flex StyleCustmAddres align-items-center justify-content-between'}>
               <Radio 
               checked={isSelected}
               onClick={()=> {
                   Database.updateDefaultAddress(user.uid, address.id)
               }}
               className={'d-flex flex-row align-items-center'}>
                    <address className="CustomAddrsNames">
                     <ul>
                        <li><h5>{address.name}</h5></li>
                        <li><h6>{`${address.houseNumber} ${address.area} ${address.town} ${address.state}`}</h6></li>
                        <li><h6>{address.phone}</h6></li>
                     </ul>
                    </address>
               </Radio>
                <Dropdown overlay={()=>menu}>
                    <a className="ant-dropdown-link" onClick={e => {
                        e.preventDefault()
                        
                    }}>
                      <GrMoreVertical/>
                    </a>
                </Dropdown>
            </Col>
        </Row>
    );
}

export default CustomAddress;