export const FilterActionKeys = {
    SET_PRICE_RANGE : 'SET_PRICE_RANGE',
    CLEAR_PRICE_RANGE : 'CLEAR_PRICE_RANGE', 
    SET_CONDITION_FILTER : 'SET_CONDITION_FILTER',
    CLEAR_CONDITION_FILTER: 'CLEAR_CONDITION_FILTER',
    SET_CATEGORY_FILTER : 'SET_CATEGORY_FILTER',
    CLEAR_CATEGORY_FILTER: 'CLEAR_CATEGORY_FILTER',
    SET_USER_RATING_FILTER: 'SET_USER_RATING_FILTER',
    CLEAR_USER_RATING_FILTER: 'CLEAR_USER_RATING_FILTER',
    SET_PRODUCT_RATING_FILTER: 'SET_PRODUCT_RATING_FILTER',
    CLEAR_PRODUCT_RATING_FILTER: 'CLEAR_PRODUCT_RATING_FILTER',
}

export const setPriceRange = range => {
    return {
        type : FilterActionKeys.SET_PRICE_RANGE,
        range
    }
}

export const clearPriceRange = () => {
    return {
        type : FilterActionKeys.CLEAR_PRICE_RANGE,
    }
}

export const setConditionFilter = (conditions) => {
    return {
        type : FilterActionKeys.SET_CONDITION_FILTER,
        conditions
    }
}

export const clearConditionFilter = () => {
    return {
        type : FilterActionKeys.CLEAR_CONDITION_FILTER,
    }
}

export const setCategoryFilter = (categories) => {
    return {
        type : FilterActionKeys.SET_CATEGORY_FILTER,
        categories
    }
}

export const clearCategoryFilter = () => {
    return {
        type : FilterActionKeys.CLEAR_CATEGORY_FILTER,
    }
}

export const setUserRatingFilter = (rate) => {
    return {
        type : FilterActionKeys.SET_USER_RATING_FILTER,
        rate
    }
}

export const clearRatingFilter = () => {
    return {
        type : FilterActionKeys.CLEAR_USER_RATING_FILTER
    }
}

export const setProductRatingFilter = (rate) => {
    return {
        type : FilterActionKeys.SET_PRODUCT_RATING_FILTER,
        rate
    }
}

export const clearProductRatingFilter = () => {
    return {
        type : FilterActionKeys.CLEAR_PRODUCT_RATING_FILTER
    }
}