import React from 'react';
import './styles.scss';

import { Select } from 'antd';

const CustomSelect = React.forwardRef(({
    options = [],
    placeholder = 'Select',
    ...restProps
}, ref) => {
    return (
        <Select
            ref={ref}
            className={'custom-select'}
            placeholder={placeholder}
            {...restProps}
        >
            {options.map((option, index) => {
                let { value, label } = option;
                return (
                    <Select.Option key={index} value={value || option}>
                        {label || option}
                    </Select.Option>
                );
            })}
        </Select>
    );
});

export default CustomSelect
