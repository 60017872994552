import React, { PureComponent } from 'react';
import './styles.scss';

import { connect } from 'react-redux';
import CustomButton from '../../../Components/Atom/CustomButton';
import CustomInput from '../../../Components/Atom/CustomInput';
import CustomAlert from '../../../Components/Atom/CustomAlert';
import Spinner from '../../../Components/Atom/Spinner';
import SocialButtonGroup from '../../../Components/Molecule/SocialButtonGroup';
import {
    Nav
} from 'react-bootstrap';
import {
    Form
} from 'antd';
import AuthFirebase from '../../../Firebase/AuthFirebase';
import Rules from '../../../Services/Rules';
import { AuthTab } from '../../../Config';
import { showAuthModal } from '../../../Redux/Actions/AuthActions';
import { ValidationMessages } from '../../../Shared/Messages';
import RouterService from '../../../Services/RouterService';
import { RouteConfig } from '../../../Config/RouteConfig';

class Login extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            errorMessage: '',
            signingIn: false
        }
        this.checkLogin();
    }

    componentDidUpdate() {
        this.checkLogin();
    }

    checkLogin() {
        if (this.props.isLoggedIn) {
            this.props.showAuthModal(false);
        }
    }

    onLogin(event) {
        event.preventDefault();
        let { validateFields } = this.props.form;
        validateFields((error, values) => {
            if (!error) {
                window.scrollTo({ top: 0 });
                this.setState({
                    signingIn: true
                });
                let { email, password } = values;
                AuthFirebase.signInWithEmail(email.toLowerCase(), password)
                    .then(() => {
                        this.setState({
                            signingIn: false
                        });
                    })
                    .catch(({ message }) => {
                        this.setState({
                            signingIn: false,
                            errorMessage: message
                        });
                    });
            }
        });
    }

    onForgotPassword(event) {
        event.preventDefault();
        this.props.showAuthModal(true, AuthTab.FORGOT_PASSWORD.id);
    }

    termsaOfUse(event) {
        event.preventDefault();
        RouterService.pushRoute(`${RouteConfig.TERMS_OF_SERVICES}`);
        this.props.showAuthModal(false);
    }

    privacyPolicy(event) {
        event.preventDefault();
        RouterService.pushRoute(`${RouteConfig.PRIVACY_POLICY}`);
        this.props.showAuthModal(false);
    }

    render() {
        let { getFieldDecorator } = this.props.form;
        return (
            <div className={'login-page'}>
                <h4>Welcome to WeCycle</h4>
                <p>Enter your details below.</p>
                <Form onSubmit={this.onLogin.bind(this)}>
                    <CustomAlert
                        message={this.state.errorMessage}
                    />
                    <Form.Item>
                        {getFieldDecorator('email', {
                            rules: Rules.getEmailRules()
                        })(
                            <CustomInput
                                type={'email'}
                                placeholder={'Enter email'}
                                autoCorrect={'none'}
                                autoCapitalize={'none'}
                            />
                        )}
                    </Form.Item>
                    <Form.Item>
                        {getFieldDecorator('password', {
                            rules: [{ required: true, message: ValidationMessages.ENTER_PASSWORD }]
                        })(
                            <CustomInput
                                type={'password'}
                                placeholder={'Enter password'}
                                onChange={(event) => {
                                    this.setState({
                                        password: event.target.value
                                    });
                                }}
                            />
                        )}
                    </Form.Item>


                <h6 className={'terms'}>By Register or Sign in, you agre to Wecycle's <span onClick={this.termsaOfUse.bind(this)} className={'link-css'}> Terms of Use</span> and 
                 <span onClick={this.privacyPolicy.bind(this)} className={'link-css'}> Privacy Policy</span></h6>

                    <Form.Item className={'login-button-wrapper'}>
                        {this.state.signingIn ?
                            <Spinner />
                            :
                            <CustomButton
                                className={'login-button'}
                                onClick={this.onLogin.bind(this)}
                            >
                                Login
                            </CustomButton>
                        }
                    </Form.Item>
                    <Nav.Link
                        className={'forgot-password-text'}
                        onClick={this.onForgotPassword.bind(this)}
                    >
                        Forgot Password?
                    </Nav.Link>
                </Form>

                <SocialButtonGroup />

            </div>
        );
    }
}


const mapStateToProps = state => {
    return {
        isLoggedIn: state.AuthReducer.isLoggedIn
    };
}

export default connect(mapStateToProps, { showAuthModal })(Form.create()(Login));
