import React, { PureComponent } from 'react';
import './styles.scss';
import { Nav } from 'react-bootstrap';
import { Row, Col } from 'antd';
import Moment from 'moment';
import { LinkSections, SocialLinks } from '../../../Config/FooterConfig';
import { AppConstants } from '../../../Shared/Constants';
import { Images, Icons } from '../../../Shared/Assets';
import { iOSLink } from '../../../Config';
import RouterService from '../../../Services/RouterService';
import ImageIcon from '../../Atom/ImageIcon';

export class
    Footer extends PureComponent {
    renderSections(section, sectionIndex) {
        return (
            <Col
                key={sectionIndex}
                xs={24}
                sm={12}
                md={6}
            >
                <ul>
                    {section.map((linkData, linkIndex) => {
                        return (
                            <li key={linkIndex}>
                                <Nav.Link onClick={() => {
                                    // for FAQ link 
                                    linkData.label === "FAQ" ?
                                        window.open(linkData.link)
                                        :
                                        RouterService.pushRoute(linkData.link)
                                }}>
                                    {linkData.label}
                                </Nav.Link>
                            </li>
                        );
                    })}
                </ul>
            </Col>
        );
    }

    render() {
        return (
            <footer className="footer-outer">
                <div className={'footer-top justify-content-space-between'}>
                    <Row>
                        <Col>
                            <ImageIcon className={'name-title'} src={Icons.logo}></ImageIcon>
                        </Col>
                    </Row>
                    <Row className={'links-row'}>
                        {LinkSections.map(this.renderSections.bind(this))}
                        <Col
                            className={'download-section'}
                            xs={24}
                            sm={12}
                            md={6}
                        >
                            <ul>
                                <li>
                                    <h2 className={'download-text'}>Download</h2>
                                </li>
                                <li>
                                    iPhone and iPad versions.
                                </li>
                                <li>
                                    <a href={iOSLink} target={'blank'}>
                                        <img className={'appstore-button'} alt={'apple-button'} src={Images.appleButton} />
                                    </a>
                                </li>
                            </ul>
                        </Col>
                        <Col
                            xs={24}
                            sm={12}
                            md={6}
                        >
                            <ul className={'social-list'}>
                                {SocialLinks.map((LinkData, index) => {
                                    return (
                                        <li key={index}>
                                            <a href={LinkData.link} target={'blank'}>
                                                <LinkData.Icon className={'icon'} />
                                            </a>
                                        </li>
                                    );
                                })}
                            </ul>
                        </Col>
                    </Row>
                </div>
                <div className={'footer-bottom'}>
                    <p>
                        &copy; {Moment().year()} {AppConstants.APP_NAME} a Tanbel Inc. Product. Tanbel Inc. All rights reserved.
                    </p>
                </div>
            </footer>
        )
    }
}

export default Footer;
