import React from 'react';
import './styles.scss';

import ImageIcon from '../../Atom/ImageIcon';
import { sections } from '../../../Config/Placeholder';
import { Icons } from '../../../Shared/Assets';
import { iOSLink } from '../../../Config';

const CustomHeader = ({
    activeId,
    home = false
}) => {
    return (
        <div className={'placeholder-header'}>
            <div className={`custom-header ${home ? 'home' : ''}`}>
                {!!home &&
                    <a href={`#${sections.home.id}`}>
                        <h2 className={'title'}>WeCycle.</h2>
                    </a>
                }
                <ul className={'nav-link-list'}>
                    {Object.keys(sections).map((sectionKey, index) => {
                        let section = sections[sectionKey];
                        if (index > 0) {
                            return (
                                <a key={index} href={`#${section.id}`}>
                                    <li className={activeId === section.id ? 'active' : ''}>
                                        {section.name}
                                    </li>
                                </a>
                            );
                        }
                        return null;
                    })}
                </ul>
                {home ?
                    <div className={'ios-app-link'}>
                        <a href={iOSLink} target={'_blank'}>
                            <img alt={'ios-app'} src={Icons.iosGreenLink} />
                        </a>
                    </div>
                    :
                    <a href={`#${sections.home.id}`} className={'goto-top'}>
                        <ImageIcon
                            src={Icons.angleUp}
                            size={15}
                        />
                    </a>
                }
            </div >
        </div>
    );
}

export default CustomHeader;
