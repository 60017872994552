import React from "react";
import RBSpinner from "react-bootstrap/Spinner";

function Spinner({ small }) {
  return (
    <div style={{
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    }}>
      <RBSpinner
        size={small ? "sm" : ""}
        animation="border"
        role="status"
        variant="success"
      />
    </div>
  );
}

export default Spinner;
