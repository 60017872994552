import React from 'react'
import './style.scss';

import CustomButton from '../../Atom/CustomButton';

const index = ({
    className = '',
    heading,
    id,
    children,
    containClassName = '',
    onSeeMore
}) => {
    return (
        <div className={`label-wrapper ${className}`}>
            {!!heading &&
                <p id={id} className={'header'}> {heading} </p>
            }
            <div className={`label-container ${containClassName}`}>
                {children}
            </div>
            {onSeeMore &&
                // <div className={'footer'}>
                
                    <CustomButton
                        onClick={onSeeMore}
                    >
                        See More
                    </CustomButton>
                // </div>
            }
        </div>
    )
}

export default index
