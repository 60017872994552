import React, { PureComponent } from 'react';
import './styles.scss';
import { Form, Input, Button, message } from 'antd';
import APIManager from '../../../APIManager/index'

const { TextArea } = Input;

const formFiels = {
    name: '',
    email: '',
    textMessage: ''
}

export default class Contact extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            ...formFiels
        }
    }
// <img src="https://firebasestorage.googleapis.com/v0/b/wicycle-9e525.appspot.com/o/WebAssets%2Fhome.png?alt=media&amp;token=26fd5326-7b50-4da1-9111-de9412981fc3" alt="contact" width="418" height="309" /></div>
 getContactEmail = () => {
        return `
        <div>
        <div style="text-align: center;">
        <h2 style="font-size: 32px; text-align: center; color: #6cc788;">Contact</h2>
        <div style="font-size: 20px;">
        <p>Hi WeCycle,</p>
        <p>${this.state.name} have some queries regarding <span style="color: #6cc788;">WeCycle</span></p>
        <p>&nbsp;</p>
        <p>Message</p>
        <p>${this.state.textMessage}</p>
        <br/><br/>
        <p>Regards</p>
        <p>${this.state.name}</p>
        <p>${this.state.email}</p>
        <br/><br/> 
        </div>
        </div>
        `;
    }

//
    onSubmitForm(event) {
        event.preventDefault();
        let { name, email, textMessage } = this.state;
        if (name && email && textMessage) {
            this.toggleLoading();
            let detail = {
                "to": "welcome@wecycle.io, kb.tanvir@gmail.com",
                "subject" : "Contact",
                "html" : this.getContactEmail()
              };
            APIManager.sendMail(detail)
              .then((response)=> {
                message.success('Mail sent');
                this.toggleLoading();
              })
              .catch(()=> {
                  console.log('error');
                  this.toggleLoading();
              })
            // DataFirebase.saveContactMessage(this.state)
            //     .then(() => {
            //         this.toggleLoading();
            //         this.setState({ ...formFiels });
            //         notification.success({
            //             message: 'Message sent!',
            //             description: 'Thanks alot for writing us.'
            //         })
            //     })
            //     .catch(() => {
            //         this.toggleLoading();
            //         message.error('Error on sending mail');
            //     });

        }
    }

    toggleLoading() {
        this.setState(state => ({
            loading: !state.loading
        }));
    }

    render() {
        return (
            <div id={'contact-form'}>
                <Form className={'contact-form'} onSubmit={this.onSubmitForm.bind(this)}>
                    <Form.Item>
                        <h2>Get Early Access</h2>
                    </Form.Item>
                    <Form.Item>
                        <p>
                            Enter your name and email address to get early access.
                                </p>
                    </Form.Item>
                    <Form.Item>
                        <Input
                            required
                            placeholder={'Name'}
                            value={this.state.name}
                            onChange={({ target }) => this.setState({ name: target.value })}
                        />
                    </Form.Item>
                    <Form.Item>
                        <Input
                            required
                            type={'email'}
                            placeholder={'Email'}
                            value={this.state.email}
                            onChange={({ target }) => this.setState({ email: target.value })}
                        />
                    </Form.Item>
                    <Form.Item>
                        <TextArea
                            className={'message'}
                            placeholder={'Message'}
                            value={this.state.textMessage}
                            onChange={({ target }) => this.setState({ textMessage: target.value })}
                        />
                    </Form.Item>
                    <Form.Item>
                        <Button
                        style={{backgroundColor: 'white',color:'black'}}
                            htmlType={'submit'}
                            disabled={this.state.loading}
                            loading={this.state.loading}
                        >
                            Send
                        </Button>
                    </Form.Item>
                </Form>
            </div>
        );
    }
}
