export const CartActionTypes = {
    BOOK_NOW: 'BOOK_NOW',
    CLEAR_BOOK_NOW: 'CLEAR_BOOK_NOW',
    CLEAR_CART : 'CLEAR_CART',
    ADD_CART : 'ADD_CART',
    SHOW_LOADER : 'SHOW_LOADER'
}

export const bookProductNow = (product) => {
    return {
        type: CartActionTypes.BOOK_NOW,
        product
    };
}

export const clearBookNow = () => {
    return {
        type: CartActionTypes.CLEAR_BOOK_NOW
    };
}

export const addCart = (product) => {
    return {
        type: CartActionTypes.ADD_CART,
        product
    };
}

export const clearCart = (product) => {
    return {
        type: CartActionTypes.CLEAR_CART,
        product
    };
}

export const showLoader = (loading) => {
    return {
        type: CartActionTypes.SHOW_LOADER,
        loading
    };
}
