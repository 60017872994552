import React from 'react'
import { Helmet } from "react-helmet";
import { withRouter } from "react-router";
import {SEO} from '../../../Shared/SEO'

const MetaTags = ({ match, product }) => {
let data = SEO[match.path]
    if (product && Object.keys(product).length !== 0) {
        return <>
                <Helmet>
                    <title>{`${product.name ? product.name : 'WeCycle (Beta) Buy | Rent'} Rent | Buy`}</title>
                     <meta name="description" content={`${product.desc}. Best and lowest price ${product.hourlyRate}$ with ${product.condition} condition. Rent what you want and Lend what you don't` } />
                     <meta name="og:description" content={`${product.desc}. Best and lowest price ${product.hourlyRate}$ with ${product.condition} condition. Rent what you want and Lend what you don't` } />
                     <meta name="og:title" content={`${product.name} Rent | Buy`} />
                     <meta name="og:url" content={`https://wecycle.io/ + ${match.url}`} />
                     <meta name="og:image" content={product.images
                        ? product.images[0].downloadURL 
                        : 'https://firebasestorage.googleapis.com/v0/b/wicycle-9e525.appspot.com/o/WebAssets%2Fhome.png?alt=media&token=26fd5326-7b50-4da1-9111-de9412981fc3'} />
                </Helmet>
            </>
    }
    return <>
            <Helmet>
                <title>{data ? data.title : 'WeCycle (Beta) Buy | Rent'}</title>
                <meta name="description" content={data ? data.description : 'Rent what you want and Lend what you don\'t'} />
                <meta name="og:title" content={data ? data.title : 'WeCycle (Beta) Buy | Rent'} />
                <meta name="og:url" content={`https://wecycle.io/ + ${match.url}`} />
                <meta name="og:image" content={'https://firebasestorage.googleapis.com/v0/b/wicycle-9e525.appspot.com/o/WebAssets%2Fhome.png?alt=media&token=26fd5326-7b50-4da1-9111-de9412981fc3'} />
            </Helmet>
         </>
}

export default withRouter(MetaTags)