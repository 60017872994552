import React from 'react';
import { Slider } from 'antd';
import FilterBottom from '../CustomFilterBottom'
import './styles.scss'

const priceFilter = ({
    clearClicked,
    applyClicked,
    sliderChanged,
    
}) => {
    return (
        <div>
         <Slider className={'slider'}
            defaultValue={[0, 2000]}  
            min={0}
            max={2000}
            step={1}
            range
            onChange={(event)=>{
                sliderChanged(event)
            }}
            />
        <FilterBottom 
        clearClicked={clearClicked}
        applyClicked={applyClicked}
      />
      </div>
    )
}

export default priceFilter;