import React, { PureComponent } from 'react';
import './styles.scss';

import SectionWrapper from '../../SectionWrapper';
import { sections } from '../../../../Config/Placeholder';
import { Images } from '../../../../Shared/Assets';

export default class About extends PureComponent {
    render() {
        return (
            <SectionWrapper
                isHide={this.props.isHide}
                id={sections.about.id}
            >
                <div className={'about-container'} style={{ backgroundImage: `url(${Images.aboutPath})` }}>
                    <div className={'info'}>
                        <h2>
                            WeCycle is a peer-to-peer rental service in which you can rent and lend things among others in your community.
                        </h2>
                        <img alt={'about'} src={Images.about} />
                    </div>
                    <div className={'description'}>
                        <p>
                            Whether you’re renting a karaoke speaker to a garden hose, WeCycle allows you find whatever you want to rent, getting rid of the cost of paying for something in full, even if it's going to be used for a few days. Still, have that old bike? Lend it on WeCycle. Need to get rid of that disco ball? There’s a place for that on WeCycle. You can lend your old, unused stuff and make money doing it, which is beneficial for your wallet, your life, and the planet.
                            <br />
                            Our main goal is to help minimize the amount of waste we produce and ultimately make this world a better place to live in.
                        </p>
                    </div>
                </div>
            </SectionWrapper>
        );
    }
}
