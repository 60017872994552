import React, { Component } from "react";
import "./styles.scss";
import { connect } from "react-redux";
import DataFirebase from "../../Firebase/DataFirebase";
import Page from "../../Components/HOC/Page";
import { Spin, Row, Col, Rate, Button } from "antd";
import ProductActionBar from "../../Components/Molecule/ProductActionBar";
import GoogleMap from "../../Components/Molecule/GoogleMap";
import Carousel from "../../Components/Molecule/Carousel";
import RouterService from "../../Services/RouterService";
import { RouteConfig } from "../../Config/RouteConfig";
import { bookProductNow } from "../../Redux/Actions/CartActions";
import { showAuthModal } from "../../Redux/Actions/AuthActions";
import Floating from "../../Components/Atom/FloatingButton";
import { message } from "antd";
import PreviewActionBar from "../../Components/Molecule/PreviewActionBar";
import { Redirect } from "react-router-dom";
import ImageIcon from "../../Components/Atom/ImageIcon";
// import { Lightbox } from "react-modal-image";
import ImageViewer from "../../Components/Molecule/ImageViewer";
import "lightbox-react/style.css";
import CartDate from "../../Components/Molecule/CartDate";
import TagManager from "react-gtm-module";
import Utility from "../../Services/Utility";
import { Icons } from "../../Shared/Assets";
import ModalReset from "../../Components/Molecule/ModalReset";
import BasicModal from "../../Components/Atom/BasicModal";
import TextArea from "antd/lib/input/TextArea";

class ProductDetails extends Component {
  constructor(props) {
    super(props);
    let { productKey } = this.props.match.params;
    this.state = {
      photoIndex: 0,
      productDetails: {},
      fetched: productKey === "preview",
      loading: false,
      isOpen: false,
      isPreview: productKey === "preview",
      isProductAdded: false,
      isLoading: false,
      isCardSelected: false,
      imageURL: null,
      modalReset: false,
      isRedirect: false,
      isModalOpen: false,
      ratting: {
        rate: 0,
        review: "",
      },
      reviews: [],
    };
  }

  handleCancel = () => {
    this.setState({
      ...this.state,
      isModalOpen: false,
    });
  };

  showModal = () => {
    this.setState({
      ...this.state,
      isModalOpen: true,
    });
  };

  fetchReviews = async (pid) => {
    const allReview = await DataFirebase.getProductReview(pid);
    this.setState({
      ...this.state,
      reviews: allReview.reverse(),
    });
  };

  handleOk = () => {
    let { productKey } = this.props.match.params;

    this.setState({
      ...this.state,
      isModalOpen: false,
    });

    const data = {
      ...this.state.ratting,
      reviewer: {
        uid: this.props.userData.uid,
        name: this.props.userData.name,
        photo: this.props.userData.avatar,
      },
      timestamp: new Date().toISOString(),
      product: productKey,
    };
    DataFirebase.addProductReview(
      data,
      () => {
        this.setState({
          ...this.state,
          reviews: [data, ...this.state.reviews],
        });
      },
      () => {
        message.error("Something went wrong");
      }
    );
  };

  componentDidMount() {
    let { productKey } = this.props.match.params;
    if (productKey !== "preview") {
      this.toggleLoading();
      DataFirebase.getProduct(productKey)
        .then((product) => {
          this.toggleLoading();
          Utility.analyticsUpdates("Product_Detail", {
            id: product.id,
            product: product,
            userDetail: this.props.user,
          });
          this.setState({
            fetched: true,
            productDetails: product,
          });
        })
        .catch((error) => {
          this.toggleLoading();
          console.log(error);
        });
      this.fetchReviews(productKey);
    }
  }

  moveToChat() {
    if (this.props.isLoggedIn) {
      let { id, name, ownerData } = this.state.productDetails;
      let { isLoggedIn, userData } = this.props;
      let { uid } = userData || {};
      if (uid !== ownerData.uid) {
        let userIdArray = [uid, ownerData.uid];
        userIdArray.sort();
        let userIDs = userIdArray.join("_");
        let channelID = id + "_" + userIDs;
        let details = {
          name: name,
          UserToChat: ownerData.uid,
          currentUser: uid,
          productID: id,
        };
        this.toggleLoading();
        DataFirebase.firebaseCreateChannel(isLoggedIn, channelID, details)
          .then(() => {
            this.toggleLoading();
            RouterService.pushRoute(`${RouteConfig.CHAT}/${channelID}`);
          })
          .catch(() => this.toggleLoading());
      } else {
        message.warning("Cannot message to yourself");
      }
    } else {
      this.props.showAuthModal();
    }
  }

  toggleLoading() {
    this.setState((state) => ({
      loading: !state.loading,
    }));
  }

  onFavorite({ uid, id, isFavorite }) {
    let { isLoggedIn } = this.props;
    DataFirebase.favoriteProduct(isLoggedIn, uid, id, isFavorite);
  }

  addSellingProductToCart = (redirect) => {
    let startDate = "";
    let endDate = "";
    let {
      id,
      // condition,
      // name,
      // desc,
      hourlyRate,
      // weeklyRate,
      // isSell = false,
      // isDeliveryAvailable = false,
      // isVerified = false,
      // images = [],
      // l = [],
      ownerData,
    } = this.state.productDetails || {};
    let cartProductData = {
      // endDate: "",
      // endTime: "",
      // endTimeStamp: endDate.add(this.state.endTime, 'hours').unix(),
      finalPrice: `${hourlyRate}`,
      isAvailable: true,
      isPickUp: false,
      isRead: false,
      message: "",
      messageTitle: `${
        this.props.user.name ? this.props.user.name : this.props.user.first_Name
      } + 'has send a request to you'`,
      notificationType: 1,
      productId: id,
      receiverID: ownerData.uid,
      senderID: this.props.user.uid,
      // startDate: startDate.format('MMM DD, YYYY'),
      // startTime: this.state.startTime,
      // startTimeStamp: startDate.add(this.state.startTime, 'hours').unix(),
      // time: '',
      totalDays: 0,
      userName: `${
        this.props.user.name ? this.props.user.name : this.props.user.first_Name
      }`,
      //
    };
    if (cartProductData.senderId === cartProductData.receiverID) {
      message.info("Can Not Purchase Your Own product");
    } else if (this.props.cartProducts.length !== 0) {
      if (
        this.props.cartProducts[0].product.userId !== cartProductData.receiverID
      ) {
        message.info("Are You sure you want to reset");
        this.setState({
          modalReset: true,
          isRedirect: false,
          cartProductData: cartProductData,
        });
      } else {
        DataFirebase.addedToCart(
          cartProductData,
          this.props.user.uid,
          false
        ).then(() => {
          if (redirect) {
            RouterService.pushRoute(RouteConfig.CHECKOUT);
          }
        });
      }
    } else {
      DataFirebase.addedToCart(
        cartProductData,
        this.props.user.uid,
        false
      ).then(() => {
        if (redirect) {
          RouterService.pushRoute(RouteConfig.CHECKOUT);
        }
      });
    }
  };

  onAddCart() {
    if (this.props.isLoggedIn) {
      let { isSell = false } = this.state.productDetails || {};

      if (isSell) {
        this.setState({
          isBooknow: false,
        });
        this.addSellingProductToCart(false);
      } else {
        this.setState({
          isCartSelected: true,
          isBooknow: false,
        });
      }
    } else {
      this.props.showAuthModal();
    }
  }

  onBookNow() {
    if (this.props.isLoggedIn) {
      let { isSell = false } = this.state.productDetails || {};

      if (isSell) {
        this.setState({
          isBooknow: true,
        });
        this.addSellingProductToCart(true);
      } else {
        this.setState({
          isCartSelected: true,
          isBooknow: true,
        });
      }
    } else {
      this.props.showAuthModal();
    }
  }

  onShare() {
    // const el = this.textArea
    // el.select()
    // message.success('Link copied, Ready to share')

    const el = document.createElement("input");
    el.value = window.location.href;
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
    message.success("Link copied, Ready to share");
  }

  onBackEdit() {
    RouterService.pushRoute(RouteConfig.POST);
  }

  onPostProduct() {
    this.setState({
      isLoading: true,
    });
    DataFirebase.firebaseAddProduct(this.props.postProduct)
      .then(() => {
        this.setState({
          ...this.state,
          isProductAdded: true,
        });
      })
      .catch(() => {
        this.setState({
          ...this.state,
          isLoading: false,
        });
      });
  }

  onUserProfile() {
    let { ownerData } = this.state.productDetails;
    RouterService.pushRoute(`${RouteConfig.USERPROFILE}/${ownerData.uid}`);
  }

  render() {
    let tagManagerArgs = {
      dataLayer: {
        page: "Landing",
      },
      dataLayerName: "PageDataLayer",
    };
    TagManager.dataLayer(tagManagerArgs);
    let { isPreview } = this.state;
    let product = isPreview
      ? this.props.postProduct
      : this.state.productDetails;
    let {
      id,
      condition,
      name,
      desc,
      hourlyRate,
      weeklyRate,
      isSell = false,
      ß = false,
      isVerified = false,
      images = [],
      l = [],
      ownerData,
    } = product || {};

    let { isLoggedIn, userData } = this.props;
    let { uid, favoritesId = {} } = userData || {};

    let favoritesProductIds = Object.keys(favoritesId);
    let isFavorite = favoritesProductIds.includes(id);
    let productImages = isPreview
      ? images
      : images.map((image) => image.downloadURL);

    let redirectUrl = null;
    if (this.state.isProductAdded) {
      redirectUrl = <Redirect to={RouteConfig.LANDING} />;
    }

    return (
      <>
        {this.state.isModalOpen && (
          <BasicModal>
            <p className="text-center">Give your review here</p>
            <hr />
            <div className="d-flex justify-content-center mb-3">
              <Rate
                allowHalf
                count={5}
                value={this.state.ratting.rate}
                style={{ transform: "scale(1.5)" }}
                onChange={(v) => {
                  this.setState({
                    ...this.state,
                    ratting: {
                      ...this.state.ratting,
                      rate: v,
                    },
                  });
                }}
                size={"large"}
              />
            </div>
            <TextArea
              style={{ width: "300px" }}
              className="mb-3"
              placeholder="Write your thoughts here..."
              allowClear
              value={this.state.ratting.review}
              onChange={(v) => {
                this.setState({
                  ...this.state,
                  ratting: {
                    ...this.state.ratting,
                    review: v.target.value,
                  },
                });
              }}
            />
            <div className="d-flex">
              <Button type="primary" onClick={this.handleOk} className="mr-2">
                Send
              </Button>
              <Button type="link" onClick={this.handleCancel}>
                Cancel
              </Button>
            </div>
          </BasicModal>
        )}
        <Page
          product={product}
          noVerticalPadding
          noSection
          noFooter
          className={"product-details"}
        >
          {redirectUrl}
          {this.state.imageURL && productImages && (
            <ImageViewer
              images={productImages}
              index={this.state.photoIndex}
              crossClicked={() => {
                this.setState({
                  ...this.state,
                  imageURL: null,
                });
              }}
            />
          )}
          {this.state.modalReset && (
            <ModalReset
              cartProductData={this.state.cartProductData}
              isCloseClicked={() => this.setState({ modalReset: false })}
              isBooknow={this.state.isBooknow}
            />
          )}

          <textarea
            style={{ height: 0, width: 0, display: "none" }}
            ref={(area) => (this.textArea = area)}
            value={`https://wecycle.page.link/?link=https://wicycle.co/product/${id}&ibi=com.tanbelinc.WiCycleApp1`}
          />

          {this.state.isCartSelected ? (
            <CartDate
              cartProductData={this.state.cartProductData}
              product={this.state.productDetails}
              isBooknow={this.state.isBooknow}
              close={() => {
                this.setState({
                  ...this.state,
                  isCartSelected: false,
                });
              }}
            />
          ) : null}
          {!this.state.isLoading ? (
            <Spin style={{ width: "100%" }} spinning={this.state.loading}>
              {this.state.fetched && product && (
                <React.Fragment>
                  <Row gutter={0} className={"top-wrapper"}>
                    <Col sm={24} md={12} lg={12} xl={8}>
                      <Carousel
                        images={productImages}
                        onClick={(image, index) => {
                          // alert(image);
                          this.setState({
                            ...this.state,
                            imageURL: image,
                            photoIndex: index,
                          });
                        }}
                      />
                    </Col>
                    <Col
                      sm={24}
                      md={12}
                      lg={12}
                      xl={16}
                      className={"description"}
                    >
                      <h1 className={"name"}>
                        {name}
                        {isVerified && (
                          <ImageIcon
                            className={"Verify_butn"}
                            src={Icons.Verifyicon}
                          />
                        )}
                      </h1>
                      <span>{condition}</span>
                      <span> condition</span>
                      <table className={"rate-table"}>
                        {isSell ? (
                          <tbody>
                            <tr>
                              <th>${hourlyRate}</th>
                            </tr>
                            <tr>
                              <td>Price</td>
                            </tr>
                          </tbody>
                        ) : (
                          <tbody>
                            <tr>
                              <th>${hourlyRate}</th>
                              <th>${weeklyRate}</th>
                            </tr>
                            <tr>
                              <td>Daily</td>
                              <td>Weekly</td>
                            </tr>
                          </tbody>
                        )}
                      </table>
                      <div>
                        <b>Description</b>
                        <p>{desc}</p>
                      </div>
                    </Col>
                  </Row>
                  <Row gutter={0} className={"map-wrapper"}>
                    <Col>
                      {product && (
                        <GoogleMap
                          center={{
                            lat: product.address.latitude,
                            lng: product.address.longitude,
                          }}
                          googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyBXUKXS0Qnf2aiCfaDBzz2MTgEsPPKrOt8=3.exp&libraries=geometry,drawing,places"
                          loadingElement={<div style={{ height: `100%` }} />}
                          containerElement={<div className={"map"} />}
                          mapElement={<div style={{ height: `100%` }} />}
                        />
                      )}
                    </Col>
                    <Col>
                      <div className={"reviews-list mt-5"}>
                        <div className={"d-flex justify-content-between"}>
                          <h3 className="mr-3">Reviews</h3>
                          <Button onClick={this.showModal}>
                            Leave a review
                          </Button>
                        </div>
                        <hr className="mb-5" />
                        {this.state.reviews.map((review) => (
                          <div className={"review-item mb-5"}>
                            <div className={"d-flex align-items-center"}>
                              <img
                                className={"mr-2"}
                                alt={"reviewer"}
                                width={60}
                                height={60}
                                src={review.reviewer.photo}
                              />
                              <div className={"reviewer-info"}>
                                <h5 className={"reviewer-name"}>
                                  {review.reviewer.name}
                                </h5>
                                {review.timestamp && (
                                  <h6 className={"reviewer-date"}>
                                    {new Date(
                                      review.timestamp
                                    ).toLocaleDateString()}
                                  </h6>
                                )}
                              </div>
                            </div>
                            <div>
                              <Rate
                                allowHalf
                                count={5}
                                value={review.rate}
                                size={"large"}
                              />
                              <p className={"review-desc"}>{review.review}</p>
                            </div>
                          </div>
                        ))}
                      </div>
                    </Col>
                  </Row>
                  {!isPreview ? (
                    <Floating
                      isLiked={isFavorite}
                      ownerData={ownerData}
                      buttonClicked={(index) => {
                        if (index === 1) {
                          this.moveToChat();
                        } else if (index === 3) {
                          this.onFavorite({
                            uid,
                            id,
                            isFavorite,
                          });
                        } else if (index === 0) {
                          this.onUserProfile();
                        } else if (index === 2) {
                          this.onShare();
                        }
                      }}
                    />
                  ) : null}
                  {!isPreview ? (
                    <ProductActionBar
                      isSelling={isSell}
                      isFavorite={isLoggedIn && isFavorite}
                      onFavorite={this.onFavorite.bind(this, {
                        uid,
                        id,
                        isFavorite,
                      })}
                      ownerData={ownerData}
                      userProfile={this.onUserProfile.bind(this)}
                      onMessage={this.moveToChat.bind(this)}
                      onAddCart={this.onAddCart.bind(this)}
                      onBookNow={this.onBookNow.bind(this)}
                      onShare={this.onShare.bind(this)}
                    />
                  ) : (
                    <PreviewActionBar
                      onBackEdit={this.onBackEdit.bind(this)}
                      onPostClick={this.onPostProduct.bind(this)}
                    />
                  )}
                </React.Fragment>
              )}
            </Spin>
          ) : (
            <Spin className={"spinner"} />
          )}
        </Page>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  isLoggedIn: state.AuthReducer.isLoggedIn,
  userData: state.AuthReducer.userData,
  postProduct: state.ProductReducer.postProduct,
  user: state.AuthReducer.user,
  cartProducts: state.CartReducer.cartProducts,
});

const mapDispatchToProps = (dispatch) => ({
  bookProductNow: (product) => {
    dispatch(bookProductNow(product));
  },
  showAuthModal: () => {
    dispatch(showAuthModal(true));
  },
});
export default connect(mapStateToProps, mapDispatchToProps)(ProductDetails);
