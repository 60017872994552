import React, { PureComponent } from "react";
import "./styles.scss";
import DataFirebase from "../../../Firebase/DataFirebase";
import { Row, Form, Input, Button, Icon, Col } from "antd";
import Autocomplete from "react-google-autocomplete";

import { connect } from "react-redux";
import { setLocation } from "../../../Redux/Actions/AuthActions";
import { RouteConfig } from "../../../Config/RouteConfig";
import RouterService from "../../../Services/RouterService";
import { CATEGORIES } from "../../../Config";
import Utility from "../../../Services/Utility";

class Search extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      query: "",
      istyping: false,
      address: "",
      isRender: "0",
      lat: null,
      lng: null,
      index: 0,
      placeholders: [
        "Camera",
        "Bike",
        "Car",
        "Drill",
        "Chairs",
        "Drone",
        "Tent",
        "Speaker",
      ],
    };
  }

  componentWillMount() {
    this.timer = setInterval(() => {
      const min = 1;
      const max = 8;
      const rand = Math.floor(min + Math.random() * (max - min));
      if (!this.state.istyping) {
        this.setState({ query: this.state.placeholders[rand] });
      }
    }, 5000);
  }

  componentWillUnmount() {
    clearInterval(this.timer);
  }

  onProductSearch(param, loc) {
    if (param.lat && param.lng) {
      DataFirebase.resetQuery();
      DataFirebase.setProductQuery(param.lat, param.lng);
    }
    RouterService.pushRoute({
      pathname: `${RouteConfig.PRODUCTS}/${CATEGORIES.POPULAR}`,
      search: "?" + new URLSearchParams(param).toString(),
      address: "?" + new URLSearchParams(loc).toString(),
    });
  }

  render() {
    // let { address } =  this.state.address || {}
    return (
      <div id={"contact-form"}>
        <Form className={"contact-form"}>
          <Form.Item>
            <Input
              className={"input-Border"}
              type={"text"}
              addonBefore={<Icon className={"input-icon"} type={"search"} />}
              value={this.state.query}
              onChange={(event) => {
                this.setState({
                  query: event.target.value,
                  istyping: true,
                });
              }}
            />
          </Form.Item>
          <Form.Item>
            <div className={"input-Border fake-input"}>
              <div>
                <Icon className={"input-icon"} type={"environment"} />
              </div>
              <div className="fake-input-body">
                {this.state.isRender === "0" &&
                  this.setState({
                    address: this.props.location,
                  })}
                <Autocomplete
                  className={"location-feild"}
                  onPlaceSelected={(place) => {
                    this.props.updateLocation(
                      place.formatted_address,
                      place.geometry.location.lat(),
                      place.geometry.location.lng()
                    );
                    this.setState({
                      address: place.formatted_address,
                      lat: place.geometry.location.lat(),
                      lng: place.geometry.location.lng(),
                    });
                  }}
                  value={this.state.address}
                  onChange={(event) => {
                    this.setState({
                      address: event.target.value,
                      isRender: "1",
                    });
                  }}
                  types={["(regions)"]}
                />
              </div>
            </div>
          </Form.Item>
          <Form.Item>
            <Button
              className={"button-Search"}
              onClick={(query, address) => {
                let searchWord = this.state.query;
                Utility.analyticsUpdates("Product_Search", {
                  search: searchWord,
                  location: this.state.address,
                  userDetail: this.props.user,
                });
                this.onProductSearch({
                  search: searchWord,
                  address: this.state.address,
                  ...(this.state.lat && { lat: this.state.lat }),
                  ...(this.state.lng && { lng: this.state.lng }),
                });
              }}
            >
              Search
            </Button>
          </Form.Item>
        </Form>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  location: state.AuthReducer.address.location,
  user: state.AuthReducer.user,
});

const mapDispatchToProps = (dispatch) => ({
  updateLocation: (address, lat, lng) =>
    dispatch(setLocation(address, lat, lng)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Search);
