import React from 'react';
import { Tabs, Row } from 'antd';

import { NavLink } from 'react-router-dom'
import CustomAddress from '../../../../Atom/CustomAddress';
import Database from '../../../../../Firebase/DataFirebase';
import { FaPlusCircle } from 'react-icons/fa'
import ModalShipAddres from '../ShipAddressModal/index'
import { connect } from 'react-redux';
import './style.scss';
import { RouteConfig } from '../../../../../Config/RouteConfig';
import Route from '../../../../../Services/RouterService'
const { TabPane } = Tabs;

class DeliveryStep extends React.Component {



    constructor(props) {
        super(props);

        let isPickUpEnabled = this.props.cartProducts.filter(item => item.product.pickUp === false).length === 0
        let isDeliveryEnabled = this.props.cartProducts.filter(item => item.product.isDeliveryAvailable === false).length === 0
        let selectedKey = "1"
        if (isDeliveryEnabled === false) {
            this.props.addressSelected(null)
            selectedKey = "2"
        }
        if (isPickUpEnabled === false) {
            selectedKey = "1"
        }
        if (isDeliveryEnabled === false && isPickUpEnabled === false) {
            selectedKey = "1"
        }
        this.state = {
            deliveryAddress: [],
            selectedAddress: '',
            showAddressModal: false,
            isPickUpEnabled: isPickUpEnabled,
            isDeliveryEnabled: isDeliveryEnabled,
            selectedKey: selectedKey,
        }





    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.user.default_address !== this.state.selectedAddress) {
            let selectedAddress = this.state.deliveryAddress.filter(item => item.id === this.props.user.default_address)
            if (selectedAddress.length > 0 && this.state.isDeliveryEnabled) {
                this.props.addressSelected(selectedAddress[0])
            }
            this.setState({
                selectedAddress: this.props.user.default_address
            })
        }
        if (prevProps.cartProducts !== this.props.cartProducts) {

            let isPickUpEnabled = this.props.cartProducts.filter(item => item.product.pickUp === false).length === 0
            let isDeliveryEnabled = this.props.cartProducts.filter(item => item.product.isDeliveryAvailable === false).length === 0
            
            let selectedKey = "1"
            if (isDeliveryEnabled === false) {
                this.props.pickUpSelected(null)
                
                selectedKey = "2"
            }
            if (isPickUpEnabled === false) {
                selectedKey = "1"
            }
            if (isDeliveryEnabled === false && isPickUpEnabled === false) {
                selectedKey = "1"
            }
            this.setState({ isDeliveryEnabled, isPickUpEnabled, selectedKey })
        }
    }

    componentDidMount() {
        if (this.state.isDeliveryEnabled) {
        Database.getShippingAddress(this.props.user.uid)
            .then(deliveryAddress => {
                let selectedAddress = deliveryAddress.filter(item => item.id === this.props.user.default_address)
                if (selectedAddress.length > 0) {
                    this.props.addressSelected(selectedAddress[0])
                }
                this.setState({
                    deliveryAddress
                })
            })
        }
    }



    newAddressAdded = () => {
        this.setState({ showAddressModal: false })
        Database.getShippingAddress(this.props.user.uid)
            .then(deliveryAddress => {
                let selectedAddress = deliveryAddress.filter(item => item.id === this.props.user.default_address)
                if (selectedAddress.length > 0) {
                    this.props.addressSelected(selectedAddress[0])
                }

                this.setState({
                    deliveryAddress
                })
            })
    }


    render() {
        return (
            <>
                <Tabs
                    defaultActiveKey="1"
                    className="StyleDelvryAddrs"
                    centered type={'card'}
                    activeKey={this.state.selectedKey}
                    onTabClick={key => {
                        if (key === 1) {
                            let selectedAddress = this.state.deliveryAddress.filter(item => item.id === this.props.user.default_address)
                            if (selectedAddress.length > 0) {
                                this.props.addressSelected(selectedAddress[0])
                            } else {
                                this.props.addressSelected(null)
                            }
                        } else {
                            this.props.pickUpSelected(null)
                        }
                        this.setState({ selectedKey: key })
                    }}
                >
                    <TabPane
                        disabled={!this.state.isDeliveryEnabled}
                        tab="Shipping" key="1"  >
                        <h4>Delivery Address</h4>
                        <Row>
                            {
                                this.state.deliveryAddress.map(item => {
                                    return <CustomAddress
                                        key={item}
                                        user={this.props.user}
                                        isSelected={item.id === this.state.selectedAddress}
                                        address={item}
                                        reloadAddress={this.newAddressAdded}
                                    />
                                })
                            }


                            <div className="ShippingNew">
                                <ul>
                                    <li>
                                        <NavLink to={'#'} onClick={() => {
                                            this.setState({ showAddressModal: true })
                                        }} >
                                            <FaPlusCircle />Add New Shipping Address
                                        </NavLink>
                                         {this.state.showAddressModal &&
                                            <ModalShipAddres
                                                addressAdded={this.newAddressAdded}
                                                isCloseClicked={() => this.setState({ showAddressModal: false })} />
                                          }
                                    </li>
                                </ul>
                            </div>

                        </Row>
                    </TabPane>
                    <TabPane
                        tab="Pick up"
                        disabled={!this.state.isPickUpEnabled}
                        key="2" >
                        <h4>Pick Up Address</h4>
                        <p>Arrange the pickup location once the request is accepted by lender using our message services</p>
                    </TabPane>

                </Tabs>
            </>
        )
    }

}

const mapStateToProps = state => {
    return {
        user: state.AuthReducer.userData,
        cartProducts: state.CartReducer.cartProducts,
    };
}
export default connect(mapStateToProps)(DeliveryStep);
