import { ProductActionTypes } from "../Actions/ProductActions";

const defaultProductState = {
  loadingProducts: false,
  loadMore: true,
  products: [],
  postProduct: null,
  conditions: [],
  category: [],
  userProducts: [],
  favorites: [],
  lending: [],
};

const ProductReducer = (state = { ...defaultProductState }, action) => {
  switch (action.type) {
    case ProductActionTypes.LOADING_PRODUCTS:
      return {
        ...state,
        loadingProducts: action.status,
      };

    case ProductActionTypes.PRODUCT_ADD:
      return {
        ...state,
        postProduct: action.product,
      };

    case ProductActionTypes.PRODUCT_REMOVE:
      return {
        ...state,
        postProduct: null,
      };

    case ProductActionTypes.FAVORITE_ADD:
      return {
        ...state,
        favorites: action.favorite,
      };

    case ProductActionTypes.LENDING_ADD:
      return {
        ...state,
        lending: action.product,
      };

    case ProductActionTypes.FAVORITE_REMOVE:
      return {
        ...state,
        favorites: [],
      };

    case ProductActionTypes.LENDING_REMOVE:
      return {
        ...state,
        lending: [],
      };

    case ProductActionTypes.LOAD_MORE:
      return {
        ...state,
        loadMore: action.status,
      };
    case ProductActionTypes.PRODUCT_CONDITIONS:
      let prevConditions = state.conditions.filter(
        (pCondition) => pCondition.id === action.conditions.id
      );
      let conditions = [...state.conditions];
      if (prevConditions.length === 0) {
        conditions.push(action.conditions);
      }
      return {
        ...state,
        conditions: conditions,
      };

    case ProductActionTypes.PRODUCT_CATEGORY:
      let prevCategory = state.category.filter(
        (pCategory) => pCategory.id === action.category.id
      );
      let categories = [...state.category];
      if (prevCategory.length === 0) {
        categories.push(action.category);
      }
      return {
        ...state,
        category: categories,
      };

    case ProductActionTypes.SHIPPING_PRODUCTS:
      let allProducts = state.products.map((item) => item.id);
      let shippingProducts = action.products.filter(
        (item) => !allProducts.includes(item.id)
      );
      let productsToAppend = state.products.concat(shippingProducts);
      return {
        ...state,
        products: productsToAppend,
      };

    case ProductActionTypes.PRODUCT_ENTERED:
      let enteredProduct = action.product;
      let product = state.products.find(
        (product) => product.id === enteredProduct.id
      );

      if (!product) {
        let products = [...state.products, enteredProduct];
        return {
          ...state,
          products,
        };
      }
      return { ...state };

    case ProductActionTypes.FAVORITE_ENTERED:
      let enteredFavorite = action.favorite;
      let favorite = state.favorites.find(
        (favorite) => favorite.id === enteredFavorite.id
      );

      if (!favorite) {
        let favorites = [...state.favorites, enteredFavorite];
        return {
          ...state,
          favorites,
        };
      }
      return { ...state };

    case ProductActionTypes.LENDING_ENTERED:
      let enteredLending = action.product;
      let lendings = state.lending.find(
        (favorite) => favorite.id === enteredLending.id
      );

      if (!lendings) {
        let lending = [...state.lending, enteredLending];
        return {
          ...state,
          lending,
        };
      }
      return { ...state };

    case ProductActionTypes.SET_PRODUCTS:
      return {
        ...state,
        loadingProducts: false,
        products: action.products,
      };

    case ProductActionTypes.CLEAR_PRODUCT_LIST:
      return {
        ...state,
        products: [],
      };

    case ProductActionTypes.ADD_USER_PRODUCTS:
      let userProducts = state.userProducts.filter(
        (pProduct) => pProduct.id === action.product.id
      );
      let products = [...state.userProducts];
      if (userProducts.length === 0) {
        products.push(action.product);
      }
      return {
        ...state,
        userProducts: products,
      };

    case ProductActionTypes.CLEAR_USER_PRODUCTS:
      return {
        ...state,
        userProducts: [],
      };

    default:
      return {
        ...state,
      };
  }
};

export default ProductReducer;
