import { FaFacebookF, FaTwitter, FaInstagram } from 'react-icons/fa';
import { RouteConfig } from '../Config/RouteConfig';

export let LinkSections = [
    [
        {
            label: 'About Us',
            link: RouteConfig.ABOUT,
        },
        {
            label: 'Blog',
            link: RouteConfig.BLOG,
        },
        {
            label: 'Contacts',
            link: RouteConfig.CONTACT,
        }
    ],
    [ 
        {   
            label: 'FAQ',
            link:  'https://wecycle.drift.help/',
        },
        {
            label: 'Terms of Services',
            link: RouteConfig.TERMS_OF_SERVICES,
        },
        {
            label: 'Privacy Policy',
            link: RouteConfig.PRIVACY_POLICY,
        }
    ]

];

export const SocialLinks = [
    {
        Icon: FaFacebookF,
        link: 'http://facebook.com/WeCycle.io',
    },
    {
        Icon: FaTwitter,
        link: 'http://twitter.com/cycle_we',
    },
    {
        Icon: FaInstagram,
        link: 'http://instagram.com/WeCycle.io',
    }
];