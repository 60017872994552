import { AuthActionTypes } from '../Actions/AuthActions';
import { REHYDRATE } from 'redux-persist';
import { AuthTab } from '../../Config';

const nonPersistedState = {
    address: {
        location: '',
        latitude: 0,
        longitude: 0
    },
    authModalVisible: false,
    activeAuthTabId: AuthTab.LOGIN.id
};

const defaultAuthState = {
    isLoggedIn: false,
    user: null,
    userData: null,
    ...nonPersistedState
};

const AuthReducer = (state = { ...defaultAuthState }, action) => {
    switch (action.type) {
        case AuthActionTypes.SET_LOCATION:
            return {
                ...state,
                address: {
                    location: action.location,
                    latitude: action.latitude,
                    longitude: action.longitude
                }
            };

        case AuthActionTypes.SHOW_AUTH_MODAL:
            return {
                ...state,
                authModalVisible: action.status || false,
                activeAuthTabId: action.activeId || AuthTab.LOGIN.id
            };

        case AuthActionTypes.AUTH_CHANGED:
        
            return {
                ...state,
                isLoggedIn: !!action.user,
                user: action.user
            };

        case AuthActionTypes.UPDATE_USER_DATA:
            return {
                ...state,
                userData: action.userData
            };

        case AuthActionTypes.RESET_USER:
            return {
                ...state,
                isLoggedIn: false,
                userData: null,
                user: null
            }

        case REHYDRATE:
            let oldState = (action.payload || {}).AuthReducer;
            return {
                ...oldState,
                ...nonPersistedState
            };


        default:
            return { ...state };
    }
}

export default AuthReducer;