import React, { Component } from "react";
import { connect } from "react-redux";
import { RouteConfig } from "../../../Config/RouteConfig";
import Database from "../../../Firebase/DataFirebase";
import RouterService from "../../../Services/RouterService";
import ProductGalleryLoader from "../../Atom/Loader/ProductGallery";
import ProductCard from "../../Cell/ProductCard";
import "./styles.scss";

class ManagePosts extends Component {
  state = {
    loading: false,
  };

  async componentDidMount() {
    let { uid } = this.props.userData;
    if (uid) {
      try {
        this.setState({ loading: true });
        await Database.getUserProducts(uid);
        this.setState({ loading: false });
      } catch (error) {
        this.setState({ loading: false });
      }
    }
  }

  onProductDetails(productKey) {
    RouterService.pushRoute(`${RouteConfig.PRODUCT}/${productKey}`);
  }

  render() {
    const products = this.props.userProducts;
    const { loading } = this.state;
    return (
      <>
        <div className="manage-post">
          <div className="post-gallery">
            {loading ? (
              <ProductGalleryLoader />
            ) : (
              products.map((product, ind) => {
                let { id } = product;
                return (
                  <ProductCard
                    isMyProduct={true}
                    key={ind}
                    className="cards-product"
                    onClick={this.onProductDetails.bind(this, id)}
                    product={product}
                  />
                );
              })
            )}
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userProducts: state.ProductReducer.userProducts,
    userData: state.AuthReducer.userData,
  };
};

export default connect(mapStateToProps)(ManagePosts);
// export default ManagePosts
