import React, { Component } from 'react';
import './styles.scss';

import { Layout, Icon } from 'antd';
import { Home, About, OurServices, Features, Contact } from '../../Components/Placeholder/Sections';
import { sections } from '../../Config/Placeholder';
import TagManager from 'react-gtm-module'


class Placeholder extends Component {
    constructor(props) {
        super(props);
        this.state = {
            menuOpen: false
        }
    }

    toggleMenu(callback = () => { }) {
        this.setState(state => ({
            menuOpen: !state.menuOpen
        }), () => {
            callback();
        });
    }

    componentDidMount() {
        this.getDefaultSection();
    }

    getDefaultSection() {
        let { hash } = window.location;

        if (hash) {
            let element = document.querySelector(hash);
            if (element) {
                element.scrollIntoView();
            }
        }
    }

    gotoSection(id) {
        let section = document.getElementById(id);
        if (section) {
            this.toggleMenu(() => {
                section.scrollIntoView();
            });
        }
    }

    render() {
        
        let tagManagerArgs = {
                dataLayer: {
                   page: 'Landing'
                  }, 
                  dataLayerName: 'PageDataLayer'
             }
   TagManager.dataLayer(tagManagerArgs)


        let { menuOpen } = this.state;
        return (
            <Layout className={`placeholder-container`}>
                <Home />
                <About />
                <OurServices />
                <Features />
                <Contact />
                <div className={'mobile-menu'}>
                    <Icon
                        onClick={() => { this.toggleMenu(); }}
                        type={menuOpen ? "close" : "menu-fold"}
                        className={`menu-icon ${menuOpen ? 'open' : ''}`}
                    />
                    <div className={`nav-links ${menuOpen ? 'open' : 'close'}`}>
                        <ul>
                            <li onClick={this.gotoSection.bind(this, 'home')} className={'home-link'}>Home</li>
                            {Object.keys(sections).map((sectionKey, index) => {
                                let section = sections[sectionKey];
                                if (index > 0) {
                                    return (
                                        <li key={index} onClick={this.gotoSection.bind(this, section.id)}>
                                            {section.name}
                                        </li>
                                    );
                                }
                                return null;
                            })}
                        </ul>
                    </div>
                </div>
            </Layout>
        );
    }
}

export default Placeholder;
