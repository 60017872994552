import React from "react";
import "./styles.scss";
import { message, Badge, Drawer, Button, Radio, Space } from "antd";
import { connect } from "react-redux";
import { showAuthModal } from "../../../Redux/Actions/AuthActions";
import { AuthTab } from "../../../Config";
import { Navbar, Nav, Dropdown } from "react-bootstrap";
import PropType from "prop-types";
import RouterService from "../../../Services/RouterService";
import { RouteConfig } from "../../../Config/RouteConfig";
import CustomButton from "../../Atom/CustomButton";
import AuthFirebase from "../../../Firebase/AuthFirebase";
import { Icons, ReactIcon } from "../../../Shared/Assets";
import ImageIcon from "../../Atom/ImageIcon";
import Cart from "../../Molecule/Cart";

const ProfileLink = React.forwardRef(({ children, onClick }, ref) => (
  <Nav.Link className={"profile-link mx-3"} onClick={onClick}>
    <span>{children}</span>
  </Nav.Link>
));

class Header extends React.Component {
  state = {
    isCartView: false,
    theposition: 0,
    isHome: true,
  };

  componentDidMount() {
    window.addEventListener("scroll", this.listenToScroll);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.listenToScroll);
  }

  onLogout() {
    AuthFirebase.logout();
  }

  onShowAuthModal(tabIndex) {
    this.props.showAuthModal(true, tabIndex);
  }

  onPost() {
    RouterService.pushRoute(`${RouteConfig.POST}`);
  }

  listenToScroll = () => {
    const winScroll =
      document.body.scrollTop || document.documentElement.scrollTop;
    const height =
      document.documentElement.scrollHeight -
      document.documentElement.clientHeight;
    const scrolled = winScroll / height;
    if (this.state.theposition !== scrolled) {
      this.setState({
        theposition: scrolled,
      });
    }
  };

  render() {
    let onMessage = () => {
      RouterService.pushRoute(`${RouteConfig.CHAT}`);
    };

    let onCart = () => {
      if (this.props.cartProducts.length === 0) {
        message.success("No Product Found");
      } else {
        this.setState({
          isCartView: true,
        });
      }
    };

    let onFavourite = () => {
      RouterService.pushRoute(`${RouteConfig.LIKE}`);
    };

    let onProfile = () => {
      RouterService.pushRoute(`${RouteConfig.PROFILE}`);
    };
    let lightContent = this.props.lightContent;
    //
    return (
      <Navbar
        collapseOnSelect
        expand="md"
        className={`navbar ${
          lightContent ? "light-navbar" : ""
        } justify-content-between ${
          this.state.theposition > 0.011 ? "fixed-top" : ""
        }`}
      >
        {this.state.isCartView && this.props.cartProducts.length !== 0 ? (
          <Cart
            close={() => {
              this.setState({
                ...this.state,
                isCartView: false,
              });
            }}
          />
        ) : null}

        <Nav.Link
          className={"logo-link"}
          onClick={RouterService.pushRoute.bind(null, RouteConfig.LANDING)}
        >
          {/* <Navbar.Brand> */}
          {/* <span className={'name-title'}>{AppConstants.APP_NAME}</span> */}
          <ImageIcon className={"name-title"} src={Icons.logo}></ImageIcon>
          {/* </Navbar.Brand> */}
        </Nav.Link>

        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse className={"justify-content-end"}>
          <Nav className={"nav-link-wrapper align-items-center"}>
            <Nav className={"normal-links"}>
              {this.props.isLoggedIn && (
                <Nav.Link style={{ borderRightWidth: 1 }}>
                  <ReactIcon.ChatIcon
                    size={23}
                    // color={this.state.theposition > 0.011 ? "black" : "white"}
                    color={"black"}
                    onClick={onMessage.bind(this)}
                    fill={lightContent}
                  />
                </Nav.Link>
              )}
              <Nav.Link>
                <Badge
                  count={this.props.cartProducts.length}
                  style={{ right: "-4px", top: "-1px" }}
                >
                  <ReactIcon.ShopIcon
                    size={23}
                    // color={this.state.theposition > 0.011 ? "black" : "white"}
                    color={"black"}
                    onClick={onCart.bind(this)}
                    fill={lightContent}
                  />
                </Badge>
              </Nav.Link>
              <Nav.Link className={"last-link"}>
                <ReactIcon.HeartIcon
                  size={23}
                  // color={this.state.theposition > 0.011 ? "black" : "white"}
                  color={"black"}
                  onClick={onFavourite.bind(this)}
                  fill={lightContent}
                />
              </Nav.Link>
            </Nav>

            {!this.props.isLoggedIn ? (
              <Nav.Link
                className={"login-link"}
                onClick={() => {
                  if (this.props.isLoggedIn) {
                  } else {
                    this.onShowAuthModal(AuthTab.LOGIN.id);
                  }
                }}
              >
                Login
              </Nav.Link>
            ) : (
              <Dropdown>
                <Dropdown.Toggle as={ProfileLink}>
                  {this.props.user.displayName || "Profile"}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item onClick={onProfile.bind(this)}>
                    Profile
                  </Dropdown.Item>
                  <Dropdown.Item onClick={this.onLogout.bind(this)}>
                    Logout
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            )}
            {!this.props.isLoggedIn && (
              <CustomButton
                className={"signup-button"}
                onClick={this.onShowAuthModal.bind(this, AuthTab.SIGNUP.id)}
              >
                Sign Up
              </CustomButton>
            )}
            {this.props.isLoggedIn && this.props.showNewPost && (
              <CustomButton outlineColor="1px solid white" onClick={this.onPost.bind(this)}>
                New Post
              </CustomButton>
            )}
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isLoggedIn: state.AuthReducer.isLoggedIn,
    user: state.AuthReducer.user,
    cartProducts: state.CartReducer.cartProducts,
  };
};

Header.defaultProps = {
  lightContent: false,
};

Header.propTypes = {
  lightContent: PropType.bool,
};

export default connect(mapStateToProps, { showAuthModal })(Header);
