import React from "react";
import "./style.scss";
import { Button } from "antd/lib/radio";
import { ReactIcon } from "../../../Shared/Assets";

const ButtonGroup = ({
  next,
  previous,
  goToSlide,
  seeMoreClicked,
  ...rest
}) => {
  const {
    carouselState: { currentSlide },
  } = rest;
  return (
    <div className="carousel-button-group">
      <Button
        className={currentSlide === 0 ? "disable" : ""}
        onClick={() => goToSlide(currentSlide - 1)}
      >
        <ReactIcon.LeftIcon size={20}/>
      </Button>
      <Button onClick={() => goToSlide(currentSlide + 1)}>
        <ReactIcon.RightIcon size={20}/>
      </Button>
      <Button onClick={() => seeMoreClicked()}> See More</Button>
    </div>
  );
};

export default ButtonGroup;
