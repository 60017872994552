import { createStore, compose } from 'redux';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import CombinedReducers from '../Reducers';

const persistConfig = {
    key: 'root',
    storage,
    whitelist: ['AuthReducer']
}

const persistedReducer = persistReducer(persistConfig, CombinedReducers);
const composeEnhancer = process.env.NODE_ENV === 'development' ? window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__() : null || compose;

export const store = createStore(persistedReducer, composeEnhancer);
export const persistor = persistStore(store);
export const dispatchAction = store.dispatch; 