import React from "react";
import "./styles.scss";
import ProductCard from "../../Cell/ProductCard";
import arrow from "../../../Assets/Icons/arrow.png";

// import NoReviews from "../../../Assets/Images/undraw_feedback.png";
import { RouteConfig } from "../../../Config/RouteConfig";
import RouterService from "../../../Services/RouterService";

const productsHorizontal = ({ products, user, onClick, onMoreClicked }) => {
  let onProductDetails = (productKey) => {
    RouterService.pushRoute(`${RouteConfig.PRODUCT}/${productKey}`);
  };
  return (
    <div className={"products-container"}>
      <div style={{ height: "280px" }}>
        {/* <h1 className={"add-title"}>
          {user.firstName ? user.firstName + " " + user.lastName : user.name}
        </h1> */}
        <div
          className={"productsHorizontal-container WidthPofileProducts"}
          id={"container"}
        >
          {products.map((product, index) => {
            return (
              <ProductCard
                product={product}
                onClick={onProductDetails.bind(this, product.id)}
                className={"productsHorizontal-Products"}
                key={index}
              />
            );
          })}
        </div>
        {/* {products.length > 2 && (
          <img
            src={arrow}
            alt={"arrow"}
            className={"arrow"}
            onClick={onMoreClicked}
          />
        )} */}
      </div>
    </div>
  );
};

export default productsHorizontal;
