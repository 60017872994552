import React from 'react';
import './styles.scss';
import {Button} from 'antd'
import RouterService from '../../Services/RouterService';
import { RouteConfig } from '../../Config/RouteConfig';







const PageNotFound = () => {
    return (
        <div className={'page-not-found'}>
            <h1>404</h1>
            <h4>Page Not Found!</h4>

            <Button type="primary" onClick={()=> RouterService.pushRoute(RouteConfig.LANDING)}>Back Home</Button>
        </div>
    );
}




export default PageNotFound;
