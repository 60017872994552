import React, { PureComponent } from 'react';
import './styles.scss';
import './themes.scss';
import 'antd/dist/antd.css';

import UIProvider from '../Components/HOC/UIProvider';
import AuthUIProvider from '../Components/HOC/AuthUIProvider';
import { Provider } from 'react-redux';
import { store, persistor } from '../Redux/Store';
import { PersistGate } from 'redux-persist/integration/react';
import { StripeProvider, Elements } from 'react-stripe-elements';
//pk_live_E0EHnwODPxPan3QHPOnbD65H
//pk_test_6CEzgyDd7cvLPP5Nln4VnUtK
import AppRoutes from '../Routes';

class App extends PureComponent {
  
  render() {
    return (
      <Provider store={store}>
        <StripeProvider apiKey="pk_live_E0EHnwODPxPan3QHPOnbD65H">
          <AuthUIProvider>
            <UIProvider>
              <PersistGate persistor={persistor}>
                <Elements>
                  <AppRoutes />
                </Elements>
              </PersistGate>
            </UIProvider>
          </AuthUIProvider>
        </StripeProvider>
      </Provider>
    );
  }
}

export default App;
