import React from 'react';
import Carousel from '../Carousel'
import './styless.scss'
import Modal from '../../Atom/Modal'

const imageViewer = ({
    images,
    index=0,
    crossClicked
}) => {
    return(
        <Modal 
        modelSizeClass={'modelSizeClass'}
        modelContentSize={`modelContentSize`}
        isClose
        isCloseClicked={()=> {
            crossClicked()
        }}>
            <Carousel
            images={images}
            index={index}
            className={'carosel-ImagesViewer'}
            isFull
            onClick={()=> {

            }}
            />   
         </Modal>
        )
};

export default imageViewer;