import React from 'react';
import './styles.scss';
import { Icons } from '../../../Shared/Assets';
import CustomButton from '../../Atom/CustomButton';
import ImageIcon from '../../Atom/ImageIcon';



const ProductActionBar = ({
    isFavorite,
    isSelling = false,
    onFavorite = () => { },
    onMessage = () => { },
    ownerData,
    onAddCart = () => { },
    onBookNow = () => { },
    userProfile = () => {},
    onShare = () => {}
}) => {
    let {
        avatar,
        name = ''
    } = ownerData || {};

    return (
        <div className={'product-action-bar'}>
            <div className={'owner-info'} onClick={userProfile}>
                <div className={'thumbnail'}>
                    {avatar ?
                        <img alt={'owner-avatar'} src={avatar} onClick={userProfile}/>
                        :
                        <h1>{name.charAt(0)}</h1>
                    }
                </div>
                <h4>{name}</h4>
            </div>
            <div className={'button-wrapper'}>
                <div className={'share-buttons'}>
                    <ImageIcon
                        className={'action-button'}
                        src={Icons.messageDark}
                        onClick={onMessage}
                    />
                    <ImageIcon
                        className={'action-button'}
                        src={Icons.shareDark}
                        onClick={onShare}
                    />
                    <ImageIcon
                        className={'action-button'}
                        src={isFavorite ? Icons.heartFilled : Icons.heartDark}
                        onClick={onFavorite}
                    />
                </div>
                <div className={'action-buttons'}>
                    <CustomButton
                        className={'action-button'}
                        onClick={onAddCart}
                        outline
                    >
                        Add to Cart
                    </CustomButton>
                    <CustomButton
                        className={'action-button'}
                        onClick={onBookNow}
                    >
                       {isSelling  ? 'Buy Now' : 'Book Now'} 
                    </CustomButton>
                </div>
            </div>
        </div>
    );
}

export default ProductActionBar;
