import React from 'react';
import './styles.scss';
import { Row, Col, Nav, Container } from 'react-bootstrap';
import { connect } from 'react-redux';
import Page from '../../Components/HOC/Page';
import Footer from '../../Components/Molecule/Footer';
import moment from 'moment';
import APIManager from '../../APIManager';
import RouterService from '../../Services/RouterService';
import { RouteConfig } from '../../Config/RouteConfig';
import { NavLink } from 'react-router-dom';

class Blog extends React.Component {
    constructor(props) {
        super(props);
        let { id } = this.props.match.params;
        this.state = {
            id,
            isRedirect: false,
        }
    }
    createBlogs() {
        if (this.props.blogs.length === 0 || this.props.blogs.length < 4) {
            return null
        }
        let children = []
        for (let i = 0; i < 2; i++) {
            let variable = i * 2
            let firstIndex = variable
            let secondIndex = variable + 1
            let firstBlog = this.props.blogs[variable]
            let secondBlog = this.props.blogs[variable + 1]
            if (i % 2 !== 0) {
                firstIndex = variable + 1
                secondIndex = variable
                firstBlog = this.props.blogs[variable + 1]
                secondBlog = this.props.blogs[variable]
            }
            let firstBlogData = <div class="mb-3 p-3 blog-box h-box-1 w-100 m-2" style={{ backgroundImage: `url(${firstBlog._embedded['wp:featuredmedia'][0].source_url})` }}
                onClick={() => {
                    RouterService.pushRoute(`${RouteConfig.BLOG}/${firstIndex}`)
                }} >
                <div className="blog-content-box mt-5" >
                    <lable >{moment(firstBlog.date).format('d MMM YYYY')}</lable>
                    <h1 className="blog-title  text-white" style={{ textShadow: '0.5px 0.5px #000000' }}>{firstBlog.title.rendered}</h1>
                </div>
            </div>

            let secondBlogData = <div class="mb-3 p-3 blog-box  h-box-2 w-100 m-2" style={{ backgroundImage: `url(${secondBlog._embedded['wp:featuredmedia'][0].source_url})` }}
                onClick={() => RouterService.pushRoute(`${RouteConfig.BLOG}/${secondIndex}`)}>
                <div className="blog-content-box mt-5">
                    <lable>{moment(secondBlog.date).format('d MMM YYYY')}</lable>
                    <h1 className="blog-title  text-white" style={{ maretextShadow: '0.5px 0.5px #000000' }}>{secondBlog.title.rendered}</h1>
                    {/* <p className="  text-white">{secondBlog.excerpt.rendered}</p> */}
                    <div className="  text-white" style={{ textShadow: '0.5px 0.5px #000000' }} dangerouslySetInnerHTML={{ __html: secondBlog.excerpt.rendered }} />
                </div>
            </div>

            let child = [firstBlogData, secondBlogData]
            if (i % 2 !== 0) {
                child = child.reverse()
            }
            children.push(<Col md={6}>
                {
                    child
                }
            </Col>)
        }
        return children
    }
    componentDidMount() {
        if (this.state.id === null) {
            this.setState({
                isRedirect: true
            })
        } else if (this.props.blogs.length === 0) {
            APIManager.getAllBlogs()
        }
    }

    render() {
        let blog = this.props.blogs.length > 0 ? this.props.blogs[this.state.id] : null;
        // let blogs=this.props.blogs;
        return (
            <div
                className={'Blog'}>
                <Page
                    noSection
                    noFooter
                    className={'blog-pg'}>
                </Page>

                <Row className='second-nav'>
                    <div className='link-center'>
                        <p><span><a href="/">Home</a></span> / <span>
                            <NavLink href="/blog" style={{ color: 'black' }}>Blogs</NavLink></span>
                        </p>
                        <>
                            <Nav variant="pills" className="justify-content-center" defaultActiveKey="/blogs">
                                <Nav.Item>
                                    <Nav.Link >
                                        All
                                    </Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link>
                                        Products
                                    </Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link>
                                        People
                                    </Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link>
                                        Cars
                                    </Nav.Link>
                                </Nav.Item>
                            </Nav>
                        </>
                        <div className=" d-flex justify-content-start">
                            <h2>Latest</h2>
                        </div>
                    </div>
                </Row>
                <Container>
                    <Row>
                        {/* { <div class="col p-5">
                    {blogs.map((blogs, index) => (
                        <div class="col-sm-3 ml-2 ">
                            <img class="img-thumbnail" src={blogs._embedded['wp:featuredmedia'][0].source_url} alt="Hill" />
                            <div className="blogs-container ml-4">
                                <div class="card-body">
                                    <p>{blogs.title.rendered}</p>
                                    <h5 class="card-title">{moment(blogs.date).format('d MMM YYYY')}</h5>
                                </div>
                            </div>
                        </div> 
                
                        ))}             
                </div>   */
                            //this.createBlogs()
                        }
                    </Row>
                </Container>
                {blog && this.state.id &&
                    <div className={'Top-section'} style={{ backgroundImage: `url(${blog._embedded['wp:featuredmedia'][0].source_url})` }}>
                        <div className='Top-content'>
                            <div className='center'>
                                <h6>{moment(blog.date).format('d MMM YYYY')}</h6>
                                <h1>{blog.title.rendered}</h1>
                            </div>
                        </div>
                        <Row className='second-content'>
                            <Col
                                sm={{ span: 6, offset: 3 }}
                                md={{ span: 6, offset: 3 }}
                                lg={{ span: 6, offset: 3 }}>
                                <img src={blog._embedded.author[0].avatar_urls['48']} className={'kizz-img'} alt='kizz-img' />
                                <h5 style={{ display: 'inline', color: 'white', textShadow: '1px 1px #000000' }}>{blog._embedded.author[0].name}</h5>
                                {/* <p style={{display:'Block', color: 'white'}}>Market Researcher</p> */}
                            </Col>
                        </Row>
                    </div>
                }
                {blog && this.state.id &&
                    <div className={'Blog-content'}>
                        {/* <Row >
        <Col>
        <h4>{blog.title.rendered}</h4>
        </Col>
            </Row> */}
                        <Row>
                            <Col xs={12} sm={12} md={{ span: 8, offset: 2 }} lg={{ span: 6, offset: 3 }}>
                                <div dangerouslySetInnerHTML={{ __html: blog.content.rendered }} />
                                {/* <p>MISSISSAUGA, Ont. — After a 25-year military career leading 
                 some assignments of monumental importance, Douglas 
                 McGowan thought his transition into civilian life would be 
                 simple. He began his military career driving heavy vehicles and 
                 had piloted almost every type of vehicle possible while in 
                 uniform, often under challenging circumstances. <br/><br/>
                </p>
                <p>That’s why a career as a truck driver made sense to McGowan, 
                but his optimism soon faded as resume after resume was 
                ignored. After submitting 101 resumes – about 40 of which were 
                for truck driving jobs – McGowan took a different approach. 
                “I told myself I was going to stop at 101 resumes, and I stopped 
                at 101 resumes and never got any jobs,” McGowan told Truck 
                News in an interview. “With the training I had, running the 
                people, having the highest level of security clearance you can 
                get in the Canadian government, and I got nothing.”
                </p> */}
                            </Col>
                        </Row>
                        {/* <Row>
                <Col xs={12} sm={12} md={{ span: 8, offset: 2 }} lg={{ span: 6, offset:3}}>
                    <img src={Hill} className={'-img'} alt='Hill'/>
                </Col>
            </Row>
            </div>
            <div className={'Blog-content'}>
            <Row >
                <Col><h4>Feel confident in your shipping decision!</h4></Col>
            </Row>
            <Row>
                <Col xs={12} sm={12} md={{ span: 8, offset: 2 }}  lg={{ span: 6, offset: 3}}>
                <p>When he decided it was time for a change three-and-a-half 
                years ago, a trainer urged McGowan to get his dangerous goods 
                certificate and apply to pull Super-Bs at Westcan Bulk 
                Transport. The training culture there and focus on safety 
                appealed to McGowan. 
                “They care about the people driving,” he said. “It was almost like 
                the military. They wanted to make sure you understand and 
                everything’s going to be safe. They took the time (for training) 
                and I respected that.” <br/><br/>
                </p>
                <p>As per usual, McGowan soon found himself tasked with  
                increasingly complicated jobs. <br/><br/>
                </p>
                </Col>
            </Row> 
            <Row>
                <Col xs={12} sm={12} md={{ span: 8, offset: 2 }}  lg={{ span: 6, offset: 3}}>
                    <img src={Sunset} alt='Sunset' className={'-img'}/>
                </Col>
            </Row> */}

                    </div>
                }
                <Footer />
            </div>
        )
    }
}

const mapPropsToState = (state) => {
    return {
        blogs: state.BlogReducer.blogs
    }
}

export default connect(mapPropsToState)(Blog);