import React, { PureComponent } from 'react';
import './styles.scss';

import SectionWrapper from '../../SectionWrapper';
import { sections } from '../../../../Config/Placeholder';
import { Images, Icons } from '../../../../Shared/Assets';
import ImageIcon from '../../../Atom/ImageIcon';

const linkIcons = [Icons.featureArrow, Icons.featureChat, Icons.featureRefresh, Icons.featureShare];

export default class Features extends PureComponent {
    render() {
        return (
            <SectionWrapper
                id={sections.features.id}
            >
                <div className={'features-container'} style={{ backgroundImage: `url(${Images.featuresPath})` }}>
                    <div className={'content-wrapper'} style={{ backgroundImage: `url(${Images.feature})` }}>
                        <div className={'feature-content'}>
                            <div className={'description'}>
                                <h2>
                                    We've designed Wecycle for you. So, you already know it.
                            </h2>
                                <p>No Manual Needed</p>
                            </div>
                            <div className={'user-links-wrapper'}>
                                <div className={'user-links'}>
                                    {linkIcons.map((icon, index) => {
                                        return (
                                            <ImageIcon
                                                key={index}
                                                size={40}
                                                src={icon}
                                            />
                                        );
                                    })}
                                </div>
                                <p>Connect with user</p>
                            </div>
                        </div>
                    </div>
                </div>
            </SectionWrapper>
        );
    }
}
