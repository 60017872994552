import React, { Component } from 'react';
import './styles.scss';

import AuthFirebase from '../../Firebase/AuthFirebase';
import { Form } from 'antd';
import { Col } from 'antd';
import Page from '../../Components/HOC/Page';
import CustomInput from '../../Components/Atom/CustomInput';
import CustomButton from '../../Components/Atom/CustomButton';
import CustomAlert from '../../Components/Atom/CustomAlert';
import Spinner from '../../Components/Atom/Spinner';
import { AppConstants } from '../../Shared/Constants';
import { Redirect } from 'react-router-dom';
import { RouteConfig } from '../../Config/RouteConfig';
import Utility from '../../Services/Utility';
import Rules from '../../Services/Rules';
import TagManager from 'react-gtm-module'



class ResetPassword extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isUpdated: false,
            isUpdating: false,
            errorMessage: '',
        }
    }

    onResetPassword(event) {
        event.preventDefault();

        let { validateFields } = this.props.form;
        validateFields((error, { password }) => {
            if (!error) {
                let oobCode = Utility.getParam('oobCode');
                this.setState({
                    isUpdating: true
                });
                AuthFirebase.resetPassword(oobCode, password)
                    .then(() => {
                        this.setState({
                            isUpdating: false,
                            isUpdated: true
                        });
                    })
                    .catch((error) => {
                        this.setState({
                            isUpdating: false,
                            errorMessage: error.message
                        });
                    });
            }
        });
    }

    render() {
        let tagManagerArgs = {
            dataLayer: {
            page: 'Landing'
         }, 
            dataLayerName: 'PageDataLayer'
     } 

   TagManager.dataLayer(tagManagerArgs)


        let { getFieldValue, getFieldDecorator } = this.props.form;

        let isResetPassword = Utility.getParam('mode') === 'resetPassword';
        if (!isResetPassword) {
            return <Redirect to={RouteConfig.LANDING} />;
        }

        return (
            <Page
                noPadding
                noHeader
                noFooter
                className={'reset-password-page'}
                sectionClassName={'reset-header-section'}
                renderHeaderSection={() => (
                    <Col xs={24} sm={12} md={8} xl={6} className={'reset-form'}>
                        <div className={'info-wrapper'}>
                            <h1>{AppConstants.APP_NAME}</h1>
                            {this.state.isUpdated ?
                                <p className={'success-message'}>Your password has been upated successfully.</p>
                                :
                                <p>Create your new password.</p>
                            }
                        </div>
                        {this.state.isUpdated ?
                            <CustomButton onClick={this.goBackHome.bind(this)}>
                                Go Home
                            </CustomButton>
                            :
                            <Form onSubmit={this.onResetPassword.bind(this)}>
                                <CustomAlert
                                    message={this.state.errorMessage}
                                />
                                <Form.Item>
                                    {getFieldDecorator('password', {
                                        rules: Rules.getPasswordRules()
                                    })(
                                        <CustomInput
                                            type={'password'}
                                            placeholder={'New Password'}
                                        />
                                    )}
                                </Form.Item>
                                <Form.Item>
                                    {getFieldDecorator('confirmPassword', {
                                        rules: Rules.getConfirmPasswordRules(getFieldValue('password'))
                                    })(
                                        <CustomInput
                                            type={'password'}
                                            placeholder={'Confirm Password'}
                                        />
                                    )}
                                </Form.Item>
                                <Form.Item className={'reset-button-wrapper'}>
                                    {this.state.isUpdating ?
                                        <Spinner />
                                        :
                                        <CustomButton onClick={this.onResetPassword.bind(this)}>
                                            Reset
                                    </CustomButton>
                                    }
                                </Form.Item>
                            </Form>
                        }
                    </Col>
                )}
            />
        )
    }
}

export default Form.create()(ResetPassword);
