import React, { useState } from "react";
import "./styles.scss";
import DataFirebase from "../../../Firebase/DataFirebase";
import { connect } from "react-redux";
import { setLocation } from "../../../Redux/Actions/AuthActions";
import CustomButton from "../../Atom/CustomButton";
import { Input, Icon } from "antd";
import Autocomplete from "react-google-autocomplete";

const SearchBar = ({
  searched = "",
  // searchedAddress = '',
  dark = false,
  onClick = (searched, searchedAddress) => {},
  addressSaved,
  updateLocation,
  lat = null,
  lng = null,
}) => {
  const [address, setAddress] = useState(addressSaved);
  const [search, setSearch] = useState(searched);
  const [latitude, setLat] = useState(lat);
  const [longitude, setLng] = useState(lng);
  return (
    <div className={`search-bar ${dark ? "dark" : ""}`}>
      <div className={"column"}>
        <Input
          type={"text"}
          value={search}
          prefix={<Icon className={"input-icon"} type={"search"} />}
          placeholder={"Try to find ..."}
          onChange={(event) => {
            setSearch(event.target.value);
          }}
        />
      </div>
      <div className={"column"}>
        <Icon className={"input-icon"} type={"environment"} />
        <Autocomplete
          className={"location-feild"}
          onPlaceSelected={(place) => {
            setAddress(place.formatted_address);
            setLat(place.geometry.location.lat());
            setLng(place.geometry.location.lng());
          }}
          value={address}
          onChange={(event) => {
            setAddress(event.target.value);
          }}
          types={["(regions)"]}
        />
      </div>
      <CustomButton
        className={"search-button"}
        onClick={() => {
          if ((latitude, longitude)) {
            DataFirebase.resetQuery();
            DataFirebase.setProductQuery(latitude, longitude);
            updateLocation(address, latitude, longitude);
          }

          onClick(search, address);
        }}
      >
        Search
      </CustomButton>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    addressSaved: state.AuthReducer.address.location,
  };
};

const mapDispatchToProps = (dispatch) => ({
  updateLocation: (address, lat, lng) =>
    dispatch(setLocation(address, lat, lng)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SearchBar);
