import React from "react";
import "./styles.scss";

const BasicModal = ({ children }) => {
  return (
    <div className="custom-modal-body">
      <div className="basic-modal">{children}</div>
    </div>
  );
};

export default BasicModal;
