import React, { PureComponent } from 'react';
import './styles.scss';

import { connect } from 'react-redux';
import CustomButton from '../../../Components/Atom/CustomButton';
import CustomInput from '../../../Components/Atom/CustomInput';
import CustomAlert from '../../../Components/Atom/CustomAlert';
import Spinner from '../../../Components/Atom/Spinner';
import {
    Nav
} from 'react-bootstrap';
import { Form } from 'antd';
import AuthFirebase from '../../../Firebase/AuthFirebase';
import Rules from '../../../Services/Rules';
import { AuthTab } from '../../../Config';
import { showAuthModal } from '../../../Redux/Actions/AuthActions';

class Login extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            isSent: false,
            isSending: false,
            errorMessage: null
        }
        this.checkLogin();
    }

    componentDidUpdate() {
        this.checkLogin();
    }

    checkLogin() {
        if (this.props.isLoggedIn) {
            this.props.showAuthModal(false);
        }
    }

    onSendEmail(event) {
        event.preventDefault();
        let { validateFields } = this.props.form;
        validateFields((error, { email }) => {
            if (!error) {

                window.scrollTo({ top: 0 });
                this.setState({
                    isSending: true
                });
                AuthFirebase.sendForgotPasswordEmail(email)
                    .then(() => {
                        this.setState({
                            isSent: true,
                            isSending: false,
                            errorMessage: null
                        });
                    })
                    .catch(({ message }) => {
                        this.setState({
                            isSending: false,
                            errorMessage: message
                        });
                    });
            }
        });
    }

    onGoToLogin(event) {
        event.preventDefault();
        this.props.showAuthModal(true, AuthTab.LOGIN.id);
    }

    render() {
        let { getFieldDecorator } = this.props.form;
        return (
            <div className={'forgot-password'}>
                {this.state.isSent ?
                    <div className={'success-info'}>
                        <h4>Password rest link sent!</h4>
                        <p>
                            Password reset link has been sent to the registered email address <span>{this.state.email}</span>.
                            <br />
                            <br />
                            If you still haven't received email, Send Again.
                         </p>
                    </div>
                    :
                    <div>
                        <h4>Forgot Password?</h4>
                        <p>Enter your email address and we will get you back in track.</p>
                    </div>
                }

                <Form onSubmit={this.onSendEmail.bind(this)}>
                    <CustomAlert
                        message={this.state.errorMessage}
                    />
                    {!this.state.isSent &&
                        <Form.Item>
                            {getFieldDecorator('email', {
                                rules: Rules.getEmailRules()
                            })(
                                <CustomInput
                                    placeholder={'Enter email'}
                                />
                            )}
                        </Form.Item>
                    }
                    <Form.Item className={'send-button-wrapper'}>
                        {this.state.isSending ?
                            <Spinner />
                            :
                            <CustomButton
                                className={'send-button'}
                                onClick={this.onSendEmail.bind(this)}
                            >
                                {this.state.isSent ?
                                    'Send Again'
                                    :
                                    'Send'
                                }
                            </CustomButton>
                        }
                    </Form.Item>

                    <Nav.Link
                        className={'back-link'}
                        onClick={this.onGoToLogin.bind(this)}
                    >
                        Back To Login
                    </Nav.Link>
                </Form>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        isLoggedIn: state.AuthReducer.isLoggedIn
    };
}

export default connect(mapStateToProps, { showAuthModal })(Form.create()(Login));
