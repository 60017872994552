import React from 'react';
import './styles.scss';

import { Input } from 'antd';

const CustomInput = React.forwardRef((props, ref) => {
    return (
        <Input
            ref={ref}
            type={'text'}
            className={'custom-input'}
            autoCapitalize={'none'}
            autoComplete={'false'}
            autoCorrect={'false'}
            {...props}
        />
    );
});

export default CustomInput;